// Mock Data for the theme editor
export default {
  namespaced: true,
  state: {
    data: {
      sections: [
        {
          firstChildId: null,
          component: 'dashboardCompanyPage',
          title: 'Our Headquarters',
          content: '<img class="img-fluid" src="http://lorempixel.com/400/200/city/"/>',
          displayType: 1,
          items: null,
          imageURL: 'http://lorempixel.com/400/200/city/',
          id: 2809,
          type: 1,
          onboardingId: 23065,
          displayWidthType: 1
        },
        {
          title: 'Introduce Yourself',
          component: 'dashboardIntroduction',
          defaultText:
            'You are in the spotlight! Share little things about yourself so that your new team can get to know you before you start!',
          completedText: null,
          userIntroduction: null,
          dateCompleted: null,
          completed: false,
          hasUserIntroductionText: false,
          id: 2704,
          type: 3,
          onboardingId: 23065,
          displayWidthType: 1
        },
        {
          providerId: 'rpgjI4BC5-c',
          component: 'dashboardVideo',
          title: 'Our company',
          movieLink: '<iframe width="480" height="270" src="//www.youtube-nocookie.com/embed/IKz9leKy-b8?feature=oembed&html5=1&autoplay=0&showinfo=0&rel=0&iv_load_policy=1&cc_load_policy=1&ap=%2526fmt%3d18&modestbranding=1&theme=light&api=1" frameborder="0" allowfullscreen="" id="EmbeddedVideo"></iframe>'
            .replace(/(width=")\d+("\W+height=")\d+/, '$1100%$2100%')
            .replace('src="//', 'src="https://')
            .replace('autoplay=1', 'autoplay=0')
            .replace('<iframe', '<iframe class="embed-responsive-item"'),
          hasActiveVideo: true,
          remark: null,
          id: 2703,
          type: 6,
          onboardingId: 23065,
          displayWidthType: 1
        },
        {
          firstChildId: 6394,
          title: 'Your onboarding journey',
          component: 'dashboardCompanyPage',
          content: null,
          displayType: 3,
          items: [
            {
              id: 6394,
              imageURL: 'http://lorempixel.com/400/200/city/',
              label: 'Let us show you the way',
              onboardingId: 23065,
              sectionId: 2700,
              isOnDashboard: true,
              parentId: null,
              formId: null
            }
          ],
          imageURL: null,
          id: 2700,
          type: 1,
          onboardingId: 23065,
          displayWidthType: 1
        },
        {
          component: 'dashboardDidYouKnow',
          items: [
            {
              content: 'After our last acquisition, we now have almost 100,000 employees globally',
              shortMessage: 'After our last acquisition, we now have almost 100,000 employees globally',
              longMessage: 'After our last acquisition, we now have almost 100,000 employees globally',
              index: 1,
              hasLongMessage: false
            },
            {
              content: 'We operate in over 15 countries across all our business lines',
              shortMessage: 'We operate in over 15 countries across all our business lines',
              longMessage: 'We operate in over 15 countries across all our business lines',
              index: 2,
              hasLongMessage: false
            },
            {
              content: 'Our company built the Guggenheim museum',
              shortMessage: 'Our company built the Guggenheim museum',
              longMessage: 'Our company built the Guggenheim museum',
              index: 3,
              hasLongMessage: false
            }
          ],
          title: 'Did you know',
          hasItems: true,
          first: {
            content: 'After our last acquisition, we now have almost 100,000 employees globally',
            shortMessage: 'After our last acquisition, we now have almost 100,000 employees globally',
            longMessage: 'After our last acquisition, we now have almost 100,000 employees globally',
            index: 1,
            hasLongMessage: false
          },
          id: 2719,
          type: 2,
          onboardingId: 23065,
          displayWidthType: 1
        },
        {
          component: 'dashboardContacts',
          items: [
            {
              email: 's.degroef@talmundo.com',
              jobTitle: 'Manager',
              firstName: 'Stijn',
              lastName: 'De Groef',
              phoneNumberOffice: null,
              phoneNumberMobile: null,
              linkedinUrl: null,
              customUrl: null,
              imageURL: 'http://lorempixel.com/100/100/people/',
              gender: 0
            },
            {
              email: 'r.muller@talmundo.com',
              jobTitle: 'HR contact',
              firstName: 'Sales',
              lastName: 'Demo',
              phoneNumberOffice: null,
              phoneNumberMobile: null,
              linkedinUrl: null,
              customUrl: null,
              imageURL: 'http://lorempixel.com/101/101/people/',
              gender: 0
            },
            {
              email: 'e.baeten@talmundo.com',
              jobTitle: 'Buddy',
              firstName: 'Eva',
              lastName: 'Baeten',
              phoneNumberOffice: null,
              phoneNumberMobile: null,
              linkedinUrl: null,
              customUrl: null,
              imageURL: 'http://lorempixel.com/102/102/people/',
              gender: 0
            }
          ],
          title: 'Key contacts',
          hasItems: false,
          id: 2705,
          type: 7,
          onboardingId: 23065,
          displayWidthType: 1
        },
        {
          title: 'Pulse Check',
          component: 'dashboardPulseCheck',
          items: [
            {
              id: 515,
              index: 1,
              question: 'How was your recruitment experience?',
              startFromStartDate: null,
              rating: 4.2,
              isVisibleForUser: true,
              dateCompleted: '2018-09-28T06:13:00.657'
            },
            {
              id: 547,
              index: 2,
              question: 'Almost ready to get started?',
              startFromStartDate: -7,
              rating: 0.0,
              isVisibleForUser: false,
              dateCompleted: null
            },
            {
              id: 518,
              index: 3,
              question: 'How was your first week?',
              startFromStartDate: 7,
              rating: 0.0,
              isVisibleForUser: false,
              dateCompleted: null
            },
            {
              id: 544,
              index: 4,
              question: 'How was your first month with us?',
              startFromStartDate: 30,
              rating: 0.0,
              isVisibleForUser: false,
              dateCompleted: null
            },
            {
              id: 520,
              index: 5,
              question: 'How is your onboarding going?',
              startFromStartDate: 60,
              rating: 0.0,
              isVisibleForUser: false,
              dateCompleted: null
            },
            {
              id: 542,
              index: 6,
              question: 'How do you rate your onboarding process?',
              startFromStartDate: 90,
              rating: 0.0,
              isVisibleForUser: false,
              dateCompleted: null
            },
            {
              id: 519,
              index: 7,
              question: 'How is your career going?',
              startFromStartDate: 90,
              rating: 0.0,
              isVisibleForUser: false,
              dateCompleted: null
            }
          ],
          id: 2701,
          type: 4,
          onboardingId: 23065,
          displayWidthType: 1
        },
        {
          firstChildId: 6441,
          title: 'Personal information',
          component: 'dashboardForm',
          content: null,
          displayType: 3,
          items: [
            {
              id: 6441,
              imageURL: 'http://lorempixel.com/400/200/business/',
              label: 'Go to form',
              onboardingId: 23065,
              sectionId: 2718,
              isOnDashboard: true,
              parentId: null,
              formId: 2702
            }
          ],
          imageURL: 'http://lorempixel.com/402/201/business/',
          id: 2718,
          type: 9,
          onboardingId: 23065,
          displayWidthType: 1
        },
        {
          firstChildId: 6399,
          component: 'dashboardCompanyPage',
          title: 'About us',
          content: null,
          displayType: 3,
          items: [
            {
              id: 6402,
              imageURL: 'http://lorempixel.com/420/210/business/',
              label: 'Discover Our Company',
              onboardingId: 23065,
              sectionId: 2711,
              isOnDashboard: true,
              parentId: null,
              formId: null
            },
            {
              id: 6408,
              imageURL: 'http://lorempixel.com/430/215/business/',
              label: 'Getting Started',
              onboardingId: 23065,
              sectionId: 2711,
              isOnDashboard: true,
              parentId: null,
              formId: null
            },
            {
              id: 6413,
              imageURL: 'http://lorempixel.com/438/219/business/',
              label: 'Our Headquarters',
              onboardingId: 23065,
              sectionId: 2711,
              isOnDashboard: true,
              parentId: null,
              formId: null
            },
            {
              id: 6417,
              imageURL: 'http://lorempixel.com/440/220/business/',
              label: 'Life At Our Company',
              onboardingId: 23065,
              sectionId: 2711,
              isOnDashboard: true,
              parentId: null,
              formId: null
            }
          ],
          imageURL: null,
          id: 2711,
          type: 1,
          onboardingId: 23065,
          displayWidthType: 2
        }
      ],
      formsV2Enabled: true,
      languagePack: null,
      terminologyModels: [],
      formTodoLink: [],
      todoList: [
        {
          controllerName: 'IntroduceYourself',
          actionName: 'Index',
          routeDictionary: { sectionId: 2704 },
          title: 'Introduce Yourself',
          isFinished: false,
          upNextSubtitle: null,
          type: 3
        },
        {
          controllerName: 'PulseCheck',
          actionName: 'Question',
          routeDictionary: { sectionId: 2701, id: 515 },
          title: 'How was your recruitment experience?',
          isFinished: true,
          upNextSubtitle: null,
          type: 4
        }
      ],
      isOnboardingFinished: false,
      calendarDays: 17,
      isWorking: true,
      percentageComplete: 75,
      templateConfiguration: {
        showTopHeader: true
      },
      errors: {
        templateConfiguration: {
          showTopHeader: true
        }
      },
      todoList: [
        { isFinished: true, title: 'Lorem Ipsum 1', name: 'Lorem Ipsum 1', route: '/' },
        { isFinished: true, title: 'Lorem Ipsum 2', name: 'Lorem Ipsum 2', route: '/' },
        { isFinished: true, title: 'Lorem Ipsum 3', name: 'Lorem Ipsum 3', route: '/' },
        { isFinished: true, title: 'Lorem Ipsum 4', name: 'Lorem Ipsum 4', route: '/' },
        { isFinished: true, title: 'Lorem Ipsum 5', name: 'Lorem Ipsum 5', route: '/' },
        { isFinished: false, title: 'Lorem Ipsum 6', name: 'Lorem Ipsum 6', route: '/' },
        { isFinished: false, title: 'Lorem Ipsum 7', name: 'Lorem Ipsum 7', route: '/' },
        { isFinished: false, title: 'Lorem Ipsum 8', name: 'Lorem Ipsum 8', route: '/' },
        { isFinished: false, title: 'Lorem Ipsum 9', name: 'Lorem Ipsum 9', route: '/' },
        { isFinished: false, title: 'Lorem Ipsum 10', name: 'Lorem Ipsum 10', route: '/' }
      ]
    },
    errors: {}
  },
  getters: {
    dashboardData(state) {
      return state.data;
    },
    menu(state) {
      return state.data ? state.data.sections : null;
    },
    dashboardErrors(state) {
      return state.errors;
    },
    formsV2Enabled(state) {
      return state.formsV2Enabled;
    }
  },
  mutations: {
    setDashboardData(state, payload) {
      state.data = payload;
    }
  },
  actions: {
    setDashboardData({ commit }, payload) {
      commit('setDashboardData', payload);
    }
  }
};
