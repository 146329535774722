import Vue from 'vue';

function isBic(value) {
  return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-2])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(value.toUpperCase());
}

export default {
  getMessage(field, params, data) {
    return (data && data.message) || Vue.i18n.translate('validation.bic');
  },
  validate(value) {
    return new Promise((resolve) => {
      resolve({
        valid: isBic(
          value
            .replace(/\//g, '')
            .replace(/-/g, '')
            .replace(/\./g, '')
            .replace(/ /g, '')
        ),
        data: value !== 'trigger' ? undefined : { message: 'Not this value' }
      });
    });
  }
};
