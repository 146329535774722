import Vue from 'vue';
import settings from '@shared/store/settings';

const IDENTIFIERS = ['{{', '}}'];

export default (value, args = null, index = 0) => {
  if (settings && settings.state) {
    const { terminologyModels } = settings.state;
    const found = terminologyModels.find((item) => item.label === value);
    if (found) {
      const matcher = new RegExp('' + IDENTIFIERS[0] + '\\w+' + IDENTIFIERS[1], 'g');
      return found.replacingValue && found.replacingValue.replace
        ? found.replacingValue.replace(matcher, function(placeholder) {
            // remove the identifiers
            var key = placeholder.replace(IDENTIFIERS[0], '').replace(IDENTIFIERS[1], '');

            if (args[key] !== undefined) {
              return args[key];
            }

            return placeholder; // return the original placeholder
          })
        : '';
    }
  }

  return args ? Vue.i18n.translate(value.toLowerCase(), args, index) : Vue.i18n.translate(value.toLowerCase()); // thing to transform
};
