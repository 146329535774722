<template>
  <label
    :contenteditable="editMode"
    :for="forInput"
    @blur="editFormItemLabel($event)"
    @keyup.enter="exitEdit($event)"
    v-html="choice"
  ></label>
</template>

<script>
import { mapGetters } from 'vuex';
import { uuid } from 'vue-uuid';
import FormAddSectionMarker from '@forms/components/form-items/sections/FormAddSectionMarker.vue';
import VueFormJsonSchema from 'vue-form-json-schema';

export default {
  props: {
    editMode: {
      type: Boolean
    },
    choice: {
      type: String
    },
    position: {
      type: Number
    },
    forInput: {
      type: String
    }
  },
  methods: {
    editFormItemLabel(event) {
      const value = event.target.innerHTML
        // .replace(/<br>/g, "")
        // .replace(/<\/br>/g, "")
        .replace(/[\n\r]/g, '')
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replace(/&nbsp;/gi, '');
      event.target.innerHTML = value;
      this.$store.commit('formBuilder/editChoiceOption', {
        key: this.position,
        value: value
      });
    },
    exitEdit(event) {
      event.target.blur();
    }
  }
};
</script>
