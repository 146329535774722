<template>
  <div>
    <input
      name="password"
      type="password"
      :value="value"
      v-validate="{ required: true, password: true }"
      autocomplete="password"
      id="inputPassword"
      class="form-control"
      :placeholder="$t('form.passwordPlaceholder')"
      required
      aria-label="password"
      @keyup="onKeyUp"
      @input="onChange"
    />
    <p v-if="errors.has('password')" class="help text-danger mb-0 mt-1" v-html="errors.first('password')"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      type: String
    }
  },
  created() {
    this.passwordRequirements = this.company.passwordRequirements;
  },
  computed: {
    ...mapGetters('settings', ['company']),
    isValid() {}
  },
  data() {
    return {
      passwordRequirements: null
    };
  },
  methods: {
    onChange($event) {
      this.$emit('input', $event.target.value);
    },
    onKeyUp($event) {
      this.$emit('isValid', !this.errors.has('password'));
    }
  }
};
</script>

<style></style>
