import Vue from 'vue';
import Vuex from 'vuex';
import backwardsCompatibleForms from '@forms/mixins/backwardsCompatibleForms.js';

Vue.use(Vuex);

const forms = {
  namespaced: true,
  state: {
    forms: []
  },
  getters: {
    forms(state) {
      return state.forms;
    },
    formById: (state) => (id) => {
      return state.forms.find((form) => form.id === Number(id));
    }
  },
  mutations: {
    setForms(state, forms) {
      state.forms = forms;
    },
    addForm(state, form) {
      state.forms.push(form);
    },
    editForm(state, formToEdit) {
      state.forms[state.forms.indexOf(state.forms.find((form) => form.id === formToEdit.id))] = formToEdit;
    },
    duplicateForm(state, form) {
      state.forms.push(form);
    },
    deleteForm(state, formId) {
      state.forms = state.forms.filter((form) => form.id !== formId);
    }
  },
  actions: {
    getForms({ commit }) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.get(`/v2/companies/${companyId}/templates/${templateId}/forms`).then(
          (result) => {
            commit('setForms', result.data);
            resolve(result.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getFormById({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.get(`/v2/companies/${companyId}/templates/${templateId}/forms/${payload.formId}`).then(
          (result) => {
            // Only load last version = super speed improvement
            if (result.data.versions && result.data.versions.length > 0) {
              result.data.versions = [result.data.versions.pop()];
              result.data.versions[0] = backwardsCompatibleForms(result.data.versions[0]);
            } else {
              result.data.versions = [];
            }

            if (payload.store) {
              commit('setForms', [result.data]);
            }

            resolve(result.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    addForm({ commit }, form) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.post(`/v2/companies/${companyId}/templates/${templateId}/forms`, form).then(
          (response) => {
            commit('addForm', response.data);
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    editForm({ commit }, form) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.put(`/v2/companies/${companyId}/templates/${templateId}/forms/${form.id}`, form).then(
          (response) => {
            commit('editForm', form);
            resolve(form);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    duplicateForm({ commit }, form) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http
          .post(`/v2/companies/${companyId}/templates/${templateId}/forms/duplicate/${form.id}`, form)
          .then(
            (response) => {
              commit('duplicateForm', response.data);
              resolve(response.data);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    saveForm({}, payload) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        const put = {
          title: payload.form.title,
          description: payload.form.description,
          editableByOthers: payload.form.editableByOthers,
          editAfterSubmission: payload.form.editAfterSubmission,
          tags: payload.form.tags,
          completedByRelativeDays: payload.form.completedByRelativeDays || null,
          completedByFixed: payload.form.completedByFixed || null,
          formSchema: payload.schema
        };
        Vue.prototype.$http
          .put(`/v2/companies/${companyId}/templates/${templateId}/forms/${payload.form.id}`, put)
          .then(
            (response) => {
              resolve(true);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    publishForm({}, id) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.patch(`/v2/companies/${companyId}/templates/${templateId}/forms/publish/${id}`).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    deleteForm({ commit }, formId) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.delete(`/v2/companies/${companyId}/templates/${templateId}/forms/${formId}`).then(
          (response) => {
            commit('deleteForm', formId);
            resolve(true);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    discardDraft({}, formId) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.delete(`/v2/companies/${companyId}/templates/${templateId}/forms/discard/${formId}`).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    exportTranslations({}, { formId }) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };

        Vue.prototype.$http
          .get(`/v2/companies/${companyId}/templates/${templateId}/forms/${formId}/translations`, {
            responseType: 'blob'
          })
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    importTranslations({}, { formData, id }) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };

        Vue.prototype.$http
          .post(`/v2/companies/${companyId}/templates/${templateId}/forms/${id}/translations`, formData)
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
  }
};

export default forms;
