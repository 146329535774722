<template>
  <div class="row my-2">
    <label v-if="label" class="col-6" :for="id" :class="{ checked: value }">{{ label }}</label>
    <div :class="{ 'col-6': label, 'col-12': !label }">
      <el-select
        :disabled="disabled"
        :value="value"
        :placeholder="placeholder"
        @change="$emit('change', $event)"
        class="input-select-element"
      >
        <el-option
          v-for="item in items"
          :key="item.value"
          :label="item.label.replace(/<\/?[^>]+(>|$)/g, '')"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
  props: {
    id: {
      type: String
    },
    label: {
      type: String
    },
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {}
  },
  components: {
    elSelect: Select,
    elOption: Option
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
label {
  display: flex;
  align-items: center;
  color: $color-secondary;
  font-size: 12px;
}

.input-select-element {
  width: 100%;
}
</style>

<style lang="scss">
@import '@shared/styles/_colors.scss';
.el-switch__label,
.el-switch__label span {
  color: $color-primary !important;
  font-size: 12px !important;
  font-weight: normal !important;
}
.el-switch.is-checked .el-switch__core,
.el-switch__core {
  background-color: rgba(2, 28, 44, 0.03) !important;
  border-color: rgba(2, 28, 44, 0.03) !important;
}

.el-switch__label.is-active span {
  color: $color-secondary !important;
}

.el-switch.is-checked .el-switch__core:after {
  background-color: rgba(2, 28, 44, 0.3) !important;
}

.el-switch .el-switch__core:after {
  background-color: rgb(255, 255, 255) !important;
}
</style>
