import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import camelCase from 'lodash/camelCase';

Vue.use(VeeValidate);

// register all validations
const requireValidator = require.context('./', false, /^(?!.*index\.js).*\.js$/);

requireValidator.keys().forEach((fileName) => {
  const validatorConfig = requireValidator(fileName);
  const validatorName = camelCase(fileName.replace(/^].\//, '').replace(/\.\w+$/, ''));
  Validator.extend(validatorName, validatorConfig.default || validatorConfig);
});
