<template>
  <transition :name="transition">
    <button
      class="scroll-top"
      :style="styles"
      aria-label="Scroll to top"
      :class="[{ hover: shouldHover }, { hoverable: hover }]"
      v-show="showBackToTop"
      @mouseenter="shouldHover = true"
      @mouseleave="shouldHover = false"
      @click="toTop"
    >
      <div class="material-icons">expand_less</div>
    </button>
  </transition>
</template>
<script>
import throttle from 'throttle-debounce/throttle';
import SmoothScroll from 'smoothscroll-polyfill';
SmoothScroll.polyfill();
export default {
  props: {
    at: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: ''
    },
    hover: {
      type: Boolean
    },
    transition: {
      type: String,
      default: 'back-top-fade'
    },
    scrollDuration: {
      type: Number,
      default: 100
    },
    scrollFunction: {
      type: Function,
      default: () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      }
    }
  },
  data() {
    return {
      showBackToTop: false,
      shouldHover: false,
      timeout: 0,
      scrollInterval: 20
    };
  },
  computed: {
    styles() {
      let styles = {};
      if (this.color) {
        styles.backgroundColor = this.color;
      }
      return styles;
    }
  },
  methods: {
    toTop() {
      if (this.hover) {
        this.shouldHover = false;
      }
      this.showBackToTop = false;
      this.scrollFunction();
    },
    handleScroll() {
      this.showBackToTop =
        (document.body.scrollTop || document.documentElement.scrollTop) >= 0.1 * document.body.clientHeight;
    }
  },
  mounted() {
    this.throttledScrollHandler = throttle(300, this.handleScroll);
    document.addEventListener('scroll', this.throttledScrollHandler);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.throttledScrollHandler);
  }
};
</script>

<style lang="scss">
.scroll-top {
  border: none;
  background-color: lightgrey;
  position: fixed;
  left: 12px;
  bottom: 40px;
  @media (max-width: 767px) {
    left: auto;
    right: 12px;
    top: 12px;
  }
  size: 50px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  cursor: pointer;
  z-index: 20;
  opacity: 0.8;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  &.hoverable {
    opacity: 0.4;
  }
  &.hover,
  &:focus {
    opacity: 1;
  }

  .material-icons {
    color: white;
    font-size: 2rem;
  }
}

.back-top-fade-enter,
.back-top-fade-leave-active {
  transform: translateY(-30px);
  opacity: 0;
}
</style>
