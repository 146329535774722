import { render, staticRenderFns } from "./FormAddQuestionOrSectionMarker.vue?vue&type=template&id=747605fc&scoped=true&"
import script from "./FormAddQuestionOrSectionMarker.vue?vue&type=script&lang=js&"
export * from "./FormAddQuestionOrSectionMarker.vue?vue&type=script&lang=js&"
import style0 from "./FormAddQuestionOrSectionMarker.vue?vue&type=style&index=0&id=747605fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "747605fc",
  null
  
)

export default component.exports