<template>
  <sweet-modal @close="close" :title="'Move question'" ref="moveModal">
    <div class="modal-container">
      <p>{{ $t('forms.moveQuestionFromTo', { sectionTitle: selectedSectionTitle }) }}</p>
      <select v-model="moveToSection" class="mb-3">
        <option :value="section.id" v-for="section in sections" :key="section.id">{{ section.title }}</option>
      </select>
    </div>
    <div class="float-right mt-3">
      <button class="btn btn-secondary mr-2" @click="hideMoveModal">{{ 'general.cancel' | translate }}</button>
      <button :disabled="!moveToSection" class="btn btn-primary" @click="moveQuestion" type="submit">
        {{ 'forms.moveQuestion' | translate }}
      </button>
    </div>
  </sweet-modal>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    SweetModal
  },
  data() {
    return {
      moveToSection: null
    };
  },
  methods: {
    close() {
      this.$store.commit('formBuilder/setAllowSelect', true);
    },
    hideMoveModal() {
      this.$refs.moveModal.close();
    },
    getOrderForMovedQuestion(newSection) {
      let order = this.schema.properties[newSection].properties
        ? Math.max(
            -1,
            ...Object.keys(this.schema.properties[newSection].properties).map(
              (e) => this.schema.properties[newSection].properties[e].data.order
            )
          ) + 1
        : 0;
      return order;
    },
    moveQuestion() {
      const payload = {
        currentSection: this.selectedItem.sectionId,
        questionId: this.selectedItem.questionId || this.selectedItem.groupId,
        newSection: this.moveToSection,
        newOrder: this.getOrderForMovedQuestion(this.moveToSection)
      };
      const newData = {
        sectionId: this.moveToSection,
        groupId: this.selectedItem.groupId ? this.selectedItem.groupId : null,
        questionId: this.selectedItem.questionId
      };
      this.$store.commit('formBuilder/moveQuestionToSection', payload);
      this.$store.commit('formBuilder/setAllowSelect', true);
      this.$store.commit('formBuilder/setSelectedItem', newData);
      this.hideMoveModal();
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedSection', 'uiSchemaBuilder', 'schema', 'selectedItem']),
    selectedSectionTitle() {
      if (this.selectedSection && this.selectedSection.schema) {
        return this.selectedSection.schema.title;
      }
    },
    selectedSectionId() {
      if (this.selectedSection && this.selectedSection.schema) {
        return this.selectedSection.schema.index;
      }
    },
    sections() {
      const sectionMap = this.uiSchemaBuilder
        .map((section) => {
          return {
            title: section.fieldOptions.props.title,
            id: section.fieldOptions.props.sectionId
          };
        })
        .filter((section) => section.id !== this.selectedSectionId);
      return sectionMap;
    }
  }
};
</script>

<style lang="scss" scoped>
select {
  width: 100%;
  padding-left: 16px;
}
</style>
