<template>
  <button
    v-bind="$attrs"
    v-on="$listeners"
    type="button"
    :disabled="loading || disabled"
    class="btn btn-secondary"
    :class="{ 'btn-lg': size === 'large', 'btn-sm': size === 'small' }"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :style="buttonStyle"
  >
    <template v-if="loading">
      <beat-loader :color="style.colorSecondaryButtonText"></beat-loader>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';

export default {
  components: {
    BeatLoader
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String
    }
  },
  data() {
    return {
      hover: false
    };
  },
  computed: {
    ...mapGetters('settings', ['company', 'style']),
    buttonStyle() {
      return {
        'background-color': this.disabled ? 'grey' : this.style.colorSecondaryButton,
        'border-color': this.style.colorSecondaryButton,
        'box-shadow': this.hover ? '0 0 0 1px ' + this.style.colorSecondaryButton : 'none',
        color: this.style.colorSecondaryButtonText,
        opacity: this.hover ? 0.85 : this.disabled ? 0.5 : 1,
        cursor: this.disabled ? 'not-allowed' : 'pointer'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-secondary:active {
  background-image: none;
}
</style>
