<template>
  <div class="form-file-input-element">
    <label for="files-input" class="input-file-container">
      <i class="material-icons">add</i>
      <div class="text-container">{{ 'forms.uploadFile' | translate }}</div>
      <div class="file-data-container">{{ filesRemaining }}</div>
    </label>
    <el-tooltip
      :content="changeFileDisplayItems(availableExtensions)"
      placement="top"
      effect="light"
      class="file-tooltip"
    >
      <input
        id="files-input"
        v-if="!editMode && maxFiles > 1"
        type="file"
        @change="updateFileInput"
        onclick="this.value=null;"
        multiple
      />
      <input
        id="files-input"
        v-if="!editMode && maxFiles === 1"
        type="file"
        @change="updateFileInput"
        onclick="this.value=null;"
      />
    </el-tooltip>
    <div class="files-container" v-if="files.length > 0">
      <div class="file-element" v-for="(file, index) in files" :key="index">
        <i class="material-icons">insert_drive_file</i>
        <div class="text-container">{{ removeExtension(file.name) }}</div>
        <div class="file-data-container">{{ fileSizeSI(file.size) }} - {{ file.type.split('/').pop() }}</div>
        <i class="material-icons pointer" v-if="!loading" @click="removeFile(file)">delete</i>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default {
  props: {
    editMode: {
      type: Boolean
    },
    fileType: {
      type: String
    },
    maxFiles: {
      type: Number
    },
    maxSize: {
      type: Number
    },
    formId: {
      type: Number
    },
    model: {
      type: String
    },
    fileKey: {
      type: String
    },
    availableExtensions: {
      type: Array
    }
  },
  data() {
    return {
      files: [],
      moduleId: null,
      learningPageId: null
    };
  },
  created() {
    this.moduleId = this.$route.params.module || this.$route.params.moduleId;
    this.learningPageId = this.$route.params.id || this.$route.params.learningpageId;
    // Only go fetch files if already submitted data and is not in admin preview
    if (!this.isInPreviewAdmin) {
      this.$store.dispatch('loading/isLoading');
      this.getSubmittedFiles().then(
        (files) => {
          this.$store.dispatch('loading/isNotLoading');
          if (files.length === 0) {
            return;
          }

          // Only get files related to this question
          files = files.filter((file) => {
            let fileNameToFileKey = file.name.slice(0, file.name.indexOf('/'));
            fileNameToFileKey = fileNameToFileKey.replace(/_/g, '/');
            return fileNameToFileKey === this.fileKey;
          });

          if (files && files.length > 0) {
            let filesToShow = [];

            // Only show files up to maxFiles allowed
            for (var i = 0; i < this.maxFiles; i++) {
              filesToShow.push(files[i]);
            }

            filesToShow.forEach((file) => {
              const name = file.name.slice(file.name.indexOf('/') + 1, file.name.lastIndexOf('.'));
              const extension = file.name.slice(file.name.lastIndexOf('.') + 1);
              this.files.push({ name: name, size: file.fileSize || 0, type: extension });
            });
            this.updateFileListUploaded(this.files);
          }
        },
        () => {
          this.$notify({
            type: 'error',
            message: this.$t('notifications.errorGettingFiles'),
            position: 'bottom-right'
          });
        }
      );
    }
  },
  methods: {
    updateFileInput(event) {
      this.files = Object.keys(event.target.files).map((e) => event.target.files[e]);
      const size = this.files.reduce((a, b) => a + b.size, 0);
      // In admin only fake upload
      if (this.isInPreviewAdmin) {
        this.updateFileListUploaded(this.files);
        return;
      }
      if (size < this.maxSize) {
        this.submitFiles(this.files).then(
          () => {
            this.updateFileListUploaded(this.files);
            this.$notify({
              type: 'success',
              message: this.$t('notifications.successSubmitFiles'),
              position: 'bottom-right'
            });
          },
          (error) => {
            if (error && error.response && error.response.data && error.response.data.message) {
              if (error.response.data.message.indexOf('Supported extensions') > -1) {
                error = this.$t('notifications.errorIncorrectFileFormatUpload');
              } else {
                error = error.response.data.message;
              }
            } else {
              error = this.$t('notifications.errorSubmitFiles');
            }

            this.$notify({
              type: 'error',
              message: error,
              position: 'bottom-right'
            });
          }
        );
      } else {
        this.$notify({
          type: 'error',
          message: this.$t('forms.maxSize'),
          position: 'bottom-right'
        });
      }
    },
    updateFileListUploaded(files) {
      if (files.length === 0) {
        this.$el.parentElement.querySelector(`[id="${this.model}"]`).value = null;
      } else {
        if (files[0].name.indexOf('.') > -1) {
          this.$el.parentElement.querySelector(`[id="${this.model}"]`).value = JSON.stringify([`${files[0].name}`]);
        } else {
          this.$el.parentElement.querySelector(`[id="${this.model}"]`).value = JSON.stringify([
            `${files[0].name}.${files[0].type}`
          ]);
        }
      }

      this.$el.parentElement.querySelector(`[id="${this.model}"]`).dispatchEvent(new Event('input'));
    },
    removeExtension(name) {
      return name.replace(/(\.[^/.]+)+$/, '');
    },
    fileSizeSI(a, b, c, d, e) {
      return (
        ((b = Math), (c = b.log), (d = 1e3), (e = (c(a) / c(d)) | 0), a / b.pow(d, e)).toFixed(2) +
        ' ' +
        (e ? 'kMGTPEZY'[--e] + 'B' : 'Bytes')
      );
    },
    removeFile(file) {
      const index = this.files.indexOf(file);
      this.files.splice(index, 1);
      this.updateFileListUploaded(this.files);
    },
    submitFiles(files) {
      var formData = new FormData();
      formData.append(this.fileKey, files[0]);
      this.$store.dispatch('loading/isLoading');

      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/dashboard/v2/forms/${this.moduleId}/${this.learningPageId}/${this.formId}/filesubmit`, formData)
          .then(
            (response) => {
              this.$store.dispatch('loading/isNotLoading');
              resolve(response);
            },
            (error) => {
              this.$store.dispatch('loading/isNotLoading');
              reject(error);
            }
          );
      });
    },
    getSubmittedFiles() {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/dashboard/v2/forms/${this.moduleId}/${this.learningPageId}/${this.formId}/filesubmit`)
          .then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    changeFileDisplayItems(extensions) {
      return this.$t('forms.uploadFileFormat', { extensions: extensions.toString().toLowerCase() });
    }
  },
  computed: {
    filesRemaining() {
      const maxFiles = this.maxFiles;
      const remainData = this.remainData;
      return this.$t('forms.filesRemaining', { maxFiles, remainData });
    },
    remainData() {
      if (this.files.length > 0) {
        return this.fileSizeSI(this.maxSize - this.files.reduce((acc, elem) => (acc += elem.size), 0));
      }
      return this.fileSizeSI(this.maxSize);
    },
    isInPreviewAdmin() {
      return (
        this.$route.name === 'FormEditorById' ||
        this.$route.name === 'EditorById' ||
        this.$route.name === 'EditorByModuleId' ||
        this.$route.name === 'Preview'
      );
    },
    ...mapGetters('loading', ['loading'])
  }
};
</script>

<style lang="scss" scoped>
.form-file-input-element {
  .input-file-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #666;
    border-radius: 4px;
    border-style: dashed;
    height: 48px;
    padding: 0 16px;
    color: #666;
    font-weight: bold;
    .text-container {
      margin-left: 8px;
    }
    .file-data-container {
      margin-left: auto;
    }
  }
  input {
    position: relative;
    width: 100%;
    cursor: pointer;
    height: 48px;
    top: -55px;
    opacity: 0;
    z-index: 1;
  }
  .files-container {
    margin-top: -25px;

    .file-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #666;
      border-radius: 4px;
      height: 48px;
      padding: 0 16px;
      color: #666;
      margin: 8px 0;

      .text-container {
        margin-left: 8px;
        font-weight: bold;
      }

      .file-data-container {
        margin-left: auto;
        margin-right: 8px;
      }
    }
  }
  .file-tooltip {
    margin-left: 5px;
  }
}
</style>
