<template>
  <div class="form-reg-exp-field">
    <div class="block">
      <input
        type="string"
        class="form-control"
        :disabled="editMode"
        :required="required"
        :placeholder="placeholder"
        v-model="value"
        @change="updateValue()"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    editMode: {
      type: Boolean
    },
    model: {
      type: String
    },
    initialValue: {
      type: String | Number
    },
    required: {
      type: Boolean
    },
    placeholder: {
      type: String
    }
  },
  data() {
    return {
      value: ''
    };
  },
  created() {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  },
  methods: {
    updateValue() {
      this.$el.parentElement.querySelector(`[id="${this.model}"]`).value = this.value;
      this.$el.parentElement.querySelector(`[id="${this.model}"]`).dispatchEvent(new Event('input'));
    }
  },
  mounted() {
    if (this.$el.parentElement.querySelector(`input[id="${this.model}"]`)) {
      const self = this;
      this.$el.parentElement
        .querySelector(`input[id="${this.model}"]`)
        .addEventListener('change', (newInput) => (self.value = newInput.target.value), false);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-reg-exp-field {
  font-family: 'Talmundo';

  .over-panel {
    position: relative;
    top: -40px;
    width: 100%;
    height: 40px;
    margin-bottom: -40px;
    background-color: transparent;
  }

  .alert-warning {
    display: flex;
    margin-top: 16px;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    border: solid 1px #856404;
    color: #021c2c;
    i {
      margin-right: 16px;
      color: #856404;
    }
  }
}
</style>
