<template>
  <div>
    <sweet-modal :title="title" ref="rearangeModal" :blocking="true">
      <div v-if="loading">
        <loader></loader>
      </div>
      <div class="modal-container" v-else>
        <div
          v-for="(section, index) in modalSections"
          :key="index"
          class="column-container"
          @dragover="dragover($event)"
          @drop="drop(index)"
        >
          <div class="drop-off-element"></div>
          <div class="column" draggable="true" @dragstart="dragstart(index)">
            <i class="material-icons drag-icon">drag_indicator</i>
            <div class="text-container">
              <div class="section-title" v-if="section.title" v-html="section.title"></div>
              <div class="section-title" v-else>Group question {{ index + 1 }}</div>
              <div class="section-text">{{ elements }} {{ index + 1 }} of {{ modalSections.length }}</div>
            </div>
          </div>
        </div>
        <div class="column-container" @dragover="dragover($event)" @drop="drop(modalSections.length)">
          <div class="drop-off-element"></div>
        </div>
      </div>
      <div class="float-right">
        <button class="btn btn-secondary mr-2" @click="hideFormModal">{{ 'general.cancel' | translate }}</button>
        <button class="btn btn-primary" @click="rearrangeForm" type="submit">{{ title }}</button>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { SweetModal } from 'sweet-modal-vue';

export default {
  props: {
    selectedItem: {},
    title: {
      type: String,
      required: true
    }
  },
  components: {
    SweetModal
  },
  data() {
    return {
      loading: false,
      selectedSection: 0,
      modalSections: null,
      elements: ''
    };
  },
  computed: {
    ...mapGetters('formBuilder', ['schema', 'uiSchemaBuilder'])
  },
  watch: {
    selectedItem() {
      if (this.selectedItem.choices) {
        //Rearrange choices in question
        this.elements = 'Choices';
        if (this.selectedItem.groupId) {
          if (
            (this.modalSections = this.schema.properties[this.selectedItem.sectionId].properties[
              this.selectedItem.groupId
            ].properties[this.selectedItem.questionId].enum)
          ) {
            this.modalSections = this.schema.properties[this.selectedItem.sectionId].properties[
              this.selectedItem.groupId
            ].properties[this.selectedItem.questionId].enum.map((e) => {
              return { title: e };
            });
          } else {
            this.modalSections = this.schema.properties[this.selectedItem.sectionId].properties[
              this.selectedItem.groupId
            ].properties[this.selectedItem.questionId].items.enum.map((e) => {
              return { title: e };
            });
          }
        } else {
          if (
            (this.modalSections = this.schema.properties[this.selectedItem.sectionId].properties[
              this.selectedItem.questionId
            ].enum)
          ) {
            this.modalSections = this.schema.properties[this.selectedItem.sectionId].properties[
              this.selectedItem.questionId
            ].enum.map((e) => {
              return { title: e };
            });
          } else {
            this.modalSections = this.schema.properties[this.selectedItem.sectionId].properties[
              this.selectedItem.questionId
            ].items.enum.map((e) => {
              return { title: e };
            });
          }
        }
        this.modalSections = this.modalSections.filter((e) => e.title !== 'Other');
      } else {
        if (this.selectedItem.questionId) {
          //Rearrange questions
          this.elements = 'Questions';
          if (this.selectedItem.groupId) {
            this.modalSections = Object.keys(
              this.schema.properties[this.selectedItem.sectionId].properties[this.selectedItem.groupId].properties
            )
              .filter((key) => {
                return key.indexOf('/Other') === -1;
              })
              .sort(
                (a, b) =>
                  this.schema.properties[this.selectedItem.sectionId].properties[this.selectedItem.groupId].properties[
                    a
                  ].data.order -
                  this.schema.properties[this.selectedItem.sectionId].properties[this.selectedItem.groupId].properties[
                    b
                  ].data.order
              )
              .map((e) => {
                const result = cloneDeep(
                  this.schema.properties[this.selectedItem.sectionId].properties[this.selectedItem.groupId].properties[
                    e
                  ]
                );
                result.key = e;
                return result;
              });
          } else {
            this.modalSections = Object.keys(this.schema.properties[this.selectedItem.sectionId].properties)
              .filter((key) => {
                return key.indexOf('/Other') === -1;
              })
              .sort(
                (a, b) =>
                  this.schema.properties[this.selectedItem.sectionId].properties[a].data.order -
                  this.schema.properties[this.selectedItem.sectionId].properties[b].data.order
              )
              .map((e) => {
                const result = cloneDeep(this.schema.properties[this.selectedItem.sectionId].properties[e]);
                result.key = e;
                return result;
              });
          }
        } else {
          //Rearrange questions
          this.elements = 'Questions';
          if (this.selectedItem.groupId) {
            this.modalSections = Object.keys(this.schema.properties[this.selectedItem.sectionId].properties)
              .sort(
                (a, b) =>
                  this.schema.properties[this.selectedItem.sectionId].properties[a].data.order -
                  this.schema.properties[this.selectedItem.sectionId].properties[b].data.order
              )
              .map((e) => {
                const result = cloneDeep(this.schema.properties[this.selectedItem.sectionId].properties[e]);
                result.key = e;
                return result;
              });
            this.selectedItem.groupId = undefined;
          } else {
            //Rearrange Sections
            this.elements = 'Sections';
            this.modalSections = Object.keys(this.schema.properties)
              .sort((a, b) => this.schema.properties[a].data.order - this.schema.properties[b].data.order)
              .map((e) => {
                const result = cloneDeep(this.schema.properties[e]);
                result.key = e;
                return result;
              });
          }
        }
      }
    }
  },
  methods: {
    hideFormModal() {
      this.$refs.rearangeModal.close();
    },
    rearrangeForm() {
      if (this.selectedItem.choices) {
        let enableOtherOption;
        if (this.selectedItem.groupId) {
          enableOtherOption = this.schema.properties[this.selectedItem.sectionId].properties[this.selectedItem.groupId]
            .properties[this.selectedItem.questionId].data.enableOtherOption;
        } else {
          enableOtherOption = this.schema.properties[this.selectedItem.sectionId].properties[
            this.selectedItem.questionId
          ].data.enableOtherOption;
        }
        if (enableOtherOption) {
          this.modalSections.push({ title: 'Other' });
        }
      }
      const ordered = this.modalSections.map((e, index) => {
        return {
          key: e.key,
          title: e.title,
          order: index
        };
      });
      this.$store.commit(`formBuilder/rearrange${this.elements}`, { ordered, selectedItem: this.selectedItem });
      this.$refs.rearangeModal.close();
    },
    dragover(event) {
      event.preventDefault();
      // event.target.classList.add("over");
      const childs = event.target.closest('.modal-container').querySelectorAll('.column-container');
      for (let i = 0; i < childs.length; i++) {
        childs[i].classList.remove('over');
      }
      event.target.parentElement.classList.add('over');
    },
    dragleave(event) {
      // event.preventDefault();
      event.target.classList.remove('over');
      event.target.parentElement.classList.remove('over');
    },
    dragstart(index) {
      this.selectedSection = Number(index);
    },
    drop(index) {
      event.preventDefault();
      this.modalSections.splice(
        Number(index) > this.selectedSection ? Number(index) - 1 : Number(index),
        0,
        ...this.modalSections.splice(this.selectedSection, 1)
      );
      // event.target.classList.remove("over");
      // event.target.parentElement.classList.remove("over");
      const childs = this.$el.querySelectorAll('.column-container');
      for (let i = 0; i < childs.length; i++) {
        childs[i].classList.remove('over');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';

.modal-container {
  margin-bottom: 20px;

  .column-container {
    // padding-top: 20px;

    .drop-off-element {
      // -webkit-transition: height 1s;
      // -moz-transition: height 1s;
      // -ms-transition: height 1s;
      // -o-transition: height 1s;
      // transition: height 1s;
      height: 8px;
    }

    .column {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px #979797 solid;
      background-color: white;
      border-radius: 5px;
      cursor: grab;
      width: 100%;
      color: black;
      padding: 10px 15px;
      // margin-top: 20px;

      .drag-icon {
        color: #979797;
        margin-right: 15px;
      }

      .text-container {
        display: flex;
        flex-direction: column;

        .section-title {
          font-family: var(--font-family-sans-serif);
          font-weight: 600;
        }

        .section-text {
          font-family: var(--font-family-sans-serif);
          color: #979797;
        }
      }
    }
  }
}

.column.dragElem {
  opacity: 0.4;
}
.column-container.over {
  // padding-top: 60px;
  // background-color: rgba(56, 151, 219, 0.2);
  // border-radius: 5px;

  .drop-off-element {
    margin: 4px 0;
    height: 60px;
    border: dashed 2px lightgray;
  }
}
</style>
