import { render, staticRenderFns } from "./SecondaryButton.vue?vue&type=template&id=e170616c&scoped=true&"
import script from "./SecondaryButton.vue?vue&type=script&lang=js&"
export * from "./SecondaryButton.vue?vue&type=script&lang=js&"
import style0 from "./SecondaryButton.vue?vue&type=style&index=0&id=e170616c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e170616c",
  null
  
)

export default component.exports