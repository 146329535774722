<template>
  <div class="form-address-field">
    <div class="block">
      <input
        type="text"
        class="form-control"
        :placeholder="$t('forms.address')"
        :disabled="editMode"
        :required="required"
        v-model="address.address_line_1"
        @change="updateAddress()"
      />
      <input
        v-if="displayAddress2"
        type="text"
        class="form-control"
        :placeholder="$t('forms.address2')"
        :disabled="editMode"
        v-model="address.address_line_2"
        @change="updateAddress()"
      />
      <input
        v-if="displayAdminArea"
        type="text"
        class="form-control"
        :placeholder="$t('forms.province')"
        :disabled="editMode"
        v-model="address.admin_area_1"
        @change="updateAddress()"
      />

      <div class="same-line">
        <input
          class="form-control"
          type="text"
          :placeholder="$t('forms.city')"
          :required="required"
          :disabled="editMode"
          v-model="address.city"
          @change="updateAddress()"
        />
        <input
          class="form-control"
          type="text"
          :placeholder="$t('forms.postalCode')"
          :disabled="editMode"
          :required="required"
          v-model="address.postal_code"
          @change="updateAddress()"
        />
      </div>
      <country-component
        :model.sync="address.country_code"
        :placeholder="$t('forms.country')"
        :disabled="editMode"
        :initialValue="initialCountryCode"
        @change="updateCountry($event)"
      ></country-component>
      <div class="over-panel" v-if="editMode"></div>
    </div>
  </div>
</template>
<script>
import CountryComponent from '@forms/components/CountryComponent.vue';

export default {
  components: {
    CountryComponent
  },
  props: {
    editMode: {
      type: Boolean
    },
    displayAdminArea: {
      type: Boolean
    },
    displayAddress2: {
      type: Boolean
    },
    model: {
      type: String
    },
    initialValue: {
      type: Object
    },
    required: {
      type: Boolean
    }
  },
  data() {
    return {
      address: {
        address_line_1: '',
        address_line_2: '',
        admin_area_1: '',
        postal_code: '',
        city: '',
        country_code: ''
      }
    };
  },
  created() {
    if (this.initialValue) {
      this.address = this.initialValue;
    }
  },
  methods: {
    updateCountry(country) {
      this.address.country_code = country;
      this.updateAddress();
    },
    updateAddress() {
      if (!this.$el) {
        return;
      }
      this.$el.parentElement.querySelector(`[id="${this.model}"]`).value = JSON.stringify(this.address);
      this.$el.parentElement.querySelector(`[id="${this.model}"]`).dispatchEvent(new Event('input'));
    }
  },
  computed: {
    initialCountryCode() {
      if (this.initialValue && this.initialValue['country_code']) {
        return this.initialValue['country_code'];
      }
      return '';
    }
  },
  mounted() {
    if (this.$el.parentElement.querySelector(`input[id="${this.model}"]`)) {
      const self = this;
      this.$el.parentElement
        .querySelector(`input[id="${this.model}"]`)
        .addEventListener('change', (newInput) => (self.address = JSON.parse(newInput.target.value)), false);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-address-field {
  font-family: 'Talmundo';

  label {
    font-weight: bold;
  }

  input {
    margin: 12px 0;
    padding-left: 16px;
  }

  .same-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;

    input {
      width: 48%;
    }
  }
  .over-panel {
    position: relative;
    top: -40px;
    width: 100%;
    height: 40px;
    margin-bottom: -40px;
    background-color: transparent;
  }
}
</style>
