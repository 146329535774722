<template>
  <div class="p-4 text-center" :style="styles">
    <beat-loader :color="style.colorPrimary"></beat-loader>
  </div>
</template>

<script>
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import { mapGetters } from 'vuex';

BeatLoader.props.color.default = '#133c64';

export default {
  props: {
    styles: {
      default: '',
      type: String
    }
  },
  components: {
    beatLoader: BeatLoader
  },
  computed: {
    ...mapGetters('settings', ['style'])
  }
};
</script>
