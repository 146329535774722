<template>
  <div class="add-question-marker" @click="setSelectedItem">
    <div class="inner-border"></div>
    <p @click="addFormQuestion">
      <i class="material-icons">add</i>
      {{ 'forms.addQuestion' | translate }}
    </p>
    <create-question-modal :fromGroup="fromGroup"></create-question-modal>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import { mapGetters } from 'vuex';
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import CreateQuestionModal from '@forms/modals/CreateQuestionModal.vue';
import fieldTypes from '@forms/const/fieldTypes.js';
import cloneDeep from 'lodash/cloneDeep';

export default {
  data() {
    return {
      items: {}
    };
  },
  props: {
    fromGroup: {
      type: String,
      default: undefined
    }
  },
  components: {
    CreateQuestionModal
  },
  methods: {
    setSelectedItem(event) {
      event.preventDefault();
      const sectionContainer = event.target.closest('.section-container');
      if (sectionContainer) {
        const sectionId = sectionContainer.id
          .split('/')
          .slice(-2)
          .join('/');
        const groupId = event.target.closest('.group-container') ? event.target.closest('.group-container').id : null;
        this.$store.commit('formBuilder/setSelectedItem', {
          sectionId,
          groupId,
          questionId: null
        });
      }
    },
    addFormQuestion() {
      this.items = cloneDeep(this.selectedItem);
      this.items.groupId = this.fromGroup;
      this.$children.forEach((child) => {
        if (child.$refs.formModal) {
          child.$refs.formModal.open();
        }
      });
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['alignment', 'amountOfSections', 'selectedItem'])
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_helpers.scss';
@import '@shared/styles/_colors.scss';

div.add-question-marker {
  height: 24px;
  position: relative;
  text-align: center;
  margin-top: 20px;
  .inner-border {
    margin-top: 6px;
    border-top: none;
  }
  p {
    font-family: 'Talmundo';
    display: none;
    align-items: center;
    justify-content: center;
    color: $color-midgreyblue;
    cursor: pointer;
    left: calc(50% - 100px);
    position: absolute;
    width: 210px;
    top: -15px;
    padding: 4px;
    background-color: white;
    border-radius: 15px;
    border: dashed 1px $color-midgreyblue;
  }

  &.always-show {
    p {
      display: flex !important;
    }
    .inner-border {
      border-top: dashed 1px $color-midgreyblue;
    }
  }
}

div.add-question-marker:hover {
  .inner-border {
    border-top: dashed 1px $color-midgreyblue;
  }

  p {
    display: flex;
  }
}
</style>
