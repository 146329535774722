export default {
  methods: {
    showSuccessNotification(message) {
      this.showNotification('success', message, 'bottom-right');
    },
    showInfoNotification(message) {
      this.showNotification('info', message, 'bottom-right');
    },
    showErrorNotification(error, fallbackMessage) {
      let message;
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors[0] &&
        error.response.data.errors[0].message
      ) {
        message = error.response.data.errors[0].message;
      } else if (error && error.error) {
        message = error.error;
      } else if (fallbackMessage) {
        message = fallbackMessage;
      } else if (typeof error === 'string') {
        message = error;
      } else {
        message = this.$t('notifications.error');
      }

      this.showNotification('error', message, 'bottom-right');
    },
    showNotification(type, message, position) {
      this.$notify({
        type: type,
        message: message,
        position: position
      });
    }
  }
};
