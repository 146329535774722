// Import ES6 Promise
import 'es6-promise/auto';
import identity from 'lodash/identity';
import isObject from 'lodash/isObject';
import axios from 'axios';
import qs from 'qs';
import store from '../store';

import Vue from 'vue';

import { Notification } from 'element-ui';

const { VUE_APP_ADMIN_API_URL } = process.env;
const token = window.localStorage.admin_token;

let instance = axios.create({
  baseURL: VUE_APP_ADMIN_API_URL,
  timeout: 180000,
  headers: {
    Authorization: token ? `Bearer ${token}` : null
  }
});

instance.defaults.headers.post['Content-Type'] = 'application/json';
// Add a request interceptor
instance.interceptors.request.use(
  function(config) {
    store.dispatch('loading/isLoading');
    if (
      config.method === 'post' &&
      config.data &&
      config.headers['Content-Type'] === 'application/x-www-form-urlencoded'
    ) {
      config.data = qs.stringify(config.data);
    }
    if (config.headers && (config.headers.Authorization === null || isObject(config.headers.Authorization))) {
      delete config.headers.Authorization;
    }
    return config;
  },
  function(error) {
    store.dispatch('loading/isNotLoading');
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (config) => {
    store.dispatch('loading/isNotLoading');
    return config;
  },
  (error) => {
    store.dispatch('loading/isNotLoading');

    if (error.code && error.code === 'ECONNABORTED') {
      const message = Vue.prototype.$t('notifications.timeout');
      Notification.error({
        type: 'error',
        message: message,
        position: 'bottom-right'
      });
      return Promise.reject({ error: message });
    }

    const { config, response } = error;
    if (!error.data) {
      error = response;
    }

    if (response && response.status === 400) {
      const mute = response;
    }

    if (response.status === 500) {
      // const message = Vue.prototype.$t('notifications.timeout');
      const message = "The file you've uploaded contains a virus!";
      setTimeout(() => {
        Notification.error({
          type: 'error',
          message,
          position: 'bottom-right'
        });
      }, 2000);
    }

    if (response && response.status > 200 && response.data && response.data.errorMessage) {
      store.dispatch('auth/logout');
      return Promise.reject(
        identity({
          error: response.data && response.data.errorMessage
        })
      );
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
