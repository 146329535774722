<template>
  <div class="add-section-marker" :class="{ 'always-show': alwaysShow }">
    <div class="inner-border"></div>
    <p @click="addFormSection">
      <i class="material-icons">add</i>
      {{ 'forms.addSectionMarker' | translate }}
    </p>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import { mapGetters } from 'vuex';

import fieldTypes from '@forms/const/fieldTypes.js';

export default {
  props: {
    alwaysShow: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    addFormSection(event) {
      let order = this.schema.properties
        ? Math.min(0, ...Object.keys(this.schema.properties).map((e) => this.schema.properties[e].data.order)) - 1
        : 0;
      const key = `${fieldTypes.formSection}/${this.amountOfSections}`;
      const section = {
        'field-type': fieldTypes.formSection,
        $id: `#/properties/${key}`,
        index: `${key}`,
        type: 'object',
        title: this.$t('form.sectionTitle'),
        description: this.$t('form.sectionDescription'),
        data: {
          editMode: false,
          textAlignment: this.alignment,
          showTitle: true,
          showDescription: true,
          order: order
        },
        required: []
      };

      this.$store.commit('formBuilder/addFormSection', { section, key });
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['alignment', 'amountOfSections', 'schema'])
  }
};
</script>

<style scoped lang="scss">
@import '@shared/styles/_helpers.scss';
@import '@shared/styles/_colors.scss';

div.add-section-marker {
  height: 24px;
  position: relative;
  text-align: center;
  .inner-border {
    margin-top: 6px;
    border-top: none;
  }

  p {
    display: none;
    align-items: center;
    justify-content: center;
    color: $color-midgreyblue;
    cursor: pointer;
    left: calc(50% - 45px);
    position: absolute;
    width: 150px;
    top: -15px;
    padding: 4px;
    background-color: white;
    border-radius: 15px;
    border: dashed 1px $color-midgreyblue;
  }

  &.always-show {
    p {
      display: flex !important;
    }
    .inner-border {
      border-top: dashed 1px $color-midgreyblue;
    }
  }
}

div.add-section-marker:hover {
  .inner-border {
    border-top: dashed 1px $color-midgreyblue;
  }

  p {
    display: flex;
  }
}
</style>
