export default [
  'Roboto',
  'Montserrat',
  'Raleway',
  'Source Sans Pro',
  'Lato',
  'Oswald',
  'Poppins',
  'Open Sans',
  'Nunito'
];
