import Vue from 'vue';

function validateCountry(value, countries) {
  if (!countries) {
    return false;
  }
  const match = countries.filter((country) => country.name === value);
  if (match[0]) {
    return true;
  }
  return false;
}

export default {
  getMessage(field, params, data) {
    return (data && data.message) || Vue.i18n.translate('validation.country');
  },
  validate(value, args) {
    return new Promise((resolve) => {
      resolve({
        valid: value === 'trigger' ? false : validateCountry(value, args)
      });
    });
  }
};
