import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const module = {
  namespaced: true,
  state: {
    moduleData: {},
    modules: [],
    filters: [],
    treeview: {}
  },
  getters: {
    moduleData(state) {
      return state.moduleData;
    },
    modules(state) {
      return state.modules;
    },
    getModuleById: (state) => (id) => {
      return state.modules.filter((lpModule) => {
        return lpModule.id === Number(id);
      })[0];
    },
    filters(state) {
      return state.filters;
    },
    treeview(state) {
      return state.treeview;
    }
  },
  mutations: {
    addModuleTree(state, payload) {
      state.modules = state.modules.map((element) => {
        if (element.id === payload.id) {
          element.children.push(payload.item);
        }
        return element;
      });
    },
    addModule(state, lpModule) {
      state.modules.push(lpModule);
    },
    setSelectedLearningPage(state, selectedLearningPage) {
      state.selectedLearningPage = selectedLearningPage;
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    setFiltersToModuleData(state, filters) {
      state.moduleData.filterItems = filters.map((filter) => filter.id);
    },
    setModules(state, payload) {
      state.modules = payload;
    },
    setTreeView(state, payload) {
      state.treeview = payload;
    },
    setModuleData(state, payload) {
      if (!payload.useFixedDates) {
        payload.startFromStartDate = Number(payload.startFromStartDate);
        payload.endFromStartDate = Number(payload.endFromStartDate);
        let transformed = false;

        if (payload.startWorkday === 'before') {
          payload.startFromStartDate = -payload.startFromStartDate;
          transformed = true;
        }

        if (payload.endWorkday === 'before') {
          payload.endFromStartDate = -payload.endFromStartDate;
          transformed = true;
        }

        if (payload.startFromStartDate <= 0 && !payload.startWorkday) {
          payload.startWorkday = 'before';
          if (!transformed) {
            payload.startFromStartDate = -payload.startFromStartDate;
          }
        } else {
          payload.startWorkday = 'after';
        }
        if (payload.endFromStartDate <= 0 && !payload.endWorkday) {
          payload.endWorkday = 'before';
          if (!transformed) {
            payload.endFromStartDate = -payload.endFromStartDate;
          }
        } else {
          payload.endWorkday = 'after';
        }
      } else {
        if (payload.endWorkday === undefined) {
          if (payload.endFromStartDate > 0) {
            payload.endWorkday = 'after'; // default value when switching
          } else {
            payload.endWorkday = 'before';
            if (payload.endFromStartDate) {
              payload.endFromStartDate = -payload.endFromStartDate;
            }
          }
        } else if (payload.endWorkday === 'before' && payload.endFromStartDat) {
          payload.endFromStartDate = -payload.endFromStartDate;
        }

        if (payload.startWorkday === undefined) {
          if (payload.startFromStartDate > 0) {
            payload.startWorkday = 'after'; // default value when switching
          } else {
            payload.startWorkday = 'before';
            if (payload.startFromStartDate) {
              payload.startFromStartDate = -payload.startFromStartDate;
            }
          }
        } else if (payload.startWorkday === 'before' && payload.startFromStartDate) {
          payload.startFromStartDate = -payload.startFromStartDate;
        }
        if (payload.startFromStartDate === undefined || payload.startFromStartDate === null) {
          payload.immediate = true; // When immediate was checked before
        } else {
          payload.immediate = false; // When immediate was not checked before
        }
      }

      if (payload.useFixedDates === 'always') {
        payload.startFromStartDate = null;
        payload.endFromStartDate = null;
        payload.useFixedDates = false;
      }

      if (
        (isNaN(payload.startFromStartDate) && isNaN(payload.endFromStartDate)) ||
        payload.useFixedDates === 'always'
      ) {
        payload.useFixedDates = 'always';
        payload.startFromStartDate = 0;
        payload.endFromStartDate = 0;
      } else if (isNaN(payload.startFromStartDate) && !payload.useFixedDates) {
        payload.immediate = true;
        payload.useFixedDates = false;
      }

      if (payload.immediate === undefined) {
        payload.immediate = false;
      }

      state.moduleData = payload;
    },
    deleteModuleById(state, id) {
      state.modules = state.modules.filter((lpModule) => {
        return lpModule.id !== id;
      });
    }
  },
  actions: {
    getModuleData({ commit }, onboardingPeriod) {
      return new Promise((resolve) => {
        const moduleData = {
          title: '',
          startFromStartDate: 0,
          endFromStartDate: onboardingPeriod || 0,
          isActive: true,
          titleColor: null,
          fixedStartDate: new Date(),
          fixedEndDate: new Date(),
          useFixedDates: false,
          subpagesAppearance: 0,
          order: 0,
          startWorkday: 'before',
          endWorkday: 'after',
          displayWidth: 2,
          filterItems: [],
          immediate: false
        };

        commit('setModuleData', moduleData);
        resolve(moduleData);
      });
    },
    getFilters({ commit }) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.get(`/v1/companies/${companyId}/templates/${templateId}/filters/allowed`).then(
          (result) => {
            commit('setFilters', result.data.filters);
            resolve(result.data.filters);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getModules({ commit }) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.get(`/v1/companies/${companyId}/templates/${templateId}/learningpages`).then(
          (result) => {
            const lps = result.data.map((lp) => {
              Object.keys(lp).forEach((e) => {
                if (lp[e] === null) {
                  delete lp[e];
                }
              });
              return lp;
            });
            commit('setModules', lps);
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    createModule({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        delete payload.startWorkday;
        delete payload.endWorkday;
        if (payload.immediate) {
          payload.startFromStartDate = null;
          delete payload.immediate;
        }
        if (payload.useFixedDates === false) {
          delete payload.fixedStartDate;
          delete payload.fixedEndDate;
        }
        Vue.prototype.$http.post(`/v1/companies/${companyId}/templates/${templateId}/learningpages`, payload).then(
          (response) => {
            commit('addModule', response.data);
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    updateModule({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        delete payload.startWorkday;
        delete payload.endWorkday;

        if (payload.immediate) {
          payload.startFromStartDate = null;
          delete payload.immediate;
        }

        Vue.prototype.$http
          .put(`/v1/companies/${companyId}/templates/${templateId}/learningpages/${payload.id}`, payload)
          .then(
            (response) => {
              commit('addModule', response.data);
              resolve(response.data);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    deleteModule({ commit }, id) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.delete(`/v1/companies/${companyId}/templates/${templateId}/learningpages/${id}`).then(
          (result) => {
            commit('deleteModuleById', id);
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getTreeView({ commit }, moduleId) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http
          .get(
            `/v1/companies/${companyId}/templates/${templateId}/learningpages/${moduleId}/treeview?includeAccessPermissions=true`
          )
          .then(
            (result) => {
              commit('setTreeView', result.data);
              resolve(result);
            },
            (error) => {
              commit('setTreeView', []);
              reject(error);
            }
          );
      });
    }
  }
};

export default module;
