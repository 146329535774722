<template>
  <span class="referenceRelative">
    <template v-if="hoverImage">
      <label
        slot="reference"
        class="radio-image"
        :class="radioImageClass"
        @mouseover="previewShown = true"
        @mouseout="previewShown = false"
      >
        <input
          :value="val"
          :checked="isChecked()"
          @click="sendToParent($event.target.value)"
          :name="name"
          type="radio"
        />
        <div>
          <img :src="getImageSrc(img)" />
          <div class="text">
            <span>{{ text }}</span>
            <br />
            <span>{{ subtext }}</span>
          </div>
        </div>
      </label>
      <span v-if="hoverImage && previewShown" class="hover-image" :class="{ left: hoverLeft }">
        <span class="arrow_left"></span>
        <img :src="getImageSrc(hoverImage)" />
      </span>
    </template>

    <label v-if="!hoverImage" slot="reference" class="radio-image" :class="radioImageClass">
      <input :value="val" :checked="isChecked()" @input="sendToParent($event.target.value)" :name="name" type="radio" />
      <div>
        <img :src="getImageSrc(img)" :class="{ 'is-image': isImage(img) }" />
        <div class="text">
          <span>{{ text }}</span>
          <br />
          <span>{{ subtext }}</span>
        </div>
      </div>
    </label>
  </span>
</template>

<script>
import { Button } from 'element-ui';
const SIZES = ['small', 'large'];

export default {
  components: {
    elButton: Button
  },
  data() {
    return {
      previewShown: false
    };
  },
  name: 'RadioImage',
  props: {
    img: {
      type: String,
      required: true
    },
    name: {
      type: String
    },
    setValue: {
      required: true
    },
    text: {
      type: String
    },
    subtext: {
      type: String
    },
    val: {
      required: true
    },
    size: {
      type: String,
      default: 'small',
      validator: function(value) {
        return SIZES.indexOf(value) !== -1; // The value must match one of these strings
      }
    },
    hoverImage: {
      type: String,
      required: false
    },
    hoverLeft: {
      type: Boolean
    }
  },
  computed: {
    radioImageClass() {
      return SIZES.indexOf(this.size) >= 0 ? this.size : SIZES[0];
    }
  },
  methods: {
    getImageSrc(img) {
      if (img.substring(0, 4) == 'http') {
        return img;
      } else {
        return require(`@lp/images/${img}`);
      }
    },
    isImage(img) {
      return img.substring(0, 4) == 'http';
    },
    sendToParent: function(val) {
      if (val === 'true' || val === 'false') {
        val = val == 'true';
      }
      this.$emit('input', val);
    },
    isChecked() {
      return String(this.setValue) == this.val;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@shared/styles/_colors.scss';
@import '@shared/styles/_helpers.scss';

.is-image {
  margin: 0 15px;
  width: 40px;
  height: 40px;
  padding: 3px;
  border: 1px solid $color-lightgrey;
  border-radius: $general-border-radius;
}

.radio-image {
  vertical-align: middle;

  > input {
    visibility: hidden;
    position: absolute;

    & + div {
      cursor: pointer;
      border: 1px solid $color-lightgrey;
      border-radius: $general-border-radius;
    }
  }

  > input:checked + div {
    border: 2px solid $color-darkgreyblue;
    background-color: rgba(2, 28, 44, 0.03);

    .text {
      font-weight: bold;
    }
  }
}

.radio-image.small {
  > input {
    & + div {
      width: 105px;
      height: 111px;
      text-align: center;
      padding: 10px;
      margin-right: 10px;
    }

    & + div img {
      display: block;
      margin: auto;
    }

    & + div span {
      padding-top: 5px;
      font-size: 0.8em;
      display: inline-block;
      word-break: break-all;
    }
  }
}

.radio-image.large {
  display: block;

  > div {
    padding: 10px;
  }

  img {
    vertical-align: top;
    max-width: 75px;
  }

  > input {
    & + div span {
      font-size: 1em;
      display: inline-block;
      word-break: break-all;
    }
  }

  > input:checked + div span:first-child {
    // color: $color-darkgreyblue;
    font-weight: bold;
  }

  .text {
    display: inline-block;
    padding: 6px 0 6px 10px;

    span:last-child {
      font-size: 0.8em;
      color: #999999;
    }
  }
}

.hover-image {
  position: absolute;
  display: inline-block;
  padding: 10px;
  z-index: 9999;
  border: 1px solid $color-lightgrey;
  border-radius: $general-border-radius;
  background-color: white;
  box-shadow: 0 0 5px $color-grey;
  img {
    height: 91px;
  }
}

.hover-image.left {
  left: -207px;
}

.hover-image.left:after {
  left: 190px;
  border-right: 1px solid #cad5e0;
  border-top: 1px solid #cad5e0;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
}

.hover-image:after {
  content: '';
  display: block;
  position: absolute;
  top: 45px;
  left: -6px;
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-left: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.referenceRelative {
  position: relative;
}
</style>
