const ForgotPassword = () => import('../views/auth/ForgotPassword.vue');
const Login = () => import('../views/auth/Login.vue');
const InviteUser = () => import('../views/auth/InviteUser.vue');
const AdminLogin = () => import('../views/auth/AdminLogin.vue');
const IntroductionVideo = () => import('../views/public/IntroductionVideo.vue');
const PrivacyPolicy = () => import('../views/public/PrivacyPolicy.vue');
const ConfirmChangeEmail = () => import('../views/auth/ConfirmChangeEmail.vue');
const ChangePassword = () => import('../views/auth/ChangePassword.vue');
const ErrorPage = () => import('@shared/views/404.vue');
const TestimonialInvitation = () => import('../views/public/TestimonialInvitation.vue');
const LinkExpired = () => import('../views/public/LinkExpired.vue');
const TwoFactorSetup = () => import('../views/settings/TwoFactorSetup.vue');

import store from '../store';

export default [
  {
    path: '/login',
    redirect: '/auth/login',
    meta: {
      public: true
    }
  },
  {
    path: '/auth/login',
    name: 'login',
    beforeEnter(to, from, next) {
      if (!store.getters['auth/authenticated']) return next();
      return next('/dashboard');
    },
    component: Login,
    meta: {
      public: true
    }
  },
  {
    path: '/auth/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword,
    beforeEnter(to, from, next) {
      if (!store.getters['auth/authenticated']) return next();
      return next('/dashboard');
    },
    meta: {
      public: true
    }
  },
  {
    path: '/admin-login',
    name: 'adminLogin',
    component: AdminLogin,
    beforeEnter(to, from, next) {
      if (!store.getters['auth/authenticated']) return next();
      return next('/dashboard');
    },
    meta: {
      public: true
    }
  },
  {
    path: '/invite-user',
    name: 'inviteUser',
    component: InviteUser,
    beforeEnter(to, from, next) {
      if (!store.getters['auth/authenticated']) return next();
      return next('/dashboard');
    },
    meta: {
      public: true
    }
  },
  {
    path: '/change-password',
    name: 'changePassword',
    component: ChangePassword,
    meta: {
      public: true
    }
  },
  {
    path: '/confirm-change-email',
    name: 'confirmChangeEmail',
    component: ConfirmChangeEmail,
    meta: {
      public: true
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy,
    meta: {
      public: true
    }
  },
  {
    path: '/videos/introduction/:id/',
    name: 'introductionVideo',
    component: IntroductionVideo,
    meta: {
      public: true
    }
  },
  {
    path: '/testimonial-invitation/:invitationCode/',
    name: 'testimonialInvitation',
    component: TestimonialInvitation,
    meta: {
      public: true
    }
  },
  {
    path: '/link-expired',
    name: 'linkExpired',
    component: LinkExpired,
    meta: {
      public: true
    }
  },
  {
    path: '/two-factor-setup',
    name: 'twoFactorAuthentication',
    component: TwoFactorSetup,
    meta: {
      public: true
    }
  },
  {
    path: '/404',
    name: 'error',
    component: ErrorPage,
    meta: {
      public: true
    }
  }
];
