import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import vuexI18n from 'vuex-i18n'; // load vuex i18n module

Vue.use(Vuex);

import auth from './auth';
import dashboard from './dashboard';
import learningPages from '@lp/store/learning-pages';
import forms from '@forms/store/forms';
import formBuilder from '@forms/store/form-builder';
import editor from '@shared/store/editor';
import settings from '@shared/store/settings';
import loading from '@shared/store/loading';
import tasks from './tasks';

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  modules: {
    auth,
    dashboard,
    learningPages,
    forms,
    formBuilder,
    editor,
    settings,
    loading,
    tasks
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
});

if (module.hot) {
  // accept actions and mutations as hot modules
  module.hot.accept(
    [
      './auth',
      './dashboard',
      '@shared/store/settings',
      '@forms/store/forms',
      '@shared/store/loading',
      '@shared/store/editor',
      './tasks'
    ],
    () => {
      // require the updated modules
      // have to add .default here due to babel 6 module output
      const newAuth = require('./auth').default;
      const newDashboard = require('./dashboard').default;
      const newLearningPages = require('@lp/store/learning-pages').default;
      const newForms = require('@forms/store/forms').default;
      const newFormBuilder = require('@forms/store/form-builder').default;
      const newEditor = require('@shared/store/editor').default;
      const newSettings = require('@shared/store/settings').default;
      const newLoading = require('@shared/store/loading').default;
      const newTasks = require('./tasks').default;

      // swap in the new actions and mutations
      store.hotUpdate({
        modules: {
          auth: newAuth,
          dashboard: newDashboard,
          learningPages: newLearningPages,
          forms: newForms,
          formBuilder: newFormBuilder,
          editor: newEditor,
          settings: newSettings,
          loading: newLoading,
          tasks: newTasks
        }
      });
    }
  );
}

// initialize the internationalization plugin on the vue instance. note that
// the store must be passed to the plugin. the plugin will then generate some
// helper functions for components (i.e. this.$i18n.set, this.$t) and on the vue
// instance (i.e. Vue.i18n.set).
Vue.use(vuexI18n.plugin, store, { translateFilterName: 't' });

// override $t to accept lowerCase
(function($t) {
  Vue.prototype.$t = function() {
    arguments[0] = arguments[0].toLowerCase();
    return $t.apply(this, arguments);
  };
})(Vue.prototype.$t);

export default store;
