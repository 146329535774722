import Vue from 'vue';
import jwtDecode from 'jwt-decode';
import { logout } from './../http/authentication';

let {
  admin_token,
  refresh_token,
  token,
  user,
  chatbotName,
  chatbotOptions,
  chatbotLocalization,
  chatbotEnabled,
  planhatTokens,
  talentechTenantId,
  redirectPath
} = window.localStorage;

if (!user || user === undefined) {
  user = window.localStorage.user;
} else {
  try {
    const parsed = JSON.parse(user);
    if (!parsed.id) {
      user = window.localStorage.user;
    }
  } catch {
    console.error('User not parsed!');
  }
}

if (redirectPath) {
  redirectPath = window.localStorage.redirectPath;
  window.localStorage.removeItem('redirectPath');
}

if (!('remove' in Element.prototype)) {
  Element.prototype.remove = function() {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

const auth = {
  namespaced: true,
  state: {
    admin_token,
    refresh_token,
    token,
    user: user ? JSON.parse(user) : null,
    chatbotEnabled,
    chatbotName,
    chatbotOptions: chatbotOptions && chatbotOptions !== 'undefined' ? JSON.parse(chatbotOptions) : null,
    chatbotLocalization:
      chatbotLocalization && chatbotLocalization !== 'undefined' ? JSON.parse(chatbotLocalization) : null,
    redirectPath: redirectPath,
    planhatTokens: planhatTokens ? JSON.parse(planhatTokens) : null,
    talentechTenantId: talentechTenantId ? talentechTenantId : null,
    twoFactorSetup: {
      token: null,
      email: null,
      password: null,
      authenticatorToken: null
    },
    templateId: window.localStorage.templateId || null,
    expires_in: window.localStorage.expires_in || 3600
  },
  getters: {
    userToken(state) {
      return state.token;
    },
    refreshToken(state) {
      return state.refresh_token;
    },
    user(state) {
      return state.user;
    },
    authenticated(state) {
      return !!state.token;
    },
    chatbotEnabled(state) {
      return state.chatbotEnabled === 'true' || state.chatbotEnabled === true;
    },
    isAdmin(state, getters) {
      return state.user && (state.user.isAdmin || getters.isKeeper);
    },
    isKeeper(state) {
      return state.user && state.user.email === 'keeper';
    },
    redirectPath(state) {
      return state.redirectPath;
    },
    templateId(state) {
      return state.templateId;
    },
    expires_in(state) {
      return state.expires_in;
    }
  },
  mutations: {
    setAdminToken(state, payload) {
      state.admin_token = payload.access_token;
      state.refresh_token = payload.refresh_token;

      Vue.prototype.$http.defaults.headers['Authorization'] = `Bearer ${payload.access_token}`;

      window.localStorage.admin_token = payload.access_token;
      window.localStorage.refresh_token = payload.refresh_token;
    },
    setToken(state, payload) {
      state.token = payload.token;

      Vue.prototype.$http.defaults.headers['Authorization'] = `Bearer ${payload.token}`;
      window.localStorage.token = payload.token;
      if (payload.refresh_token) {
        state.refresh_token = payload.refresh_token;
        window.localStorage.refresh_token = payload.refresh_token;
      }

      if (payload.expires_in) {
        state.expires_in = payload.expires_in;
        window.localStorage.expires_in = payload.expires_in;
      }
    },
    setChatbotData(state, payload) {
      state.chatbotEnabled = payload.chatbotEnabled;
      window.localStorage.chatbotEnabled = payload.chatbotEnabled;
    },
    setUser(state, payload) {
      payload.user.defaultAvatar = `/static/img/avatar-${!payload.user || payload.user.gender < 3 ? 'm' : 'w'}.png`;
      window.localStorage.user = JSON.stringify(payload.user);
      state.user = payload.user;
    },
    setUserImage(state, payload) {
      let user = { ...state.user };
      user.imageURL = payload;
      state.user = user;
      window.localStorage.user = JSON.stringify(user);
      window.localStorage.user = JSON.stringify(user);
    },
    setPlanhatData(state, payload) {
      state.planhatTokens = payload.planhatTenantTokens;
      state.talentechTenantId = payload.talentechTenantId;

      window.localStorage.planhatTokens = JSON.stringify(payload.planhatTenantTokens);
      window.localStorage.talentechTenantId = payload.talentechTenantId;
    },
    logout(state) {
      state.token = null;

      const remove = [
        'token',
        'admin_token',
        'refresh_token',
        'admin_user',
        'user',
        'chatbotName',
        'chatbotOptions',
        'chatbotLocalization',
        'demo',
        'demoCompanyData',
        'demoStyles',
        'onboardingPageName',
        'bot.widget-converstaion', // typo on their side
        'bot.widget-last_activity',
        'bot.widget-is_user_interacted',
        'formShown',
        'admin_filters',
        'talentechTenantId',
        'planhatTokens',
        'expires_in',
        'templateId',
        'redirectPath'
      ];

      const language = window.localStorage.user ? JSON.parse(window.localStorage.user).language : null;
      const languageId = window.localStorage.user ? JSON.parse(window.localStorage.user).languageId : null;

      remove.forEach((item) => {
        window.localStorage.removeItem(item);
      });

      if (language && languageId) {
        const userLanguage = JSON.stringify({ language, languageId });
        window.localStorage.setItem('user', userLanguage);
      }

      const chatBotSelector = document.querySelector('#sk-holder');
      if (chatBotSelector) {
        chatBotSelector.parentNode.remove();
      }

      Vue.prototype.$http.defaults.headers['Authorization'] = null;
    },
    setRedirectPath(state, payload) {
      window.localStorage.redirectPath = payload.path;
      state.redirectPath = payload.path;
    },
    setTwoFactorSetup(state, payload) {
      state.twoFactorSetup = payload;
    },
    setAuthenticatorToken(state, payload) {
      state.twoFactorSetup.authenticatorToken = payload;
    },
    setTemplateId(state, payload) {
      state.templateId = payload;
      window.localStorage.templateId = payload;
    }
  },
  actions: {
    setUser({ commit }, payload) {
      commit('setUser', payload);
    },
    setUserImage({ commit }, payload) {
      commit('setUserImage', payload);
    },
    logout({ commit, state }, callBackend = false) {
      if (callBackend) {
        return logout(state.refresh_token).then((response) => {
          commit('setTwoFactorSetup', {});
          commit('logout');
        });
      } else {
        return new Promise((resolve, reject) => {
          commit('setTwoFactorSetup', {});
          commit('logout');
          resolve();
        });
      }
    },
    setChatbotData({ commit }, payload) {
      commit('setChatbotData', payload);
    },
    setAdminToken({ commit }, payload) {
      commit('setAdminToken', payload);
    },
    setToken({ commit }, payload) {
      commit('setToken', payload);
    },
    setRedirectPath({ commit }, payload) {
      commit('setRedirectPath', payload);
    },
    setTwoFactorSetup({ commit }, payload) {
      commit('setTwoFactorSetup', payload);
    },
    setAuthenticatorToken({ commit }, payload) {
      commit('setAuthenticatorToken', payload);
    },
    setPlanhatData({ commit }, payload) {
      commit('setPlanhatData', payload);
    },
    setTemplateId({ commit }, payload) {
      commit('setTemplateId', payload);
    }
  }
};

export default auth;
