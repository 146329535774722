import Vue from 'vue';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import { Collapse, CollapseItem, Loading, Message, MessageBox, Notification } from 'element-ui';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VuePhoneNumberInput from 'vue-phone-number-input';

import 'viewerjs/dist/viewer.css';
import 'swiper/css/swiper.css';
import Viewer from 'v-viewer';
import FormSection from '@forms/components/form-items/sections/FormSection.vue';
import FormQuestion from '@forms/components/form-items/questions/FormQuestion.vue';
import FormGroup from '@forms/components/form-items/group/FormGroup.vue';
import FormChoiceLabel from '@forms/components/form-items/questions/FormChoiceLabel.vue';
import FormLabel from '@forms/components/form-items/questions/FormLabel.vue';
import FormQuestionTitle from '@forms/components/form-items/questions/FormQuestionTitle.vue';
import FormQuestionDescription from '@forms/components/form-items/questions/FormQuestionDescription.vue';
import FormRating from '@forms/components/form-items/questions/FormRating.vue';
import FormFileInput from '@forms/components/form-items/questions/FormFileInput.vue';
import FormChoiceQuestion from '@forms/components/form-items/questions/FormChoiceQuestion.vue';
import FormDateField from '@forms/components/form-items/questions/FormDateField.vue';
import FormPhoneField from '@forms/components/form-items/questions/FormPhoneField.vue';
import FormCountryField from '@forms/components/form-items/questions/FormCountryField.vue';
import FormGdprField from '@forms/components/form-items/questions/FormGdprField.vue';
import FormRegExpField from '@forms/components/form-items/questions/FormRegExpField.vue';
import FormAddressField from '@forms/components/form-items/questions/FormAddressField.vue';
import FormIbanField from '@forms/components/form-items/questions/FormIbanField.vue';
import FormEmailField from '@forms/components/form-items/questions/FormEmailField.vue';
import FormNumberField from '@forms/components/form-items/questions/FormNumberField.vue';

import 'vue-phone-number-input/dist/vue-phone-number-input.css';

//Element UI
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

Vue.use(Loading);

Vue.use(VueAwesomeSwiper);
Vue.use(Viewer);

Vue.component('elCollapse', Collapse);
Vue.component('elCollapseItem', CollapseItem);

// FORMS
Vue.component('form-section', FormSection);
Vue.component('form-question', FormQuestion);
Vue.component('form-group', FormGroup);
Vue.component('form-choice-question', FormChoiceQuestion);
Vue.component('form-choice-label', FormChoiceLabel);
Vue.component('form-label', FormLabel);
Vue.component('form-question-title', FormQuestionTitle);
Vue.component('form-question-description', FormQuestionDescription);
Vue.component('form-rating', FormRating);
Vue.component('form-file-input', FormFileInput);
Vue.component('form-date-field', FormDateField);
Vue.component('form-phone-field', FormPhoneField);
Vue.component('form-iban-field', FormIbanField);
Vue.component('form-country-field', FormCountryField);
Vue.component('form-address-field', FormAddressField);
Vue.component('form-gdpr-field', FormGdprField);
Vue.component('form-email-field', FormEmailField);
Vue.component('form-reg-exp-field', FormRegExpField);
Vue.component('form-number-field', FormNumberField);

Vue.component('vue-tel-input', VuePhoneNumberInput);

Vue.config.productionTip = process.env.NODE_ENV === 'production';

// Auto register global components
const requireComponent = require.context('./global', false, /\.vue$/);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(camelCase(fileName.replace(/^].\//, '').replace(/\.\w+$/, '')));
  Vue.component(componentName, componentConfig.default || componentConfig);
});
