import { mapGetters } from 'vuex';

export default {
  methods: {
    setSelectedElement(type, event) {
      // let id;
      // let item;

      // if (this.questionId) {
      //   id = this.questionId;
      //   item = "questionId";
      // } else if (this.groupId) {
      //   id = this.groupId;
      //   item = "groupId";
      // }

      // if (this.groupId && this.selectedItem.groupId !== this.groupId) {
      //   this.$store.commit("formBuilder/setSelectedItem", {
      //     id: this.groupId,
      //     item: "groupId"
      //   });
      // }

      // this.$store.commit("formBuilder/setSelectedItem", {
      //   id: this.questionId,
      //   item: "questionId"
      // });

      const target = event.target;

      const sectionId = target.closest('.section-container')
        ? target
            .closest('.section-container')
            .id.split('/')
            .slice(-2)
            .join('/')
        : this.selectedItem.sectionId;

      const groupId = target.closest('.group-container') ? target.closest('.group-container').id : null;
      const questionId = target.closest('.question-container') ? target.closest('.question-container').id : null;
      this.$store.commit('formBuilder/setSelectedItem', {
        sectionId,
        groupId,
        questionId
      });

      this.$store.commit('formBuilder/setSelectedElement', type);
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedItem'])
  }
};
