import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const library = {
  namespaced: true,
  state: {
    selectedLearningPage: null
  },
  getters: {
    selectedLearningPage(state) {
      return state.selectedLearningPage;
    }
  },
  mutations: {
    setSelectedLearningPage(state, selectedLearningPage) {
      state.selectedLearningPage = selectedLearningPage;
    }
  }
};

export default library;
