<template>
  <div class="form-email-field">
    <div class="block">
      <input
        type="string"
        class="form-control"
        :placeholder="placeholder"
        :disabled="editMode"
        :required="required"
        v-model="value"
        @change="updateEmail()"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    editMode: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    model: {
      type: String
    },
    initialValue: {
      type: String
    },
    required: {
      type: Boolean
    }
  },
  data() {
    return {
      value: ''
    };
  },
  created() {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  },
  methods: {
    updateEmail() {
      this.$el.parentElement.querySelector(`[id="${this.model}"]`).value = this.value;
      this.$el.parentElement.querySelector(`[id="${this.model}"]`).dispatchEvent(new Event('input'));
    }
  },
  mounted() {
    if (this.$el.parentElement.querySelector(`input[id="${this.model}"]`)) {
      const self = this;
      this.$el.parentElement
        .querySelector(`input[id="${this.model}"]`)
        .addEventListener('change', (newInput) => (self.value = newInput.target.value), false);
    }
  }
};
</script>
