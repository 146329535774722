import Vue from 'vue';
import fieldTypes from '@forms/const/fieldTypes.js';
import flatten from 'lodash/flatten';
import cloneDeep from 'lodash/cloneDeep';

export default function buildUISchema(state, editMode, initialValues) {
  if (!state.form.schema.properties) {
    return [];
  }

  let uiSchema;
  uiSchema = Object.keys(state.form.schema.properties)
    .sort((a, b) => state.form.schema.properties[a].data.order - state.form.schema.properties[b].data.order)
    .map((sect) => {
      const section = state.form.schema.properties[sect];
      let children;
      if (section.properties) {
        children = [];
        Object.keys(section.properties)
          .sort((a, b) => section.properties[a].data.order - section.properties[b].data.order)
          .forEach((key) => {
            if (section.properties[key]['field-type'] === fieldTypes.group) {
              let displayOptions = {};
              let ifValues = getConditionalById(key, state.form.schema.allOf);
              if (!editMode && ifValues && ifValues.length > 0) {
                displayOptions = {
                  schema: {
                    type: 'object',
                    anyOf: []
                  }
                };
                ifValues.forEach((e) => {
                  if (displayOptions.schema.anyOf.find((elem) => elem.required[0] === e.question)) {
                    displayOptions.schema.anyOf
                      .find((elem) => elem.required[0] === e.question)
                      .properties[e.question].oneOf.push({ type: 'string', const: e.value });
                  } else {
                    let properties = {};
                    properties[e.question] = { type: 'string', oneOf: [{ type: 'string', const: e.value }] };
                    displayOptions.schema.anyOf.push({ properties, required: [e.question] });
                  }
                });
              }
              let groupChildren = [];
              if (section.properties[key].data.showTitle) {
                groupChildren.push({
                  component: fieldTypes.formLabel,
                  fieldOptions: {
                    props: {
                      editMode: editMode,
                      isRequired: false,
                      label: section.properties[key].title,
                      groupId: key
                    }
                  }
                });
              }
              if (section.properties[key].data.showDescription) {
                groupChildren.push({
                  component: fieldTypes.formQuestionDescription,
                  fieldOptions: {
                    props: {
                      editMode: editMode,
                      groupId: key
                    },
                    domProps: {
                      innerHTML: section.properties[key].description
                    }
                  }
                });
              }
              if (section.properties[key].properties) {
                groupChildren = groupChildren.concat(
                  Object.keys(section.properties[key].properties)
                    .sort(
                      (a, b) =>
                        section.properties[key].properties[a].data.order -
                        section.properties[key].properties[b].data.order
                    )
                    .map((elem) => {
                      let initialValue;
                      if (initialValues && initialValues[key] && !section.properties[key].data.repeatable) {
                        const keyElem = elem.split('.').pop();
                        initialValue = initialValues[key][keyElem];
                      }

                      return generateUIQuestion(
                        elem,
                        section.properties[key],
                        editMode,
                        state.form.schema.allOf,
                        state.form.schema,
                        section.properties[key].data.repeatable,
                        section.properties[key].data.repeatable ? undefined : initialValue,
                        state.form.formId,
                        true
                      );
                    })
                );
              }
              children.push({
                component: fieldTypes.group,
                fieldOptions: {
                  props: {
                    schema: state.form.schema,
                    required: section.properties[key].items.required,
                    groupSchema: section.properties[key],
                    repeatable: section.properties[key].data.repeatable,
                    editMode: editMode,
                    groupId: key,
                    sectionId: section.index,
                    initialValue: initialValues ? initialValues[key] : undefined,
                    buttonText: section.properties[key].data.buttonText
                  }
                },
                displayOptions,
                children: groupChildren
              });
            } else {
              let initialValue;
              if (initialValues) {
                initialValue = initialValues[key];
              }

              children.push(
                generateUIQuestion(
                  key,
                  section,
                  editMode,
                  state.form.schema.allOf,
                  state.form.schema,
                  false,
                  initialValue,
                  state.form.formId,
                  false
                )
              );
            }
          });
      }

      let data = {
        component: fieldTypes.formSection,
        fieldOptions: {
          attrs: {
            id: section.$id,
            index: section.$id.split('/').pop()
          },
          props: {
            title: section.title,
            description: section.description,
            sectionId: sect,
            editMode: editMode,
            textAlignment: section.data.textAlignment,
            showTitle: section.data.showTitle,
            showDescription: section.data.showDescription
          }
        }
      };
      if (children) {
        data.children = children;
      }
      return data;
    });

  return uiSchema;
}

//Returns the uiSchema for a question.
function generateUIQuestion(
  key,
  section,
  editMode,
  allOf,
  schema,
  isRepeteable = false,
  initialValue,
  formId,
  isGroup
) {
  const question = section.properties[key];
  let questionChildren = [];
  if (question.data.showTitle) {
    questionChildren.push({
      component: fieldTypes.formLabel,
      fieldOptions: {
        props: {
          editMode: editMode,
          isRequired: isRequiredById(key, schema),
          label: question.title,
          questionId: key
        }
      }
    });
  }
  if (question.data.showDescription) {
    questionChildren.push({
      component: fieldTypes.formQuestionDescription,
      fieldOptions: {
        props: {
          editMode: editMode,
          questionId: key
        },
        domProps: {
          innerHTML: question.description
        }
      }
    });
  }

  let placeholder;
  if (question.data.placeholder) {
    placeholder = question.data.placeholder
      .replace(/&amp;/gi, '&')
      .replace(/<\/?[^>]+(>|$)/g, '')
      .replace('&lt;', '<')
      .replace('&gt;', '>');
  }

  switch (question['field-type']) {
    case fieldTypes.questionOtherAnswer:
      if (!isGroup) {
        questionChildren.push({
          component: fieldTypes.formInput,
          model: key,
          fieldOptions: {
            class: ['d-none'],
            on: ['input'],
            attrs: {
              id: key,
              disabled: editMode
            }
          }
        });
      }

      questionChildren.push(getRequiredWarning(key));

      break;
    case fieldTypes.questionOpenAnswer:
      const inputElement = {
        component: question.data.shortAnswer ? fieldTypes.formInput : fieldTypes.textArea,
        model: isRepeteable ? '' : key,
        fieldOptions: {
          class: ['form-control'],
          on: ['input'],
          attrs: {
            placeholder: placeholder,
            maxlength: question.data.maxCharacters,
            id: key,
            disabled: editMode,
            value: initialValue ? initialValue : '',
            type: 'text'
          },
          domProps: {
            innerHTML: initialValue ? initialValue : ''
          }
        }
      };
      if (isRequiredById(key, schema)) {
        inputElement.fieldOptions.attrs.required = 'required';
      }
      questionChildren.push(inputElement);
      questionChildren.push(getRequiredWarning(key));

      if (question.data.requireValidation) {
        questionChildren.push({
          component: question.data.shortAnswer ? fieldTypes.formInput : fieldTypes.textArea,
          model: isRepeteable ? '' : key + '/confirm',
          fieldOptions: {
            class: ['form-control', 'confirm'],
            on: ['input'],
            attrs: {
              placeholder: placeholder,
              maxlength: question.data.maxCharacters,
              id: key + '/confirm',
              disabled: editMode
            }
          }
        });
      }

      break;
    case fieldTypes.questionChoice:
      questionChildren.push({
        component: fieldTypes.questionChoice,
        fieldOptions: {
          props: {
            editMode: editMode,
            choices: question.enum ? question.enum : question.items.enum,
            type: question.data.type,
            enableOtherOption: question.data.enableOtherOption,
            model: key,
            initialValue: initialValue,
            isGroup: isGroup
          }
        }
      });
      questionChildren.push({
        component: fieldTypes.formInput,
        model: isRepeteable ? '' : key,
        fieldOptions: {
          class: ['d-none'],
          on: ['input'],
          attrs: {
            id: key
          }
        }
      });
      if (!editMode && question.data.enableOtherOption && !isGroup) {
        questionChildren.push({
          component: 'div',
          fieldOptions: {
            class: ['input-choice-other']
          },
          children: [
            {
              component: fieldTypes.formInput,
              model: `${key}/Other`,
              fieldOptions: {
                class: ['form-control'],
                on: ['input'],
                attrs: {
                  placeholder: 'Other',
                  id: `${key}/Other`
                }
              },
              displayOptions: {
                model: key,
                schema: {
                  type: 'string',
                  const: question.enum
                    ? question.enum[question.enum.length - 1]
                    : '["' + question.items.enum[question.items.enum.length - 1] + '"]'
                }
              }
            }
          ]
        });

        questionChildren.push(getRequiredWarning(`${key}/Other`));
      }

      questionChildren.push(getRequiredWarning(key));

      break;
    case fieldTypes.questionRating:
      questionChildren.push({
        component: fieldTypes.questionRating,
        fieldOptions: {
          props: {
            editMode: editMode,
            minLabel: question.data.minLabel,
            maxLabel: question.data.maxLabel,
            min: question.min,
            max: question.max,
            value: Number(initialValue || question.value),
            showLabels: question.data.showLabels,
            model: key,
            variant: question.variant
          }
        }
      });

      questionChildren.push({
        component: fieldTypes.formInput,
        model: isRepeteable ? '' : key,
        fieldOptions: {
          class: ['d-none'],
          on: ['input'],
          attrs: {
            id: key,
            type: 'number'
          }
        }
      });

      questionChildren.push(getRequiredWarning(key));

      break;
    case fieldTypes.questionFile:
      let fileKey = `${section.index}.${key}`;
      (question.data.maxSize = question.data.maxSize === 50000000 ? 10000000 : question.data.maxSize),
        questionChildren.push({
          component: fieldTypes.questionFile,
          model: key,
          fieldOptions: {
            props: {
              model: key,
              editMode: editMode,
              fileType: question.data.fileType,
              maxFiles: question.data.maxFiles,
              maxSize: question.data.maxSize,
              formId: formId,
              fileKey: fileKey,
              availableExtensions: question.data.availableExtensions
            }
          }
        });
      questionChildren.push({
        component: fieldTypes.formInput,
        model: isRepeteable ? '' : key,
        fieldOptions: {
          class: ['d-none'],
          on: ['input'],
          attrs: {
            id: key
          }
        }
      });

      questionChildren.push(getRequiredWarning(key));

      break;
    case fieldTypes.questionDate:
      questionChildren.push({
        component: fieldTypes.questionDate,
        model: key,
        fieldOptions: {
          props: {
            editMode: editMode,
            placeholder: placeholder,
            model: key,
            initialValue: initialValue
          }
        }
      });
      questionChildren.push({
        component: fieldTypes.formInput,
        model: isRepeteable ? '' : key,
        fieldOptions: {
          class: ['d-none'],
          on: ['input'],
          attrs: {
            id: key
          }
        }
      });

      questionChildren.push(getRequiredWarning(key));

      break;
    case fieldTypes.questionPhone:
      questionChildren.push({
        component: fieldTypes.questionPhone,
        model: key,
        fieldOptions: {
          props: {
            editMode: editMode,
            placeholder: placeholder,
            isRequired: isRequiredById(key, schema),
            model: key,
            initialValue: initialValue
          }
        }
      });
      questionChildren.push({
        component: fieldTypes.formInput,
        model: isRepeteable ? '' : key,
        fieldOptions: {
          class: ['d-none'],
          on: ['input'],
          attrs: {
            id: key
          }
        }
      });

      questionChildren.push(getRequiredWarning(key));

      questionChildren.push(getCorrectPhone(key));
      break;
    case fieldTypes.questionCountry:
      questionChildren.push({
        component: fieldTypes.questionCountry,
        model: key,
        fieldOptions: {
          props: {
            editMode: editMode,
            placeholder: placeholder,
            model: key,
            initialValue: initialValue
          }
        }
      });
      questionChildren.push({
        component: fieldTypes.formInput,
        model: isRepeteable ? '' : key,
        fieldOptions: {
          class: ['d-none'],
          on: ['input'],
          attrs: {
            id: key
          }
        }
      });

      questionChildren.push(getRequiredWarning(key));

      break;
    case fieldTypes.questionAddress:
      questionChildren.push({
        component: fieldTypes.questionAddress,
        model: key,
        fieldOptions: {
          props: {
            editMode: editMode,
            displayAdminArea: question.data.displayAdminArea,
            displayAddress2: question.data.displayAddress2,
            required: isRequiredById(key, schema),
            model: key,
            initialValue: initialValue
          }
        }
      });
      questionChildren.push({
        component: fieldTypes.formInput,
        model: isRepeteable ? '' : key,
        fieldOptions: {
          class: ['d-none'],
          on: ['input'],
          attrs: {
            id: key
          }
        }
      });

      questionChildren.push(getRequiredWarning(key));

      break;

    case fieldTypes.questionGDPR:
      questionChildren.push({
        component: fieldTypes.questionGDPR,
        model: key,
        fieldOptions: {
          props: {
            editMode: editMode,
            model: key,
            label: question.data.label,
            gdprText: question.data.gdprText,
            showGdprText: question.data.showGdprText,
            initialValue: initialValue,
            questionId: key
          }
        }
      });
      questionChildren.push({
        component: fieldTypes.formInput,
        model: isRepeteable ? '' : key,
        fieldOptions: {
          class: ['d-none'],
          on: ['input'],
          attrs: {
            id: key
          }
        }
      });

      questionChildren.push(getRequiredWarning(key));

      break;
    case fieldTypes.questionIban:
      questionChildren.push({
        component: fieldTypes.questionIban,
        model: key,
        fieldOptions: {
          props: {
            editMode: editMode,
            placeholder: placeholder,
            model: key,
            required: isRequiredById(key, schema),
            initialValue: initialValue
          }
        }
      });
      questionChildren.push({
        component: fieldTypes.formInput,
        model: isRepeteable ? '' : key,
        fieldOptions: {
          class: ['d-none'],
          on: ['input'],
          attrs: {
            id: key
          }
        }
      });

      questionChildren.push(getRequiredWarning(key, 'string'));

      questionChildren.push(getCorrectIBAN(key));
      break;
    case fieldTypes.questionRegExp:
      questionChildren.push({
        component: fieldTypes.questionRegExp,
        model: key,
        fieldOptions: {
          props: {
            editMode: editMode,
            placeholder: placeholder,
            pattern: question.pattern,
            error: question.data.error,
            model: key,
            required: isRequiredById(key, schema),
            initialValue: initialValue
          }
        }
      });
      questionChildren.push({
        component: fieldTypes.formInput,
        model: isRepeteable ? '' : key,
        fieldOptions: {
          class: ['d-none'],
          on: ['input'],
          attrs: {
            id: key
          }
        }
      });
      questionChildren.push(getRequiredWarning(key, 'string'));
      questionChildren.push(getCorrectPattern(question.data.error, key));

      break;
    case fieldTypes.questionNumber:
      questionChildren.push({
        component: fieldTypes.questionNumber,
        model: key,
        fieldOptions: {
          props: {
            editMode: editMode,
            placeholder: placeholder,
            model: key,
            required: isRequiredById(key, schema),
            initialValue: initialValue,
            min: question.data.showMin ? question.minimum : undefined,
            max: question.data.showMax ? question.maximum : undefined
          }
        }
      });
      questionChildren.push({
        component: fieldTypes.formInput,
        model: isRepeteable ? '' : key,
        fieldOptions: {
          class: ['d-none'],
          on: ['input'],
          attrs: {
            id: key
          }
        }
      });
      questionChildren.push(getRequiredWarning(key, 'string'));
      questionChildren.push(getCorrectNumber(key));

      break;
    case fieldTypes.questionEmail:
      questionChildren.push({
        component: fieldTypes.questionEmail,
        model: key,
        fieldOptions: {
          props: {
            editMode: editMode,
            placeholder: placeholder,
            model: key,
            required: isRequiredById(key, schema),
            initialValue: initialValue
          }
        }
      });
      questionChildren.push({
        component: fieldTypes.formInput,
        model: isRepeteable ? '' : key,
        fieldOptions: {
          class: ['d-none'],
          on: ['input'],
          attrs: {
            id: key
          }
        }
      });

      questionChildren.push(getRequiredWarning(key, 'string'));
      questionChildren.push(getCorrectEmail(key));
      break;
    default:
      break;
  }
  let displayOptions = {};
  let ifValues = getConditionalById(key, allOf);
  if (ifValues && ifValues.length > 0) {
    displayOptions = {
      schema: {
        type: 'object',
        anyOf: []
      }
    };
    ifValues.forEach((e) => {
      if (displayOptions.schema.anyOf.find((elem) => elem.required[0] === e.question)) {
        displayOptions.schema.anyOf
          .find((elem) => elem.required[0] === e.question)
          .properties[e.question].oneOf.push({ type: 'string', const: e.value });
      } else {
        let properties = {};
        properties[e.question] = { type: 'string', oneOf: [{ type: 'string', const: e.value }] };
        displayOptions.schema.anyOf.push({ properties, required: [e.question] });
      }
    });
  }

  let sectionId;
  let groupId;
  if (section.index.indexOf('.')) {
    sectionId = section.index.split('.')[0];
    groupId = section.index.split('.')[1];
  } else {
    sectionId = section.index;
  }
  return {
    component: fieldTypes.formQuestion,
    fieldOptions: {
      class: [
        `${question['field-type'] === fieldTypes.questionOtherAnswer ? 'd-none' : ''}`,
        Object.keys(displayOptions).length >= 1 ? 'required' : ''
      ],
      attrs: {
        id: key
      },
      props: {
        editMode: editMode,
        questionId: key,
        sectionId: sectionId,
        groupId: groupId
      }
    },
    displayOptions: !editMode ? displayOptions : {},
    children: questionChildren
  };
}

//Get the allOf value for an ID.
function getConditionalById(id, allOf) {
  if (allOf) {
    const ifvalues = allOf.filter((e) =>
      e.then.properties[Object.keys(e.then.properties)[0]].required
        ? e.then.properties[Object.keys(e.then.properties)[0]].required[0] === id
        : false
    );
    if (ifvalues && ifvalues.length > 0) {
      return ifvalues.map((e) => {
        return {
          section: Object.keys(e.if.properties)[0],
          question: Object.keys(e.if.properties[Object.keys(e.if.properties)[0]].properties)[0],
          value:
            e.if.properties[Object.keys(e.if.properties)[0]].properties[
              Object.keys(e.if.properties[Object.keys(e.if.properties)[0]].properties)[0]
            ].const
        };
      });
    }
  }
  return undefined;
}

//Get if a question is required by an ID.
function isRequiredById(id, schema) {
  if (schema) {
    let map = Object.keys(schema.properties).map((e) =>
      schema.properties[e].required ? schema.properties[e].required : []
    );
    Object.keys(schema.properties).forEach((section) => {
      if (schema.properties[section].properties) {
        Object.keys(schema.properties[section].properties).forEach((question) => {
          if (
            schema.properties[section].properties[question].items &&
            schema.properties[section].properties[question].items.required
          ) {
            map = map.concat(schema.properties[section].properties[question].items.required);
          }
        });
      }
    });
    return flatten(map).includes(id);
  }
  return false;
}

function getRequiredWarning(key) {
  return {
    component: 'div',
    model: key,
    fieldOptions: {
      domProps: {
        innerHTML: `<i class="material-icons">cancel</i> ${Vue.i18n.translate('form.requiredField')}`
      },
      class: [`alert alert-danger warning-required-${key}`]
    }
  };
}

function getCorrectIBAN(key) {
  return {
    component: 'div',
    model: key,
    fieldOptions: {
      domProps: {
        innerHTML: `<i class="material-icons">cancel</i> ${Vue.i18n.translate('form.requiredIban')}`
      },
      class: [`alert alert-danger warning-pattern-${key}`]
    }
  };
}

function getCorrectEmail(key) {
  return {
    component: 'div',
    model: key,
    fieldOptions: {
      domProps: {
        innerHTML: `<i class="material-icons">cancel</i> ${Vue.i18n.translate('form.requiredMail')}`
      },
      class: [`alert alert-danger warning-pattern-${key}`]
    }
  };
}

function getCorrectPhone(key) {
  return {
    component: 'div',
    model: key,
    fieldOptions: {
      domProps: {
        innerHTML: `<i class="material-icons">cancel</i> ${Vue.i18n.translate('form.requiredPhone')}`
      },
      class: [`alert alert-danger warning-pattern-${key}`]
    }
  };
}

function getCorrectPattern(error, key) {
  return {
    component: 'div',
    model: key,
    fieldOptions: {
      domProps: {
        innerHTML: `<i class="material-icons">cancel</i>${error ? error : Vue.i18n.translate('form.requiredPattern')}`
      },
      class: [`alert alert-danger warning-pattern-${key}`]
    }
  };
}

function getCorrectNumber(key) {
  return {
    component: 'div',
    model: key,
    fieldOptions: {
      class: [`alert alert-danger warning-number-${key}`]
    }
  };
}
