import Vue from 'vue';
import IBAN from 'iban';

export default {
  getMessage(field, params, data) {
    return (data && data.message) || Vue.i18n.translate('validation.iban');
  },
  validate(value) {
    return new Promise((resolve) => {
      resolve({
        valid: IBAN.isValid(
          value
            .replace(/\//g, '')
            .replace(/-/g, '')
            .replace(/\./g, '')
            .replace(/ /g, '')
        ),
        data: value !== 'trigger' ? undefined : { message: 'Not this value' }
      });
    });
  }
};
