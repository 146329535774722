import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import vuexI18n from 'vuex-i18n'; // load vuex i18n module

Vue.use(Vuex);

import auth from '@admin/store/auth';
import dashboard from '@admin/store/dashboard';
import learningPages from '@lp/store/learning-pages';
import module from '@lp/store/module';
import library from '@lp/store/library';
import editor from '@shared/store/editor';
import template from '@lp/store/template';
import settings from '@shared/store/settings';
import forms from '@forms/store/forms';
import formBuilder from '@forms/store/form-builder';
import loading from '@shared/store/loading';

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  modules: {
    dashboard, // mock some dashboard data
    learningPages,
    library,
    editor,
    module,
    template,
    settings,
    auth,
    forms,
    formBuilder,
    loading
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
});

if (module.hot) {
  // accept actions and mutations as hot modules
  module.hot.accept(
    [
      '@lp/store/learning-pages',
      '@lp/store/library',
      '@shared/store/editor',
      '@lp/store/module',
      '@lp/store/template',
      '@shared/store/settings',
      '@admin/store/auth',
      '@forms/store/forms',
      '@forms/store/form-builder',
      '@shared/store/loading'
    ],
    () => {
      // require the updated modules
      // have to add .default here due to babel 6 module output
      const newDashboard = require('@lp/store/dashboard').default;
      const newLearningPages = require('@lp/store/learning-pages').default;
      const newLibrary = require('@lp/store/library').default;
      const newEditor = require('@shared/store/editor').default;
      const newModule = require('@lp/store/module').default;
      const newTemplate = require('@lp/store/template').default;
      const newSettings = require('@shared/store/settings').default;
      const newAuth = require('@admin/store/auth').default;
      const newForms = require('@forms/store/forms').default;
      const newFormBuilder = require('@forms/store/form-builder').default;
      const newLoading = require('@shared/store/loading').default;
      // swap in the new actions and mutations
      store.hotUpdate({
        modules: {
          dashboard: newDashboard,
          learningPages: newLearningPages,
          library: newLibrary,
          editor: newEditor,
          module: newModule,
          template: newTemplate,
          settings: newSettings,
          auth: newAuth,
          forms: newForms,
          formBuilder: newFormBuilder,
          loading: newLoading
        }
      });
    }
  );
}

// initialize the internationalization plugin on the vue instance. note that
// the store must be passed to the plugin. the plugin will then generate some
// helper functions for components (i.e. this.$i18n.set, this.$t) and on the vue
// instance (i.e. Vue.i18n.set).
Vue.use(vuexI18n.plugin, store, { translateFilterName: 't' });

// override $t to accept lowerCase
(function($t) {
  Vue.prototype.$t = function() {
    arguments[0] = arguments[0].toLowerCase();
    return $t.apply(this, arguments);
  };
})(Vue.prototype.$t);

export default store;
