import Vue from 'vue';
import { uuid } from 'vue-uuid';
import { ColorPicker } from 'element-ui';
import MediumEditor from 'medium-editor/dist/js/medium-editor';

const rangy = require('rangy/lib/rangy-core.js');
require('rangy/lib/rangy-textrange');
require('rangy/lib/rangy-selectionsaverestore');

const vueColorPickerArray = [];
const editorsElement = [];
let predefinedColors;
let storedSelection;
let currentSelection;
export default function mediumEditor(selector, buttons, colors) {
  if (colors) {
    predefinedColors = colors;
  }
  const setMediumEditor = (selector, buttons) => {
    const colorPickerId = uuid.v4();
    let extensions = {};
    if (buttons.indexOf('boldStyle') > -1) {
      extensions.boldStyle = boldStyle();
    }
    if (buttons.indexOf('underline') > -1) {
      extensions.underline = underline();
    }
    if (buttons.includes('colorPicker') > -1) {
      extensions.colorPicker = colorpicker(colorPickerId);
    }
    if (buttons.indexOf('bulletList') > -1) {
      extensions.bulletList = bulletList();
    }
    if (buttons.indexOf('italicStyle') > -1) {
      extensions.italicStyle = italicStyle();
    }
    if (buttons.indexOf('anchorLink') > -1) {
      extensions.anchorLink = anchorLink();
      extensions.anchorPreview = anchorPreview();
    }
    extensions.paste = pasteHandler();
    const editor = new MediumEditor(selector, {
      targetBlank: true,
      toolbar: {
        buttons: buttons
      },
      static: true,
      placeholder: false,
      extensions: extensions
    });

    if (buttons.includes('colorPicker')) {
      editorsElement.push(editor);
    }
    editor.subscribe('editableKeydownEnter', function(event) {
      if (navigator.userAgent.indexOf('Firefox') !== -1 && event.keyCode === 13) {
        MediumEditor.selection.getSelectionStart(editor.options.ownerDocument);
        document.execCommand('defaultParagraphSeparator', false, 'p');
      }
    });
    return editor;
  };

  setMediumEditor(selector, buttons);
}

const colorpicker = (colorPickerId) => {
  const ColorPickerExtension = MediumEditor.extensions.button.extend({
    name: 'colorPicker',
    action: 'applyForeColor',
    aria: 'color picker',
    contentDefault: "<span class='editor-color-picker'>Text Color<span>",

    init: function() {
      this.button = this.document.createElement('button');
      this.button.classList.add('medium-editor-action');
      this.button.innerHTML = `<div id="${colorPickerId}"></div>`;

      this.on(this.button, 'mousedown', this.handleMouseDown.bind(this));
      this.subscribe('showToolbar', (event, editorElement) => {
        let vueColorPicker;
        if (document.querySelector(`[id="${colorPickerId}"]`)) {
          vueColorPicker = new Vue({
            el: `[id="${colorPickerId}"]`,
            template: `<el-color-picker @change="update" v-model="color" size="small" :predefine="predefinedColors"></el-color-picker>`,
            components: { elColorPicker: ColorPicker },
            data() {
              return {
                color: getTextColor(),
                predefinedColors
              };
            },
            methods: {
              update(color) {
                setColor(color);
              }
            }
          });
          vueColorPickerArray.push(vueColorPicker);
        }
        // Set color to all medium-colorpickers.
        vueColorPickerArray.forEach((e) => {
          e.color = getTextColor();
        });
      });
    },
    handleMouseDown: function(event) {
      storedSelection = rangy.saveSelection();
    }
  });

  const pickerExtension = new ColorPickerExtension();

  function getTextColor() {
    // const color = window.getSelection().focusNode.parentElement.style.color;
    const color = document.queryCommandValue('foreColor');
    if (color.includes('rgb')) {
      return `#${color
        .match(/\((.*)\)/)
        .pop()
        .split(',')
        .map((e) => ('0' + parseInt(e).toString(16)).slice(-2))
        .join('')}`;
    }
    return '#000001';
  }

  function setColor(color) {
    rangy.restoreSelection(storedSelection);
    rangy.removeMarkers(storedSelection);
    pickerExtension.document.execCommand('styleWithCSS', false, true);
    pickerExtension.document.execCommand('foreColor', false, color);
  }

  return pickerExtension;
};

const bulletList = () => {
  const bulletListExtension = MediumEditor.extensions.button.extend({
    name: 'bulletList',
    action: 'insertUnorderedList',
    aria: 'Unordered list',
    contentDefault: '<b>•</b>',
    useQueryState: true,

    init: function() {
      this.button = this.document.createElement('button');
      this.button.classList.add('medium-editor-action');
      this.button.innerHTML = `<b>•</b>`;
      this.on(this.button, 'click', this.handleClick.bind(this));
      this.on(this.button, 'mousedown', this.handleMouseDown.bind(this));
    },

    handleClick: function(event) {
      rangy.restoreSelection(storedSelection);
      rangy.removeMarkers(storedSelection);
      document.execCommand('insertUnorderedList');
      this.base.checkSelection();
    },
    handleMouseDown: function(event) {
      storedSelection = rangy.saveSelection();
    }
  });

  const bulletPickerExtension = new bulletListExtension();

  return bulletPickerExtension;
};

const boldStyle = () => {
  const boldExtension = MediumEditor.extensions.button.extend({
    name: 'boldStyle',
    action: 'bold',
    aria: 'Bold',
    contentDefault: '<b>B</b>',
    useQueryState: true,

    init: function() {
      this.button = this.document.createElement('button');
      this.button.classList.add('medium-editor-action');
      this.button.innerHTML = `<b>B</b>`;
      this.on(this.button, 'click', this.handleClick.bind(this));
      this.on(this.button, 'mousedown', this.handleMouseDown.bind(this));
    },

    handleClick: function(event) {
      rangy.restoreSelection(storedSelection);
      rangy.removeMarkers(storedSelection);
      document.execCommand('bold');
      this.base.checkSelection();
    },
    handleMouseDown: function(event) {
      storedSelection = rangy.saveSelection();
    }
  });

  const boldStyleExtension = new boldExtension();

  return boldStyleExtension;
};

const underline = () => {
  const underlineExtension = MediumEditor.extensions.button.extend({
    name: 'underline',
    action: 'underline',
    aria: 'Underline',
    contentDefault: '<u>U</u>',
    useQueryState: true,

    init: function() {
      this.button = this.document.createElement('button');
      this.button.classList.add('medium-editor-action');
      this.button.innerHTML = `<u>U</u>`;
      this.on(this.button, 'click', this.handleClick.bind(this));
      this.on(this.button, 'mousedown', this.handleMouseDown.bind(this));
    },

    handleClick: function(event) {
      rangy.restoreSelection(storedSelection);
      rangy.removeMarkers(storedSelection);
      document.execCommand('underline');
      this.base.checkSelection();
    },
    handleMouseDown: function(event) {
      storedSelection = rangy.saveSelection();
    }
  });

  const boldStyleExtension = new underlineExtension();

  return boldStyleExtension;
};

const italicStyle = () => {
  const italicExtension = MediumEditor.extensions.button.extend({
    name: 'italicStyle',
    action: 'italic',
    aria: 'Italic',
    contentDefault: '<b><i>I</i></b>',
    useQueryState: true,

    init: function() {
      this.button = this.document.createElement('button');
      this.button.classList.add('medium-editor-action');
      this.button.innerHTML = `<b><i>I</i></b>`;
      this.on(this.button, 'click', this.handleClick.bind(this));
      this.on(this.button, 'mousedown', this.handleMouseDown.bind(this));
    },

    handleClick: function(event) {
      rangy.restoreSelection(storedSelection);
      rangy.removeMarkers(storedSelection);
      document.execCommand('italic');
      this.base.checkSelection();
    },
    handleMouseDown: function(event) {
      storedSelection = rangy.saveSelection();
    }
  });

  const italicStyleExtension = new italicExtension();

  return italicStyleExtension;
};

const pasteHandler = () => {
  const CustomPasteHandler = MediumEditor.extensions.paste.extend({
    forcePlainText: true,
    cleanPastedHTML: false,
    cleanTags: ['div', 'meta'],
    unwrapTags: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'table', 'td', 'ul', 'li'],
    cleanAttrs: ['class', 'style', 'dir'],
    init: function() {
      this.getEditorElements().forEach(function(element) {
        this.on(element, 'paste', this.handlePaste.bind(this));
      }, this);
    }
  });
  const pasteHandler = new CustomPasteHandler();
  return pasteHandler;
};

const anchorLink = () => {
  const anchorLinkHandler = MediumEditor.extensions.anchor.extend({
    placeholderText: 'Paste or type a link',
    name: 'anchorLink',
    action: 'createLink',
    aria: 'link',
    tagNames: ['a'],
    contentDefault:
      '<svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="miter" style="filter: invert(86%) sepia(0%) saturate(937%) hue-rotate(209deg) brightness(82%) contrast(81%)"><path d="M11,14.52a5.72,5.72,0,0,1-.82-.67h0A5.72,5.72,0,0,1,9.48,13,5.67,5.67,0,0,1,9,12.12a5.63,5.63,0,0,1-.28-1,4.78,4.78,0,0,1-.1-1,4.85,4.85,0,0,1,.1-1,5,5,0,0,1,.76-1.87,5.22,5.22,0,0,1,.67-.82l.37-.37.37-.37.37-.37L11.63,5,12,4.61l.37-.37.37-.37.37-.37a4.72,4.72,0,0,1,.82-.67,5.27,5.27,0,0,1,.91-.48,5.42,5.42,0,0,1,1-.29,5.55,5.55,0,0,1,2,0,5.42,5.42,0,0,1,1,.29,5.27,5.27,0,0,1,.91.48,5.12,5.12,0,0,1,.82.67h0a5.12,5.12,0,0,1,.67.82,5.27,5.27,0,0,1,.48.91,5.42,5.42,0,0,1,.29,1,5.55,5.55,0,0,1,0,2,5.42,5.42,0,0,1-.29,1,5.27,5.27,0,0,1-.48.91,4.72,4.72,0,0,1-.67.82l-.37.37-.37.37-.37.37-.37.37" style="/* fill: #021c2c; */"></path><path d="M13,9.48a5.72,5.72,0,0,1,.82.67h0a5.72,5.72,0,0,1,.67.82,5,5,0,0,1,.76,1.88,4.78,4.78,0,0,1,.1,1,4.85,4.85,0,0,1-.1,1,5,5,0,0,1-.76,1.87,5.22,5.22,0,0,1-.67.82l-.37.37-.37.37-.37.37-.37.37-.37.37-.37.37-.37.37-.37.37a4.72,4.72,0,0,1-.82.67,5.27,5.27,0,0,1-.91.48,5.42,5.42,0,0,1-1,.29,5.55,5.55,0,0,1-2,0,5.42,5.42,0,0,1-1-.29,5.27,5.27,0,0,1-.91-.48,5.12,5.12,0,0,1-.82-.67h0a5.12,5.12,0,0,1-.67-.82,5.27,5.27,0,0,1-.48-.91,5.42,5.42,0,0,1-.29-1,5.55,5.55,0,0,1,0-2,5.42,5.42,0,0,1,.29-1,5.27,5.27,0,0,1,.48-.91,4.72,4.72,0,0,1,.67-.82l.37-.37.37-.37L4.61,12,5,11.63"></path></svg>',
    contentFA: '<i class="t-icon bold-01_interface_essentials-link_unlink-hyperlink_3"></i>',

    init: function() {
      this.button = this.document.createElement('button');
      this.button.classList.add('medium-editor-action');
      this.button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="miter" style="filter: invert(86%) sepia(0%) saturate(937%) hue-rotate(209deg) brightness(82%) contrast(81%)"><path d="M11,14.52a5.72,5.72,0,0,1-.82-.67h0A5.72,5.72,0,0,1,9.48,13,5.67,5.67,0,0,1,9,12.12a5.63,5.63,0,0,1-.28-1,4.78,4.78,0,0,1-.1-1,4.85,4.85,0,0,1,.1-1,5,5,0,0,1,.76-1.87,5.22,5.22,0,0,1,.67-.82l.37-.37.37-.37.37-.37L11.63,5,12,4.61l.37-.37.37-.37.37-.37a4.72,4.72,0,0,1,.82-.67,5.27,5.27,0,0,1,.91-.48,5.42,5.42,0,0,1,1-.29,5.55,5.55,0,0,1,2,0,5.42,5.42,0,0,1,1,.29,5.27,5.27,0,0,1,.91.48,5.12,5.12,0,0,1,.82.67h0a5.12,5.12,0,0,1,.67.82,5.27,5.27,0,0,1,.48.91,5.42,5.42,0,0,1,.29,1,5.55,5.55,0,0,1,0,2,5.42,5.42,0,0,1-.29,1,5.27,5.27,0,0,1-.48.91,4.72,4.72,0,0,1-.67.82l-.37.37-.37.37-.37.37-.37.37" style="/* fill: #021c2c; */"></path><path d="M13,9.48a5.72,5.72,0,0,1,.82.67h0a5.72,5.72,0,0,1,.67.82,5,5,0,0,1,.76,1.88,4.78,4.78,0,0,1,.1,1,4.85,4.85,0,0,1-.1,1,5,5,0,0,1-.76,1.87,5.22,5.22,0,0,1-.67.82l-.37.37-.37.37-.37.37-.37.37-.37.37-.37.37-.37.37-.37.37a4.72,4.72,0,0,1-.82.67,5.27,5.27,0,0,1-.91.48,5.42,5.42,0,0,1-1,.29,5.55,5.55,0,0,1-2,0,5.42,5.42,0,0,1-1-.29,5.27,5.27,0,0,1-.91-.48,5.12,5.12,0,0,1-.82-.67h0a5.12,5.12,0,0,1-.67-.82,5.27,5.27,0,0,1-.48-.91,5.42,5.42,0,0,1-.29-1,5.55,5.55,0,0,1,0-2,5.42,5.42,0,0,1,.29-1,5.27,5.27,0,0,1,.48-.91,4.72,4.72,0,0,1,.67-.82l.37-.37.37-.37L4.61,12,5,11.63"></path></svg>`;
      this.on(this.button, 'click', this.handleClick.bind(this));
      this.on(this.button, 'mousedown', this.handleMouseDown.bind(this));
    },
    getButton: function() {
      return this.button;
    },
    handleClick: function(event) {
      event.preventDefault();
      event.stopPropagation();
      if (
        currentSelection.anchorNode.nodeName.toLowerCase() === 'a' ||
        currentSelection.focusNode.nodeName.toLowerCase() === 'a'
      ) {
        rangy.restoreSelection(storedSelection);
        rangy.removeMarkers(storedSelection);
        return document.execCommand('unlink');
      }
      if (!this.isDisplayed()) {
        this.showForm();
      }

      return false;
    },
    handleMouseDown: function() {
      storedSelection = rangy.saveSelection();
      currentSelection = rangy.getSelection();
    },
    completeFormSave: function(opts) {
      rangy.restoreSelection(storedSelection);
      rangy.removeMarkers(storedSelection);

      document.execCommand('createLink', false, opts.value);
      this.base.checkSelection();
    }
  });

  const anchorHandler = new anchorLinkHandler();
  return anchorHandler;
};

const anchorPreview = () => {
  const anchorPreviewExtension = MediumEditor.extensions.anchorPreview.extend({
    anchorPreviewDisplaySelector: true,
    name: 'anchor-preview',
    previewValueSelector: 'a',

    handleClick: function(e) {
      const anchorExtension = this.base.getExtensionByName('anchorLink'),
        activeAnchor = this.activeAnchor;
      if (anchorExtension && activeAnchor) {
        e.preventDefault();
        this.base.selectElement(this.activeAnchor);
        this.base.delay(
          function() {
            if (activeAnchor) {
              const opts = {
                value: activeAnchor.attributes.href.value,
                target: activeAnchor.getAttribute('target'),
                buttonClass: activeAnchor.getAttribute('class')
              };
              anchorExtension.showForm(opts);
              activeAnchor = null;
            }
          }.bind(this)
        );
      }
      this.hidePreview();
    }
  });
  const anchorPreviewHandler = new anchorPreviewExtension();
  return anchorPreviewHandler;
};
