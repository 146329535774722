<template>
  <div class="row mt-2 mb-1">
    <div class="col-6">
      <label :for="id">{{ label }}</label>
    </div>
    <div class="col-6">
      <div class="color-picker-wrapper" :class="{ 'reverse-icon': lowerCaseColor == '#ffffff' }">
        <el-color-picker
          :predefine="predefinedColors"
          @change="$emit('change', color)"
          class="mr-1 float-left"
          size="mini"
          v-model="color"
          name="color"
        ></el-color-picker>
        <span>
          {{ getColorName }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ColorPicker } from 'element-ui';
import { mapGetters } from 'vuex';

export default {
  props: {
    id: {
      type: String,
      default: 'color'
    },
    label: {
      type: String
    },
    value: {
      type: String
    }
  },
  components: {
    elColorPicker: ColorPicker
  },
  computed: {
    ...mapGetters('settings', ['predefinedColors']),
    getColorName() {
      const index = this.predefinedColors.indexOf(this.lowerCaseColor);
      if (index < 0 || index > 3) {
        return this.$t('ui.colorpicker.custom');
      }

      switch (index) {
        case 0:
          return this.$t('ui.colorpicker.primary');
        case 1:
          return this.$t('ui.colorpicker.secondary');
        case 2:
          return this.$t('ui.colorpicker.white');
        case 3:
          return this.$t('ui.colorpicker.black');
      }
    },
    lowerCaseColor() {
      if (this.color) {
        return this.color.toLowerCase();
      }
      return '#ffffff';
    }
  },
  data() {
    return {
      color: null
    };
  },
  methods: {
    updateColor(value) {
      this.color = value;
    }
  },
  watch: {
    value: {
      handler: 'updateColor',
      immediate: true
    }
  }
};
</script>

<style lang="scss">
@import '@shared/styles/_colors.scss';

.el-color-predefine__color-selector > div {
  border: 1px solid $color-grey;
}

.color-picker-wrapper {
  border: 1px solid $color-lightgrey;
  border-radius: 5px;
  height: 30px;
  cursor: default;

  & > span {
    float: left;
    padding: 5px 5px 0 0;
    color: $color-darkgrey;
  }

  .el-color-picker__trigger {
    border: none;
  }

  .el-color-picker__icon {
    width: 0;
    height: 0;
    border-bottom: 5px solid white;
    border-left: 5px solid transparent;
    transform: translate3d(2px, 2px, 0) !important;
  }

  .el-icon-arrow-down:before {
    content: '';
  }
}

.color-picker-wrapper.reverse-icon {
  .el-color-picker__icon {
    border-bottom: 5px solid black;
  }
}
</style>
