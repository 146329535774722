import Vue from 'vue';
import axios from 'axios';

import camelCase from 'lodash/camelCase';
import each from 'lodash/each';
import find from 'lodash/find';
import isObject from 'lodash/isObject';
import identity from 'lodash/identity';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';

import variables, {
  LIGHT_GREY,
  primaryKeys,
  secondaryKeys,
  ctaKeys,
  textKeys,
  whiteKeys,
  blackKeys,
  map
} from './variables';

const { VUE_APP_ADMIN_API_URL, VUE_APP_API_URL } = process.env;

import theme from '@shared/mixins/theme';

const availableFonts = theme.data().availableFonts;

const settings = {
  namespaced: true,
  state: {
    companyData: null,
    styles: null,
    theme: null,
    sidebarShown: false,
    selectedCompany: null,
    terminologyModels: [],
    enabledFeatures: [],
    featuresWithConsent: {
      vimeo: true,
      youtube: true,
      googleMaps: true
    }
  },
  getters: {
    availableLanguages(state) {
      return state.companyData ? state.companyData.availableLanguages : [];
    },
    company(state) {
      return state.companyData;
    },
    defaultLanguage(state) {
      const id = state.companyData && state.companyData.defaultLanguageId;
      if (!id) return null;
      const found = find(state.companyData.languages, { id });
      if (!found) return null;
      return found.localeCode;
    },
    style(state) {
      let themeVariables = {};
      if (!state.styles) return {};

      Object.keys(variables).forEach((group) => {
        each(variables[group], (v, k) => {
          let key = camelCase(k.replace('$', ''));
          if (state.styles[key]) {
            themeVariables[key] = state.styles[key];
          } else {
            themeVariables[key] = v;
          }
        });
      });
      if (state.styles.advanced) {
        themeVariables['advanced'] = state.styles.advanced;
      }
      return { ...themeVariables };
    },
    predefinedColors(state, getters) {
      let colors = [
        getters.style.colorPrimary,
        getters.style.colorSecondary,
        getters.style.colorCta,
        getters.style.colorText,
        '#FFF',
        '#000'
      ];
      return uniq(colors);
    },
    subdomain() {
      let subdomain = window.location.host.split('.')[0];
      if (process.env.VUE_APP_LOCALHOST_COMPANY && window.location.host.includes(':5000')) {
        subdomain = process.env.VUE_APP_LOCALHOST_COMPANY;
      }
      return subdomain;
    },
    themeRaw(state) {
      const theme = {
        ...pick(state.theme, Object.keys(variables).join(','))
      };
      each(theme, (value) => {
        let originalValue = value;
        let isSum = false;
        if (!isObject(value) && value.indexOf(' - ') > 0) {
          value = value.substring(0, value.indexOf(' - '));
          isSum = true;
        }
        const found = state.theme[value];
        if (found) {
          if (isSum) {
            value =
              parseInt(found.replace('px', '')) +
              parseInt(originalValue.substring(originalValue.indexOf(' - '), originalValue.length).replace(/ /gi, '')) +
              'px';
          } else {
            value = found;
          }
        }
      });
      return theme;
    },
    sidebarShown(state) {
      return state.sidebarShown;
    },
    selectedCompany(state) {
      return state.selectedCompany;
    },
    theme(state, getters) {
      var groupedVariables = {};

      Object.keys(variables).forEach((group) => {
        if (group === 'advanced') {
          groupedVariables['advanced'] = state.theme ? state.theme['advanced'] || '' : '';
        } else {
          groupedVariables[group] = [];
        }
        each(variables[group], (v, k) => {
          let value = state.theme ? state.theme[k] || v : v;
          const showImage =
            isObject(value) || value.indexOf('.jpg') > 0 || value.indexOf('.jpeg') > 0 || value.indexOf('.png') > 0;

          let image = null;

          if (showImage) {
            image = value;
            value = value.fileName || value;
          }

          let font = null;
          let custom = null;

          if (group === 'fonts') {
            if (find(availableFonts, (font) => font.fileName == value)) {
              value = {
                fileName: value,
                custom: true
              };
            } else if (value.url) {
              value.custom = true;
            }
            font = true;
            custom = value.custom;
          }

          const obj = {
            key: k,
            value,
            showImage,
            image,
            font,
            custom,
            color: k.indexOf('color') > 0 || (group === 'background' && !isObject(value))
          };

          groupedVariables[group].push(pickBy(obj, identity));
        });
      });

      return groupedVariables;
    },
    enabledFeatures(state) {
      return state.enabledFeatures;
    },
    featureFormEnabled(state) {
      return state.enabledFeatures.find((feature) => feature.name === 'Forms v2' && feature.isActive);
    },
    featuresWithConsent(state) {
      return state.featuresWithConsent;
    },
    consentForYoutube(state) {
      return state.featuresWithConsent.youtube;
    },
    consentForVimeo(state) {
      return state.featuresWithConsent.vimeo;
    },
    consentForGoogleMaps(state) {
      return state.featuresWithConsent.googleMaps;
    }
  },
  mutations: {
    setCompanyData(state, payload) {
      state.companyData = payload;
    },
    setAvailableLanguages(state, payload) {
      state.companyData.availableLanguages = payload.languages;
    },
    setStyle(state, payload) {
      state.styles = payload;
    },
    setTerminologyModels(state, payload) {
      state.terminologyModels = payload;
    },
    setTheme(state, payload) {
      let themeVariables = {
        ...variables,
        ...payload
      };

      state.theme = omit(themeVariables, '$header-bar-background-color', '$dashboard-background');
    },
    updateValue(state, payload) {
      Vue.set(state.theme, payload.key, payload.value);
      Vue.set(state.styles, camelCase(payload.key.replace('$', '')), payload.value);
    },
    updateImage(state, payload) {
      Vue.set(state.theme, payload.key, payload.image);
      Vue.set(state.styles, camelCase(payload.key.replace('$', '')), payload.image);
    },
    toggleSidebar(state) {
      state.sidebarShown = !state.sidebarShown;
    },
    showSidebar(state) {
      state.sidebarShown = true;
    },
    closeSidebar(state) {
      state.sidebarShown = false;
    },
    setEnabledFeatures(state, enabledFeatures) {
      state.enabledFeatures = enabledFeatures;
    },
    setFeaturesWithConsent(state, payload) {
      state.featuresWithConsent = payload.content;
      window.localStorage.setItem(payload.key, JSON.stringify(state.featuresWithConsent));
    }
  },
  actions: {
    getCompanyData({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${VUE_APP_API_URL}/companies/subdomain/${this.getters['settings/subdomain']}`, {
            headers: {
              // Authorization: null
            }
          })
          .then(
            (response) => {
              commit('setCompanyData', { ...response.data });
              if (!response.data.style) {
                response.data.style = '{}';
              }
              let style = JSON.parse(response.data.style);

              if (!window.THEME) {
                if (style && !style['$header-icon-color']) {
                  if (style['$nav-icon-color']) {
                    style['$header-icon-color'] = style['$nav-icon-color'];
                    delete style['$nav-icon-color'];
                  } else if (style['$links-color']) {
                    style['$header-icon-color'] = style['$nav-icon-color'];
                    delete style['$links-color'];
                  }
                }
              }

              if (style && style['$panel-caption-background-color']) {
                style['$company-page-image-caption-background-color'] = style['$panel-caption-background-color'];
                delete style['$panel-caption-background-color'];
              }

              if (style && style['$panel-caption-text-color']) {
                style['$company-page-image-caption-text-color'] = style['$panel-caption-text-color'];
                delete style['$panel-caption-text-color'];
              }

              if (style && !style['$color-cta']) {
                style['$color-cta'] = style['$color-primary'];
              }

              if (style && !style['$color-text']) {
                style['$color-text'] = '#000';
              }

              primaryKeys.forEach((key) => {
                if (!style[key]) {
                  style[key] = map.primary;
                }
              });
              secondaryKeys.forEach((key) => {
                if (!style[key]) {
                  style[key] = map.secondary;
                }
              });
              ctaKeys.forEach((key) => {
                if (!style[key]) {
                  style[key] = map.cta;
                }
              });
              textKeys.forEach((key) => {
                if (!style[key]) {
                  style[key] = map.text;
                }
              });
              whiteKeys.forEach((key) => {
                if (!style[key]) {
                  style[key] = map.white;
                }
              });
              blackKeys.forEach((key) => {
                if (!style[key]) {
                  style[key] = map.black;
                }
              });

              if (style && !style['$subheader-background-color']) {
                style['$subheader-background-color'] = LIGHT_GREY;
              }

              if (style && !style['$color-cta']) {
                style['$color-cta'] = style['$color-primary'];
              }

              each(style, (value, key) => {
                if (!value) return;
                if (value === 'white') {
                  value = '#FFFFFF';
                }
                if (value === 'black') {
                  value = '#000000';
                }
                if (isObject(value)) {
                  // Keep it as is
                } else if (value.indexOf('$') === 0 && value.split(' ').length === 1) {
                  style[key] = style[value];
                } else if (value.indexOf('$') === 0) {
                  let newValue = style[value.match(/^(\$)[a-z-]+/gi)[0]];
                  if (newValue.indexOf('px')) {
                    let arr = value.split(' ');
                    let calc = eval(parseInt(newValue.replace('px', '')) + arr[1] + parseInt(arr[2]));
                    value = calc + 'px';
                    style[key] = value;
                  }
                } else {
                  style[key] = value;
                }
              });

              commit('setTheme', { ...style });

              commit(
                'setStyle',
                mapKeys({ ...style }, function(value, key) {
                  return camelCase(key.replace('$', ''));
                })
              );
              resolve(response);
            },
            (err) => {
              reject(err);
            }
          );
      });
    },
    setCompanyData({ commit }, payload) {
      commit('setCompanyData', payload);
    },
    setAvailableLanguages({ commit }, payload) {
      commit('setAvailableLanguages', payload);
    },
    toggleSidebar({ commit }) {
      commit('toggleSidebar');
    },
    showSidebar({ commit }) {
      commit('showSidebar');
    },
    closeSidebar({ commit }) {
      commit('closeSidebar');
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 200);
    },
    updateValue({ commit }, payload) {
      commit('updateValue', payload);
    },
    updateImage({ commit }, payload) {
      commit('updateImage', payload);
    },
    setStyle({ commit }, payload) {
      commit('setStyle', payload);
    },
    setTerminologyModels({ commit }, payload) {
      commit('setTerminologyModels', payload);
    },
    getFeaturesWithConsent({ commit, state }, user) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get(`/v2/companies/tenantdata`).then((response) => {
          if (response.data.askConsentForExternalContent) {
            const key = `featuresWithConsent-${user.id}`;
            const storedConsentFeatures = window.localStorage.getItem(key);
            if (!storedConsentFeatures) {
              commit('setFeaturesWithConsent', {
                content: {
                  vimeo: false,
                  youtube: false,
                  googleMaps: false
                },
                key
              });
            } else {
              commit('setFeaturesWithConsent', {
                content: JSON.parse(storedConsentFeatures),
                key
              });
            }
          }
        });
      });
    },
    setFeaturesWithConsent({ commit, state }, payload) {
      const key = `featuresWithConsent-${payload.user.id}`;
      commit('setFeaturesWithConsent', {
        content: payload.content,
        key
      });
    },
    getEnabledFeatures({ commit }, user) {
      return new Promise((resolve, reject) => {
        if (user.isAdmin) {
          if (!window.localStorage.admin_user) {
            reject('No Admin User');
          }
          const companyId = JSON.parse(window.localStorage.admin_user).companyId;
          axios
            .get(`${VUE_APP_ADMIN_API_URL}/v1/companies/${companyId}/features`, {
              headers: {
                Authorization: `Bearer ${window.localStorage.admin_token}`
              }
            })
            .then(
              (response) => {
                resolve(response);
                commit('setEnabledFeatures', response.data);
              },
              (error) => reject(error)
            );
        } else if (user.isOnboardee) {
          if (!window.localStorage.user) {
            reject('No Onboardee User');
          }

          axios
            .get(`${VUE_APP_API_URL}/profile/available-features`, {
              headers: {
                Authorization: `Bearer ${window.localStorage.token}`
              }
            })
            .then(
              (response) => {
                resolve(response);
                commit('setEnabledFeatures', response.data);
              },
              (error) => reject(error)
            );
        }
      });
    }
  }
};

export default settings;
