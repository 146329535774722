<template>
  <div class="add-question-marker">
    <div class="inner-border"></div>
    <div @click="selectSection">
      <el-dropdown @command="handleAction" trigger="click" class="dropdown-container" @visible-change="setVisible">
        <p>
          <i class="material-icons">add</i>
          {{ 'forms.addQuestionOrSection' | translate }}
        </p>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="question">{{ 'forms.question' | translate }}</el-dropdown-item>
          <el-dropdown-item command="section">{{ 'forms.section' | translate }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <create-question-modal></create-question-modal>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import { mapGetters } from 'vuex';
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import CreateQuestionModal from '@forms/modals/CreateQuestionModal.vue';
import fieldTypes from '@forms/const/fieldTypes.js';
import cloneDeep from 'lodash/cloneDeep';

export default {
  data() {
    return {
      items: {}
    };
  },
  props: {
    sectionId: {
      type: String
    }
  },
  components: {
    CreateQuestionModal,
    elDropdown: Dropdown,
    elDropdownItem: DropdownItem,
    elDropdownMenu: DropdownMenu
  },
  methods: {
    selectSection() {
      const sectionId = this.sectionId;
      const groupId = null;
      const questionId = null ? event.target.closest('.question-container').id : null;
      this.$store.commit('formBuilder/setSelectedItem', {
        sectionId,
        groupId,
        questionId
      });
    },
    setVisible(visible) {
      this.$el.classList[visible ? 'add' : 'remove']('always-show');
    },
    handleAction(command) {
      this.setVisible(true);
      switch (command) {
        case 'question':
          this.items = cloneDeep(this.selectedItem);
          this.$children.forEach((child) => {
            if (child.$refs.formModal) {
              child.$refs.formModal.open();
            }
          });
          break;
        case 'section':
          let order;
          const sectionId = this.$el
            .closest('[index]')
            .getAttribute('id')
            .split('/')
            .slice(-2)
            .join('/');
          const indexOfSection = Object.keys(this.schema.properties)
            .sort((a, b) => this.schema.properties[a].data.order - this.schema.properties[b].data.order)
            .indexOf(sectionId);
          if (indexOfSection === Object.keys(this.schema.properties).length - 1) {
            order =
              this.schema.properties[
                Object.keys(this.schema.properties).sort(
                  (a, b) => this.schema.properties[a].data.order - this.schema.properties[b].data.order
                )[indexOfSection]
              ].data.order + 1;
          } else {
            order =
              (this.schema.properties[
                Object.keys(this.schema.properties).sort(
                  (a, b) => this.schema.properties[a].data.order - this.schema.properties[b].data.order
                )[indexOfSection]
              ].data.order +
                this.schema.properties[
                  Object.keys(this.schema.properties).sort(
                    (a, b) => this.schema.properties[a].data.order - this.schema.properties[b].data.order
                  )[indexOfSection + 1]
                ].data.order) /
              2;
          }
          const key = `${fieldTypes.formSection}/${this.amountOfSections}`;

          const section = {
            'field-type': fieldTypes.formSection,
            type: 'object',
            $id: `#/properties/${key}`,
            index: `${key}`,
            title: this.$t('form.sectionTitle'),
            description: this.$t('form.sectionDescription'),
            data: {
              editMode: false,
              textAlignment: this.alignment,
              showTitle: true,
              showDescription: true,
              order: order
            },
            required: []
          };
          this.$store.commit('formBuilder/addFormSection', { section, key });
          break;
        default:
          break;
      }
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['alignment', 'amountOfSections', 'selectedItem', 'schema'])
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_helpers.scss';
@import '@shared/styles/_colors.scss';

div.add-question-marker {
  height: 24px;
  position: relative;
  text-align: center;
  margin-top: 20px;
  .inner-border {
    margin-top: 6px;
    border-top: none;
  }
  p {
    font-family: 'Talmundo';
    display: none;
    align-items: center;
    justify-content: center;
    color: $color-midgreyblue;
    cursor: pointer;
    left: calc(50% - 100px);
    position: absolute;
    width: 210px;
    top: -30px;
    padding: 4px;
    background-color: white;
    border-radius: 15px;
    border: dashed 1px $color-midgreyblue;
  }

  &.always-show {
    p {
      display: flex !important;
    }
    .inner-border {
      border-top: dashed 1px $color-midgreyblue;
    }
  }
}

div.add-question-marker:hover {
  .inner-border {
    border-top: dashed 1px $color-midgreyblue;
  }

  p {
    display: flex;
  }
}
</style>
