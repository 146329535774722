import Vue from 'vue';

function validateBelgianZip(value) {
  const pattern = /^\d{4}$/; // check for digits and length (should be 4 digits)
  if (typeof value === 'Array') {
    value = value[0];
  }
  return value && !!value.match(pattern);
}

export default {
  getMessage(field, params, data) {
    return (data && data.message) || Vue.i18n.translate('validation.belgianZip');
  },
  validate(value) {
    return new Promise((resolve) => {
      resolve({
        valid: value === 'trigger' ? false : validateBelgianZip(value),
        data: value !== 'trigger' ? undefined : { message: 'Not this value' }
      });
    });
  }
};
