export default {
  created() {
    window.addEventListener('message', (event) => {
      if (event?.data?.call === 'access-token-changed') {
        this.$store.dispatch('auth/setToken', {
          token: event?.data?.value?.AccessToken
        });
      }
    });
  }
};
