import Vue from 'vue';
import Vuex from 'vuex';

import VIEW_MODES from '@admin/const/viewModes.js';

Vue.use(Vuex);

const editor = {
  namespaced: true,
  state: {
    viewMode: VIEW_MODES.DESKTOP,
    inTranslateMode: false,
    autoPopupSettings: false,
    ignoreChangesPopup: false,
    forceRefresh: false,
    inViewMode: false
  },
  getters: {
    previewClass(state) {
      switch (state.viewMode) {
        case VIEW_MODES.DESKTOP:
          return 'desktop';
        case VIEW_MODES.TABLET:
          return 'tablet';
        case VIEW_MODES.PHONE:
          return 'phone';
      }
      return 'desktop';
    },
    isActiveView: (state) => (view) => {
      return state.viewMode === view;
    },
    inTranslateMode(state) {
      return state.inTranslateMode;
    },
    inViewMode(state) {
      return state.inViewMode;
    },
    autoPopupSettings(state) {
      return state.autoPopupSettings;
    },
    ignoreChangesPopup(state) {
      return state.ignoreChangesPopup;
    },
    forceRefresh(state) {
      return state.forceRefresh;
    }
  },
  mutations: {
    setViewMode(state, viewMode) {
      state.viewMode = viewMode;
    },
    setInTranslateMode(state, inTranslateMode) {
      state.inTranslateMode = inTranslateMode;
    },
    setInViewMode(state, inViewMode) {
      state.inViewMode = inViewMode;
    },
    autoPopupSettings(state, autoPopupSettings) {
      state.autoPopupSettings = autoPopupSettings;
    },
    ignoreChangesPopup(state, ignoreChangesPopup) {
      state.ignoreChangesPopup = ignoreChangesPopup;
    },
    forceRefresh(state, forceRefresh) {
      state.forceRefresh = forceRefresh;
    }
  }
};

export default editor;
