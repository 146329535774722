<template>
  <div class="form-country-field">
    <div class="block">
      <country-component
        :model.sync="value"
        :placeholder="placeholder"
        :disabled="editMode"
        :initialValue="initialValue"
        @change="updateCountry($event)"
      ></country-component>
      <div class="over-panel" v-if="editMode"></div>
    </div>
  </div>
</template>
<script>
import CountryComponent from '@forms/components/CountryComponent.vue';

export default {
  components: {
    CountryComponent
  },
  props: {
    editMode: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    model: {
      type: String
    },
    initialValue: {
      type: String
    }
  },
  data() {
    return {
      value: ''
    };
  },
  created() {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  },
  mounted() {
    if (this.$el.parentElement.querySelector(`input[id="${this.model}"]`)) {
      const self = this;
      this.$el.parentElement.querySelector(`input[id="${this.model}"]`).addEventListener(
        'change',
        (newInput) => {
          self.value = newInput.target.value;
        },
        false
      );
    }
  },
  methods: {
    updateCountry(country) {
      this.value = country;
      if (!this.$el) {
        return;
      }
      this.$el.parentElement.querySelector(`[id="${this.model}"]`).value = this.value;
      this.$el.parentElement.querySelector(`[id="${this.model}"]`).dispatchEvent(new Event('input'));
    }
  }
};
</script>

<style lang="scss" scoped>
.form-country-field {
  font-family: 'Talmundo';

  .over-panel {
    position: relative;
    top: -40px;
    width: 100%;
    height: 40px;
    margin-bottom: -40px;
    background-color: transparent;
  }
}
</style>
