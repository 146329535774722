import Vue from 'vue';
import camelCase from 'lodash/camelCase';

// register all filters
const requireFilter = require.context('./', false, /^(?!.*index\.js).*\.js$/);

requireFilter.keys().forEach((fileName) => {
  const filterConfig = requireFilter(fileName);
  const filterName = camelCase(fileName.replace(/^].\//, '').replace(/\.\w+$/, ''));
  Vue.filter(filterName, filterConfig.default || filterConfig);
  if (filterName === 'translate') {
    Vue.prototype.$t = filterConfig.default || filterConfig;
  }
});
