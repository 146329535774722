import Vue from 'vue';
import jwtDecode from 'jwt-decode';

const http = require('../http').default;

const refresh_token = window.localStorage.refresh_token;
let token = window.localStorage.admin_token;

const auth = {
  namespaced: true,
  state: {
    refresh_token,
    token,
    twoFactorSetup: {
      token: null,
      email: null,
      password: null,
      authenticatorToken: null,
      forAdmin: null
    },
    permissions: []
  },
  getters: {
    authenticated(state) {
      if (!state.token) {
        if (
          window.parent &&
          window.parent.document &&
          window.parent.document.querySelector('iframe[id="iframeView"]')
        ) {
          token = window.parent.document.querySelector('iframe[id="iframeView"]').getAttribute('token');
          state.token = token;
          window.localStorage.setItem('admin_token', token);
          return true;
        }
      }
      return !!state.token && new Date().getTime() < new Date(jwtDecode(state.token).exp * 1000).getTime(); //moment().isBefore(moment.unix(jwtDecode(state.token).exp));
    },
    companyId(state, getters) {
      return getters.user.companyId;
    },
    isTalmundoUser(state) {
      let decoded = null;
      if (state.token) decoded = jwtDecode(state.token);
      return state.token && decoded && decoded.role === 'admin' && decoded.email === 'keeper';
    },
    templateId(state, getters) {
      return getters.user.templateId;
    },
    user(state) {
      return window.localStorage.admin_user ? JSON.parse(window.localStorage.admin_user) : null;
    },
    permissions(state) {
      return state.permissions;
    },
    canCreateUpdateLearningPages(state, getters) {
      const learningPagePermissions = getters.permissions.find(
        (persmission) => persmission.permissionType === 'LearningPages'
      );
      if (learningPagePermissions) {
        return (
          learningPagePermissions.permissionState == 'Delete' ||
          learningPagePermissions.permissionState == 'CreateUpdate'
        );
      }
      return false;
    },
    canDeleteLearningPages(state, getters) {
      const learningPagePermissions = getters.permissions.find(
        (persmission) => persmission.permissionType === 'LearningPages'
      );
      if (learningPagePermissions) {
        return learningPagePermissions.permissionState == 'Delete';
      }
      return false;
    },
    canCreateUpdateForms(state, getters) {
      const formsPermissions = getters.permissions.find(
        (persmission) => persmission.permissionType === 'AdministrativeFormModule'
      );
      if (formsPermissions) {
        return formsPermissions.permissionState == 'Delete' || formsPermissions.permissionState == 'CreateUpdate';
      }
      return false;
    },
    canDeleteForms(state, getters) {
      const formsPermissions = getters.permissions.find(
        (persmission) => persmission.permissionType === 'AdministrativeFormModule'
      );
      if (formsPermissions) {
        return formsPermissions.permissionState == 'Delete';
      }
      return false;
    }
  },
  mutations: {
    logout(state) {
      state.token = null;
      state.refresh_token = null;
      state.twoFactorSetup = null;
      http.defaults.headers['Authorization'] = null;
      const remove = [
        'token',
        'user',
        'admin_token',
        'refresh_token',
        'admin_user',
        'chatbotName',
        'chatbotOptions',
        'chatbotLocalization',
        'demo',
        'demoCompanyData',
        'demoStyles',
        'bot.widget-converstaion', // typo on their side
        'bot.widget-last_activity',
        'bot.widget-is_user_interacted'
      ];

      remove.forEach((item) => {
        window.localStorage.removeItem(item);
      });
      window.localStorage.removeItem('defaultLanguage');
    },
    setRefreshToken(state, payload) {
      state.refresh_token = payload.refresh_token;
      window.localStorage.refresh_token = payload.refresh_token;
    },
    setToken(state, payload) {
      state.token = payload.token;
      http.defaults.headers['Authorization'] = `Bearer ${payload.token}`;
      window.localStorage.admin_token = payload.token;
    },
    setUser(state, payload) {
      payload.templateId =
        payload.companies[0] && payload.companies[0].templates[0] ? payload.companies[0].templates[0].id : null;
      let user = JSON.stringify(payload);
      window.localStorage.admin_user = user;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setTwoFactorSetup(state, payload) {
      state.twoFactorSetup = payload;
    }
  },
  actions: {
    logout({ commit }) {
      commit('logout');
    },
    setRefreshToken({ commit }, payload) {
      commit('setRefreshToken', payload);
    },
    setTwoFactorSetup({ commit }, payload) {
      commit('setTwoFactorSetup', payload);
    },
    setToken({ commit }, payload) {
      commit('setToken', payload);
    },
    setUser({ commit }, payload) {
      commit('setUser', payload);
    },
    getUserPermissions({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get(`/v2/users/permissions`).then(
          (result) => {
            resolve(result);
            commit('setPermissions', result.data.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    }
  }
};

export default auth;
