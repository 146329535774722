import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const loading = {
  namespaced: true,
  state: {
    loading: false
  },
  getters: {
    loading(state) {
      return state.loading;
    }
  },
  mutations: {
    loading(state, loading) {
      state.loading = loading;
    }
  },
  actions: {
    isLoading({ commit }) {
      commit('loading', true);
    },
    isNotLoading({ commit }) {
      commit('loading', false);
    }
  }
};

export default loading;
