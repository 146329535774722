<template>
  <div>
    <el-dropdown @command="handleAction" trigger="click" class="dropdown-container" :class="elements">
      <i class="material-icons more_vert" @click="setClick">more_vert</i>
      <el-dropdown-menu slot="dropdown">
        <!-- <el-dropdown-item command="duplicate">{{`forms.duplicate${elements}` | translate}}</el-dropdown-item> -->
        <el-dropdown-item v-if="isQuestionInSection" command="move">{{
          `forms.move${elements}` | translate
        }}</el-dropdown-item>

        <el-dropdown-item command="rearrange">{{ `forms.rearrange${elements}` | translate }}</el-dropdown-item>
        <el-dropdown-item command="delete">{{ `forms.delete${elements}` | translate }}</el-dropdown-item>
        <el-dropdown-item v-if="elements !== 'Choices'" command="duplicate">{{
          `forms.duplicate${elements}` | translate
        }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <RearrangeModal :title="title" :selectedItem="item"></RearrangeModal>
    <MoveModal :selectedItem="item"></MoveModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import FormModal from '@forms/modals/FormModal.vue';
import RearrangeModal from '@forms/modals/RearrangeModal.vue';
import MoveModal from '@forms/modals/MoveModal.vue';
import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { uuid } from 'vue-uuid';

export default {
  components: {
    FormModal,
    RearrangeModal,
    MoveModal,
    elDropdown: Dropdown,
    elDropdownItem: DropdownItem,
    elDropdownMenu: DropdownMenu
  },
  props: {
    title: {
      type: String,
      required: true
    },
    elements: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      item: {}
    };
  },
  computed: {
    ...mapGetters('formBuilder', ['createQuestionIdInSection', 'schema', 'selectedItem']),
    isQuestionInSection() {
      // Only allow questions/groups to be moved. not questions in group
      if (this.selectedItem && this.selectedItem.groupId && this.selectedItem.questionId) {
        return false;
      }
      return this.elements === 'Questions';
    }
  },
  methods: {
    setClick(event) {
      const sectionId = event.target
        .closest('.section-container')
        .id.split('/')
        .slice(-2)
        .join('/');
      const groupId = event.target.closest('.group-container') ? event.target.closest('.group-container').id : null;
      const questionId = event.target.closest('.question-container')
        ? event.target.closest('.question-container').id
        : null;
      this.$store.commit('formBuilder/setSelectedItem', {
        sectionId,
        groupId,
        questionId
      });
    },
    handleAction(command, event) {
      switch (command) {
        case 'duplicate':
          const payload = {
            currentSection: this.selectedItem.sectionId,
            questionId: this.selectedItem.questionId || this.selectedItem.groupId,
            formGroup: this.selectedItem.groupId,
            id: uuid.v4()
          };
          this.duplicate(payload);
          this.$store.commit(`formBuilder/duplicate${this.elements}`, payload);
          break;
        case 'rearrange':
          this.rearange();
          break;
        case 'delete':
          if (this.elements === 'Choices') {
            this.$store.commit(`formBuilder/deleteChoices`, this.$el.parentElement.querySelector('label').innerHTML);
            this.$store.commit(`formBuilder/redraw`);
          } else {
            this.$store.commit(`formBuilder/delete${this.elements}`);
          }
          break;
        case 'move':
          this.$store.commit('formBuilder/setAllowSelect', false);
          this.item = cloneDeep(this.selectedItem);
          this.$children.forEach((child) => {
            if (child.$refs.moveModal) {
              child.$refs.moveModal.open();
            }
          });
          break;
        default:
          break;
      }
    },
    rearange() {
      this.item = cloneDeep(this.selectedItem);
      if (this.elements === 'Choices') {
        this.item.choices = true;
      }
      this.$children.forEach((child) => {
        if (child.$refs.rearangeModal) {
          child.$refs.rearangeModal.open();
        }
      });
    },
    duplicate(payload) {
      const index = this.$el.closest('[index]').getAttribute('index');
      const question =
        this.selectedItem.groupId && this.selectedItem.questionId
          ? Math.max(
              -1,
              ...[...this.$el.closest('.group').querySelector('.group-content-wrapper').children].map((e) =>
                e.getAttribute('id')
                  ? Number(
                      e
                        .getAttribute('id')
                        .split('/')
                        .pop()
                    )
                    ? Number(
                        e
                          .getAttribute('id')
                          .split('/')
                          .pop()
                      )
                    : 0
                  : 0
              )
            ) + 1
          : this.createQuestionIdInSection;

      const section = this.schema.properties
        ? Math.max(
            -1,
            ...Object.keys(this.schema.properties).map((e) => {
              let num = this.schema.properties[e].index.split('/').pop();
              return num;
            })
          ) + 1
        : 0;

      const selectedSection =
        this.selectedItem.sectionId && !this.selectedItem.questionId && !this.selectedItem.groupId;
      const selectedGroupQuestion = this.selectedItem.groupId && this.selectedItem.questionId;
      const selectedGroup = this.selectedItem.groupId && !this.selectedItem.questionId;

      const key = `${
        selectedSection
          ? 'form-section'
          : payload.formGroup && !this.selectedItem.questionId
          ? 'form-group'
          : payload.formGroup && this.selectedItem.questionId
          ? payload.formGroup.concat('.') + payload.formGroup + '/' + 'form-question'
          : 'form-question'
      }/${selectedSection ? section : question}`;

      let sortedElements = selectedSection
        ? Object.keys(this.schema.properties)
            .sort((a, b) => {
              return this.schema.properties[a].data.order - this.schema.properties[b].data.order;
            })
            .map((e) => {
              return {
                name: this.schema.properties[e].title,
                order: this.schema.properties[e].data.order,
                id: e
              };
            })
        : selectedGroupQuestion
        ? Object.keys(
            this.schema.properties[this.selectedItem.sectionId].properties[this.selectedItem.groupId].properties
          )
            .sort((a, b) => {
              return (
                this.schema.properties[this.selectedItem.sectionId].properties[this.selectedItem.groupId].properties[a]
                  .data.order -
                this.schema.properties[this.selectedItem.sectionId].properties[this.selectedItem.groupId].properties[b]
                  .data.order
              );
            })
            .map((e) => {
              return {
                name: this.schema.properties[this.selectedItem.sectionId].properties[this.selectedItem.groupId]
                  .properties[e].title,
                order: this.schema.properties[this.selectedItem.sectionId].properties[this.selectedItem.groupId]
                  .properties[e].data.order,
                id: e
              };
            })
        : Object.keys(this.schema.properties[this.selectedItem.sectionId].properties)
            .sort((a, b) => {
              return (
                this.schema.properties[this.selectedItem.sectionId].properties[a].data.order -
                this.schema.properties[this.selectedItem.sectionId].properties[b].data.order
              );
            })
            .map((e) => {
              return {
                name: this.schema.properties[this.selectedItem.sectionId].properties[e].title,
                order: this.schema.properties[this.selectedItem.sectionId].properties[e].data.order,
                id: e
              };
            });

      const indexOfSelectedElement = sortedElements.indexOf(
        sortedElements.find((e) =>
          selectedSection
            ? e.id === this.selectedItem.sectionId
            : selectedGroup
            ? e.id === this.selectedItem.groupId
            : e.id === this.selectedItem.questionId
        )
      );

      if (indexOfSelectedElement === sortedElements.length - 1) {
        payload.order = sortedElements[indexOfSelectedElement].order + 1;
      } else {
        payload.order =
          (sortedElements[indexOfSelectedElement].order + sortedElements[indexOfSelectedElement + 1].order) / 2;
      }
      payload.key = key;
      payload.question = question;
      payload.index =
        this.selectedItem.sectionId && !this.selectedItem.questionId && !this.selectedItem.groupId ? section : question;
    },
    showEditFormModal() {
      this.$children.forEach((child) => {
        if (child.$refs.formModal) {
          child.$refs.formModal.open();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-dropdown {
  padding-top: 20px;
  width: 20px;
  height: 30px;
  i {
    display: none;
    cursor: pointer;
    position: relative;
    right: 5px;
  }
  &:hover {
    i {
      display: block;
    }
  }
  &.Questions {
    padding-top: 0;
  }
  &.Choices {
    padding-top: 0;
    position: relative;
    top: 0;
    left: -26px;
  }
}
</style>
