import Vue from 'vue';
import Vuex from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import axios from 'axios';

Vue.use(Vuex);

const { VUE_APP_API_URL, VUE_APP_ADMIN_API_URL } = process.env;

const learningPages = {
  namespaced: true,
  state: {
    pageData: {},
    learningPages: [],
    learningPagesTreeview: [],
    initialLearningPages: [],
    selectedContentBlockElement: '',
    selectedContentBlock: { id: -1 },
    currentLearningPageId: null,
    companyImages: [],
    companyFiles: [],
    editorChanges: false,
    localeCode: 'en-US',
    defaultLocaleCode: 'en-US',
    languages: [],
    moduleIdForLearningPage: null,
    pageSettingTranslation: [],
    adjustedPageSettingTranslations: [],
    totalItems: 12,
    initialTranslations: []
  },
  getters: {
    editorChanges(state) {
      return state.editorChanges;
    },
    pageData(state) {
      return state.pageData;
    },
    learningPages(state) {
      return state.learningPages;
    },
    learningPagesTreeview(state) {
      return state.learningPagesTreeview;
    },
    firstLearningPage(state) {
      return state.learningPages[0];
    },
    companyImages(state) {
      return state.companyImages;
    },
    companyFiles(state) {
      return state.companyFiles;
    },
    getLearningPageById: (state) => (id) => {
      return find(state.learningPages, (page) => page.id === Number(id));
    },
    getInitialTranslationsById: (state) => (id) => {
      return find(state.initialTranslations, (page) => page.id === Number(id));
    },
    getInitialLearningPageById: (state) => (id) => {
      return find(state.initialLearningPages, (page) => page.id === Number(id));
    },
    getLearningPageTranslations: (state, getters) => (id) => {
      const translations = getters.getLearningPageById(id).translations;
      const translationInLocale = translations.find((translation) => translation.localeCode === state.localeCode);

      if (translationInLocale) {
        return translationInLocale;
      } else if (translations.length === 1) {
        return translations[0];
      } else {
        return translations.find((translation) => translation.localeCode === state.defaultLocaleCode);
      }
    },
    selectedContentBlockElement(state) {
      return state.selectedContentBlockElement;
    },
    selectedContentBlock(state) {
      return state.selectedContentBlock;
    },
    mainImage(state) {
      return state.pageData.images && state.pageData.images.find((image) => image.isMain === true);
    },
    languages(state) {
      return state.languages;
    },
    defaultLocaleCode(state) {
      let found = state.languages.find((language) => language.default);
      if (!found && state.defaultLocaleCode) {
        found = { localeCode: state.defaultLocaleCode };
      }
      return found && found.localeCode;
    },
    localeCode(state) {
      return state.localeCode;
    },
    moduleIdForLearningPage(state) {
      if (window.localStorage.moduleIdForLearningPage) {
        return window.localStorage.moduleIdForLearningPage;
      }

      return state.moduleIdForLearningPage;
    },
    hasChanges: (state, getters) => (learningPageId) => {
      const currentLearningPage = getters.getLearningPageById(learningPageId);
      const initialLearningPage = getters.getInitialLearningPageById(learningPageId);
      if (!currentLearningPage || !initialLearningPage) {
        return false;
      }
      const contentChanges = !isEqual(currentLearningPage.content, initialLearningPage.content);
      const translationChanges = !isEqual(currentLearningPage.translations, initialLearningPage.translations);
      return contentChanges || translationChanges;
    },
    pageSettingTranslation(state, getters) {
      const translation = state.pageSettingTranslation.find(
        (translation) => translation.languageCode === getters.localeCode
      );
      if (translation) {
        return translation;
      }
      return getters.pageData;
    },
    adjustedPageSettingTranslations(state) {
      return state.adjustedPageSettingTranslations;
    },
    totalItems(state) {
      return state.totalItems;
    }
  },
  mutations: {
    addImage(state, image) {
      let learningPage = state.learningPages.filter(
        (learningPage) => learningPage.id === Number(state.currentLearningPageId)
      )[0];

      let imageExists = learningPage.images.find((img) => img.key === image.key);
      if (!imageExists) {
        learningPage.images.push(image);
      }
    },
    setItemStatusToggle(state, payload) {
      payload.content.items.filter((selectedItem) => {
        if (selectedItem.id === payload.item.id) {
          selectedItem.isOpen = !selectedItem.isOpen;
        }
      });
    },
    setItemInitialStatus(state, payload) {
      payload.content.items.map((item, index) => {
        return (item.isOpen = index === 0 ? true : false);
      });
    },
    setModuleIdForLearningPage(state, moduleId) {
      state.moduleIdForLearningPage = moduleId;

      if (moduleId === null) {
        delete window.localStorage.moduleIdForLearningPage;
      } else {
        window.localStorage.moduleIdForLearningPage = moduleId;
      }
    },
    setLocaleCode(state, localeCode) {
      state.localeCode = localeCode;
    },
    setDefaultLocaleCode(state, localeCode) {
      state.defaultLocaleCode = localeCode;
    },
    copyTranslation(state) {
      if (state.localeCode === state.defaultLocaleCode) {
        return;
      }

      let learningPage = state.learningPages.filter(
        (learningPage) => learningPage.id === Number(state.currentLearningPageId)
      )[0];

      let localeToCopy = learningPage.translations.find((locale) => {
        return locale.localeCode === state.defaultLocaleCode;
      });

      // Default language does not exist. Provide this fallback
      if (!localeToCopy) {
        let defaultTranslations = cloneDeep(learningPage.translations[0]);
        defaultTranslations.localeCode = state.defaultLocaleCode;
        learningPage.translations.push(defaultTranslations);
        localeToCopy = defaultTranslations;
      }

      let localeToAdjust = learningPage.translations.find((locale) => {
        return locale.localeCode === state.localeCode;
      });

      if (!localeToAdjust) {
        learningPage.translations.push({
          learningPageId: state.currentLearningPageId,
          localeCode: state.localeCode,
          translations: cloneDeep(localeToCopy.translations)
        });
      } else if (localeToCopy) {
        // Check if new baselanguage strings
        let missingValues = cloneDeep(
          localeToCopy.translations.filter((translationCopy) => {
            let match = localeToAdjust.translations.find((translationAdjust) => {
              return translationCopy.key === translationAdjust.key;
            });

            return !match ? true : false;
          })
        );

        if (missingValues && missingValues.length > 0) {
          // Get existing values of other language than base
          missingValues = missingValues.map((missingValue) => {
            let match = localeToAdjust.translations.find((translationAdjust) => {
              return missingValue.key === translationAdjust.key;
            });
            if (match && match.value) {
              missingValue.value = match.value;
            }

            return missingValue;
          });
          localeToAdjust.translations = [...localeToAdjust.translations, ...missingValues];
        }
      }
    },
    addTranslationPage(state, translationToAdd) {
      let learningPage = state.learningPages.filter(
        (learningPage) => learningPage.id === Number(state.currentLearningPageId)
      )[0];

      if (learningPage.translations.length === 0) {
        learningPage.translations.push({ localeCode: state.defaultLocaleCode, translations: [] });
      }

      let localeToAdjust = learningPage.translations.find((locale) => {
        return locale.localeCode === state.defaultLocaleCode;
      });

      localeToAdjust.translations.push(translationToAdd);

      learningPage.translations = learningPage.translations.map((locale) => {
        if (locale.localeCode === state.defaultLocaleCode) {
          locale.translations = localeToAdjust.translations;
        }
        return locale;
      });
    },
    editTranslationPage(state, translationToEdit) {
      let learningPage = state.learningPages.filter(
        (learningPage) => learningPage.id === Number(state.currentLearningPageId)
      )[0];

      if (learningPage.translations.length === 0) {
        learningPage.translations.push({ localeCode: state.localeCode, translations: [] });
      }

      let localeToAdjust = learningPage.translations.find((locale) => {
        return locale.localeCode === state.localeCode;
      });

      if (!localeToAdjust && learningPage.translations.length === 1) {
        localeToAdjust = learningPage.translations[0];
      }

      let translationToAdjust = localeToAdjust.translations.find(
        (translation) => translation.key === translationToEdit.key
      );

      delete translationToEdit.element;
      delete translationToEdit.level;

      if (!translationToAdjust) {
        localeToAdjust.translations.push(translationToEdit);
      } else {
        localeToAdjust.translations = localeToAdjust.translations.map((translation) => {
          if (translation.key === translationToEdit.key) {
            translation.value = translationToEdit.value;
          }
          return translation;
        });
      }

      learningPage.translations = learningPage.translations.map((locale) => {
        if (locale.localeCode === state.localeCode) {
          locale.translations = cloneDeep(localeToAdjust.translations);
        }
        return locale;
      });
    },
    addLearningPage(state, learningPage) {
      state.learningPages.push(learningPage);
    },
    updateLearningPage(state, learningPageToUpdate) {
      const exists = find(state.learningPages, (learningPage) => learningPage.id === learningPageToUpdate.id);
      if (exists) {
        state.learningPages = state.learningPages.map((learningPage) => {
          if (learningPage.id === learningPageToUpdate.id) {
            learningPage = learningPageToUpdate;
          }
          return learningPage;
        });
      } else {
        state.learningPages.push(learningPageToUpdate);
      }
    },
    deleteLearningPageById(state, id) {
      state.learningPages = state.learningPages.filter((learningPage) => {
        return learningPage.id !== id;
      });
    },
    deleteLearningPageByIds(state, ids) {
      ids.map((id) => {
        state.learningPages = state.learningPages.filter((learningPage) => {
          return learningPage.id !== id;
        });
      });
    },
    clearCurrentLearningPage(state) {
      state.learningPages = state.learningPages.map((learningPage) => {
        if (learningPage.id === Number(state.currentLearningPageId)) {
          state.selectedContentBlock = { id: -1 };
          let initialLearningPage = state.initialLearningPages.find(
            (learningPage) => learningPage.id === state.currentLearningPageId
          );

          learningPage.content = cloneDeep(initialLearningPage.content);
          learningPage.translations = cloneDeep(initialLearningPage.translations);
        }
        return learningPage;
      });
    },
    setCurrentLearningPageId(state, id) {
      state.editorChanges = false;
      state.currentLearningPageId = Number(id);
    },
    addContentBlockToLearningPage(state, payload) {
      state.editorChanges = true;
      let learningPage = state.learningPages.filter(
        (learningPage) => learningPage.id === Number(state.currentLearningPageId)
      )[0];

      if (learningPage) {
        state.selectedContentBlock = payload.contentBlock;
        state.selectedContentBlockElement = 'content-block';
        learningPage.content.splice(payload.position, 0, payload.contentBlock);
        learningPage.content = learningPage.content.map((content, index) => {
          content.id = index;
          return content;
        });
      }
    },
    editContentBlock(state, contentToEdit) {
      state.editorChanges = true;
      let learningPage = state.learningPages.filter(
        (learningPage) => learningPage.id === Number(state.currentLearningPageId)
      )[0];

      if (learningPage) {
        learningPage.content = learningPage.content.map((content) => {
          if (content.id === contentToEdit.id) {
            state.selectedContentBlock = contentToEdit;
            return contentToEdit;
          }
          return content;
        });
      }
    },
    removeContentBlock(state, contentBlockId) {
      state.learningPages = state.learningPages.map((learningPage) => {
        if (learningPage.id === Number(state.currentLearningPageId)) {
          learningPage.content = learningPage.content.filter((contentBlock) => {
            return contentBlock.id !== contentBlockId;
          });
          state.selectedContentBlock = learningPage.content[0] || { id: -1 };
        }
        return learningPage;
      });
    },
    removeItemContentBlock(state) {
      state.selectedContentBlock.items = state.selectedContentBlock.items.filter((item, index) => {
        if (index !== state.selectedContentBlockElement.id) {
          return item;
        }
      });
    },
    setLearningPages(state, learningPages) {
      if (!learningPages) {
        return;
      }
      state.learningPages = learningPages;
    },
    setLearningPagesTreeview(state, learningPages) {
      if (!learningPages) {
        return;
      }
      state.learningPagesTreeview = learningPages;
    },
    setInitialLearningPages(state, learningPages) {
      if (!learningPages) {
        return;
      }
      state.initialLearningPages = learningPages;
    },
    setPageData(state, payload) {
      if (!payload.useFixedDates) {
        payload.startFromStartDate = Number(payload.startFromStartDate);
        payload.endFromStartDate = Number(payload.endFromStartDate);

        let transformed = false;

        if (payload.startWorkday === 'before') {
          payload.startFromStartDate = -payload.startFromStartDate;
          transformed = true;
        }

        if (payload.endWorkday === 'before') {
          payload.endFromStartDate = -payload.endFromStartDate;
          transformed = true;
        }

        if (payload.startFromStartDate <= 0 && !payload.startWorkday) {
          payload.startWorkday = 'before';
          if (!transformed) {
            payload.startFromStartDate = -payload.startFromStartDate;
          }
        } else {
          payload.startWorkday = 'after';
        }
        if (payload.endFromStartDate <= 0 && !payload.endWorkday) {
          payload.endWorkday = 'before';
          if (!transformed) {
            payload.endFromStartDate = -payload.endFromStartDate;
          }
        } else {
          payload.endWorkday = 'after';
        }
      } else {
        if (payload.endWorkday === undefined) {
          if (payload.endFromStartDate > 0) {
            payload.endWorkday = 'after'; // default value when switching
          } else {
            payload.endWorkday = 'before';
            if (payload.endFromStartDate) {
              payload.endFromStartDate = -payload.endFromStartDate;
            }
          }
        } else if (payload.endWorkday === 'before' && payload.endFromStartDate) {
          payload.endFromStartDate = -payload.endFromStartDate;
        }

        if (payload.startWorkday === undefined) {
          if (payload.startFromStartDate > 0) {
            payload.startWorkday = 'after'; // default value when switching
          } else {
            payload.startWorkday = 'before';
            if (payload.startFromStartDate) {
              payload.startFromStartDate = -payload.startFromStartDate;
            }
          }
        } else if (payload.startWorkday === 'before' && payload.startFromStartDate) {
          payload.startFromStartDate = -payload.startFromStartDate;
        }

        if (payload.startFromStartDate === undefined) {
          payload.immediate = true; // When immediate was checked before
        } else {
          payload.immediate = false; // When immediate was not checked before
        }
      }

      if (
        (isNaN(payload.startFromStartDate) && isNaN(payload.endFromStartDate)) ||
        payload.useFixedDates === 'always'
      ) {
        payload.useFixedDates = 'always';
        payload.startFromStartDate = 0;
        payload.endFromStartDate = 0;
      } else if (isNaN(payload.startFromStartDate) && !payload.useFixedDates) {
        payload.immediate = true;
        payload.useFixedDates = false;
      }

      state.pageData = payload;
    },
    setPageDataImage(state, imageToUpdate) {
      const hasImage = state.pageData.images.find((image) => image.key === imageToUpdate.key);
      if (!hasImage) {
        state.pageData.images.push({ key: imageToUpdate.key, isMain: imageToUpdate.isMain });
      } else {
        state.pageData.images = state.pageData.images.map((image) => {
          if (image.key === imageToUpdate.key) {
            image = imageToUpdate;
          }
          return image;
        });
      }
      if (imageToUpdate.isMain) {
        state.pageData.images = state.pageData.images.map((image) => {
          if (image.key !== imageToUpdate.key) {
            image.isMain = false;
          }
          return image;
        });
      }
    },
    setSelectedContentBlockElement(state, selectedElement) {
      state.selectedContentBlockElement = selectedElement;
    },
    setSelectedContentBlock(state, selectedContentBlock) {
      state.selectedContentBlock = selectedContentBlock;
    },
    setCompanyImages(state, { images, hasTags }) {
      const newImageIds = state.companyImages.map((image) => image.key);
      if (hasTags) {
        state.companyImages = images;
      } else {
        if (images) {
          const newImages = images.filter((image) => {
            const isDuplicate = newImageIds.includes(image.key);

            if (!isDuplicate) {
              newImageIds.push(image.key);
              return true;
            }
          });
          state.companyImages = [...state.companyImages, ...newImages];
        }
      }
    },
    setCompanyFiles(state, companyFiles) {
      state.companyFiles = companyFiles;
    },
    setLanguages(state, languages) {
      state.languages = languages;
    },
    setPageSettingTranslation(state, pageSettingTranslation) {
      state.pageSettingTranslation = pageSettingTranslation;
    },
    setPageSettingTranslationForLocale(state, payload) {
      let translation = state.pageSettingTranslation.find(
        (translation) => translation.languageCode === state.localeCode
      );
      if (translation) {
        translation[payload.key] = payload.value;
      } else {
        const newTranslation = {
          languageCode: state.localeCode,
          title: payload.key === 'title' ? payload.value : state.pageData.title,
          description: payload.key === 'description' ? payload.value : state.pageData.description
        };
        state.pageSettingTranslation.push(newTranslation);
      }
    },
    setAdjustedPageSettingTranslations(state, overwriteValue) {
      if (overwriteValue) {
        state.adjustedPageSettingTranslations = overwriteValue;
        return;
      }
      if (state.adjustedPageSettingTranslations.indexOf(state.localeCode) < 0) {
        state.adjustedPageSettingTranslations.push(state.localeCode);
      }
    },
    setFormIdsToLearningPage(state, payload) {
      state.learningPages = state.learningPages.map((learningPage) => {
        if (learningPage.id === state.currentLearningPageId) {
          if (learningPage.formsIds.indexOf(payload.formId) < 0) {
            learningPage.formsIds.push(Number(payload.formId));
          }
        }
        return learningPage;
      });
    },
    removeFormIdFromLearningPage(state, formToRemove) {
      state.learningPages = state.learningPages.map((learningPage) => {
        if (learningPage.id === state.currentLearningPageId) {
          learningPage.formsIds = learningPage.formsIds.filter((formId) => {
            return formId !== formToRemove;
          });
        }
        return learningPage;
      });
    },
    totalItems(state, totalItems) {
      state.totalItems = totalItems;
    },
    setInitialTranslations(state, translations) {
      state.initialTranslations = translations;
    }
  },
  actions: {
    getPageData({ commit }, onboardingPeriod) {
      return new Promise((resolve) => {
        const pageData = {
          id: -1,
          title: '',
          startFromStartDate: 0,
          endFromStartDate: onboardingPeriod || 0,
          fixedStartDate: new Date(),
          fixedEndDate: new Date(),
          useFixedDates: 'always',
          subpagesAppearance: 0,
          content: [],
          label: '',
          tags: [],
          images: [],
          translations: [],
          description: '',
          immediate: false,
          editableByOthers: false,
          canChangeEditableByOthers: true,
          editable: true
        };

        commit('setPageData', pageData);
        resolve(pageData);
      });
    },
    deleteLearningPage({ commit }, id) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.delete(`/v1/companies/${companyId}/templates/${templateId}/learningpages/items/${id}`).then(
          (result) => {
            if (!result) {
              reject();
            } else {
              commit('deleteLearningPageById', id);
              resolve(result);
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getLearningPageDetails({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        let url = `/v1/companies/${companyId}/templates/${templateId}/learningpages/items/${payload.learningPageId}`;
        url += '?draft=true';

        if (payload.moduleId) {
          url += `&learningPageModuleId=${payload.moduleId}`;
        }
        Vue.prototype.$http.get(url).then(
          (result) => {
            result.data.content = result.data.content.map((p) => {
              if (p.type === 'Tabs') {
                p.items = p.items.map((item) => {
                  if (!item.imagePos) {
                    item.imagePos = 'left'; // default
                  }
                  return item;
                });
              }
              return p;
            });
            Object.keys(result.data).forEach((key) => {
              if (result.data[key] === null) {
                delete result.data[key];
              }
            });

            let defaultLanguageTranslations = result.data.translations.find(
              (translation) => translation.localeCode === state.defaultLocaleCode
            );
            if (!defaultLanguageTranslations && result.data.translations.length > 0) {
              defaultLanguageTranslations = cloneDeep(result.data.translations[0]);
              defaultLanguageTranslations.localeCode = state.defaultLocaleCode;

              result.data.translations.push(defaultLanguageTranslations);
            }

            commit('setInitialTranslations', cloneDeep([result.data]));
            commit('setInitialLearningPages', cloneDeep([result.data]));
            commit('updateLearningPage', cloneDeep(result.data));
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getLearningPages({ commit, dispatch }, moduleId) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        let url = `/v1/companies/${companyId}/templates/${templateId}/learningpages/items/all`;
        url += '?skipContent=true';

        if (moduleId) {
          url += `&learningPageModuleId=${moduleId}`;
        }
        Vue.prototype.$http.get(url).then(
          (result) => {
            // Remove duplicate LPS
            let uniqueIds = [];
            result.data = result.data
              .map((lp) => {
                if (!uniqueIds.includes(lp.id)) {
                  uniqueIds.push(lp.id);
                  return lp;
                }
              })
              .filter((lp) => lp !== undefined);

            result.data = result.data.map((page) => {
              page.content = page.content.map((p) => {
                if (p.type === 'Tabs') {
                  p.items = p.items.map((item) => {
                    if (!item.imagePos) {
                      item.imagePos = 'left'; // default
                    }
                    return item;
                  });
                }
                return p;
              });
              return page;
            });

            commit('setInitialLearningPages', cloneDeep(result.data));
            commit('setLearningPages', cloneDeep(result.data));
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getLearningPagesTreeview({ commit, dispatch }, moduleId) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        let url = `/v1/companies/${companyId}/templates/${templateId}/learningpages/items`;
        url += '?skipContent=true';
        if (moduleId) {
          url += `&learningPageModuleId=${moduleId}`;
        }
        Vue.prototype.$http.get(url).then(
          (result) => {
            result.data = result.data.map((page) => {
              page.content = page.content.map((p) => {
                if (p.type === 'Tabs') {
                  p.items = p.items.map((item) => {
                    if (!item.imagePos) {
                      item.imagePos = 'left'; // default
                    }
                    return item;
                  });
                }
                return p;
              });
              return page;
            });

            commit('setInitialLearningPages', cloneDeep(result.data));
            commit('setLearningPages', cloneDeep(result.data));
            commit('setLearningPagesTreeview', cloneDeep(result.data));
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    setLearningPages({ commit }, payload) {
      commit('setLearningPages', payload);
    },
    setLearningPagesTreeview({ commit }, payload) {
      commit('setLearningPagesTreeview', payload);
    },
    setCompanyImages({ commit }, payload) {
      commit('setCompanyImages', payload);
    },
    getCompanyImages({ commit }, pagination) {
      return new Promise((resolve, reject) => {
        const { companyId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        let tagsString = '';
        if (!pagination) {
          pagination = {};
          pagination.pageNumber = 1;
          pagination.pageSize = 12;
          pagination.tags = [];
        } else if (pagination.tags.length > 0) {
          // If tags are assigned, you get all of the images (999) with this match
          pagination.tags.forEach((tag) => {
            tagsString += '&tags=' + encodeURIComponent(tag);
          });
          pagination.pageNumber = 1;
          pagination.pageSize = 999;
        }

        Vue.prototype.$http
          .get(
            `/v1/companies/${companyId}/companyfiles/images/paged?pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}${tagsString}`
          )
          .then(
            (result) => {
              commit('setCompanyImages', { images: result.data.list, hasTags: pagination.tags.length > 0 });
              commit('totalItems', result.data.properties.totalItems);
              resolve(result);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    getCompanyFiles({ commit }, pagination) {
      return new Promise((resolve, reject) => {
        const { companyId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        let tagsString = '';
        if (!pagination) {
          pagination = {};
          pagination.pageNumber = 1;
          pagination.pageSize = 12;
          pagination.tags = [];
        } else if (pagination.tags.length > 0) {
          // If tags are assigned, you get all of the images (999) with this match
          pagination.tags.forEach((tag) => {
            tagsString += '&tags=' + encodeURIComponent(tag);
          });
          pagination.pageNumber = 1;
          pagination.pageSize = 999;
        }

        if (pagination.pageNumber > 0) {
          pagination.pageNumber -= 1;
        }

        Vue.prototype.$http
          .get(
            `/v2/companies/${companyId}/files?take=${pagination.pageSize}&skip=${pagination.pageNumber *
              pagination.pageSize}${tagsString}`
          )
          .then(
            (result) => {
              commit('setCompanyFiles', result.data.data);
              commit('totalItems', result.data.summary.total);
              resolve(result);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    getCompanyFileAdmin({ commit }, companyFileId) {
      return new Promise((resolve, reject) => {
        const { companyId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.get(`/v2/companies/${companyId}/files/${companyFileId}`).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getCompanyFileAdminData({ commit }, companyFileId) {
      return new Promise((resolve, reject) => {
        const { companyId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        const url = `${VUE_APP_ADMIN_API_URL}/v2/companies/${companyId}/files/${companyFileId}/data`;
        fetch(url, {
          headers: {
            Authorization: `Bearer ${window.localStorage.admin_token}`
          }
        }).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getCompanyFileOnboardee({ commit }, companyFileId) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get(`/v2/company-files/${companyFileId}`).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getCompanyFileOnboardeeData({ commit }, companyFileId) {
      return new Promise((resolve, reject) => {
        const url = `${VUE_APP_API_URL}/v2/company-files/${companyFileId}/data`;
        fetch(url, {
          headers: {
            Authorization: `Bearer ${window.localStorage.token}`
          }
        }).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    createLearningPage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        let learningPage = cloneDeep(payload);
        delete learningPage.startWorkday;
        delete learningPage.endWorkday;

        if (learningPage.useFixedDates === true) {
          learningPage.fixedStartDate = new Date(new Date(learningPage.fixedStartDate).setHours(12)).toISOString();
          learningPage.fixedEndDate = new Date(new Date(learningPage.fixedEndDate).setHours(12)).toISOString();
        }

        if (learningPage.useFixedDates === 'always') {
          learningPage.startFromStartDate = null;
          learningPage.endFromStartDate = null;
          learningPage.useFixedDates = false;
        }

        if (learningPage.immediate) {
          learningPage.startFromStartDate = null;
          delete learningPage.immediate;
        }

        Vue.prototype.$http
          .post(`/v1/companies/${companyId}/templates/${templateId}/learningpages/items`, learningPage)
          .then(
            (response) => {
              commit('addLearningPage', response.data);
              resolve(response.data);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    updateLearningPage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        let learningPage = cloneDeep(payload.learningPage);

        if (learningPage.useFixedDates === true) {
          learningPage.fixedStartDate = new Date(new Date(learningPage.fixedStartDate).setHours(12)).toISOString();
          learningPage.fixedEndDate = new Date(new Date(learningPage.fixedEndDate).setHours(12)).toISOString();
        }

        if (learningPage.useFixedDates === 'always') {
          learningPage.startFromStartDate = null;
          learningPage.endFromStartDate = null;
          learningPage.useFixedDates = false;
        }

        if (learningPage.immediate) {
          learningPage.startFromStartDate = null;
          delete learningPage.immediate;
        }
        // learningPage.images = learningPage.images.filter(image => image.isMain);
        learningPage.translations.forEach((locale) => {
          locale.translations.forEach((translation) => {
            if (/^[0-9A-f]{32}$/.test(translation.value)) {
              const match = learningPage.images.find((image) => image.key === translation.value);
              if (!match) {
                learningPage.images.push({ key: translation.value, isMain: false });
              }
            }
          });
        });

        let defaultLanguageTranslations;
        let mappedDefaultTranslationKeys;
        if (payload.defaultLanguage) {
          defaultLanguageTranslations = learningPage.translations.find((e) => e.localeCode === payload.defaultLanguage);
          if (defaultLanguageTranslations) {
            mappedDefaultTranslationKeys = defaultLanguageTranslations.translations.map(
              (translation) => translation.key
            );
          }
        }

        const initTranslations = payload.initTranslations ? payload.initTranslations.translations : [];
        const diff = [];
        if (initTranslations.length > 0) {
          learningPage.translations.forEach((trans) => {
            const language = initTranslations.find((e) => e.localeCode === trans.localeCode);
            if (language) {
              let changed = false;
              trans.translations.forEach((element) => {
                const translatedElement = language.translations.find((e) => e.key === element.key);
                if (!translatedElement || element.value !== translatedElement.value) {
                  changed = true;
                }
              });

              // Checks if the new language is missing keys from the base language and adjust them
              if (defaultLanguageTranslations) {
                const mappedTranslationKeys = trans.translations.map((translation) => translation.key);

                const foundKey = difference(mappedDefaultTranslationKeys, mappedTranslationKeys);
                let defaultTranslation = cloneDeep(
                  defaultLanguageTranslations.translations.find((trans) => trans.key == foundKey)
                );
                if (defaultTranslation) {
                  const defaultValue = trans.translations.find((element) => element.key == defaultTranslation.key);
                  if (defaultValue) {
                    defaultTranslation.value = defaultValue.value;
                    trans.translations.push(defaultTranslation);
                  }
                }
              }

              if (changed) {
                diff.push(trans);
              }
            } else {
              //new language is added.
              diff.push(trans);
            }
          });
        }
        const putLearningPage = cloneDeep(learningPage);
        if (diff.length > 0) {
          putLearningPage.translations = diff;
        }
        Vue.prototype.$http
          .put(
            `/v1/companies/${companyId}/templates/${templateId}/learningpages/items/${learningPage.id}`,
            putLearningPage
          )
          .then(
            (response) => {
              commit('updateLearningPage', learningPage);
              // resolve(response.data);
              resolve(learningPage);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    assignLearningPageToModule({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };

        if (payload && payload.details && payload.details.useFixedDates === true) {
          payload.details.fixedStartDate = new Date(
            new Date(payload.details.fixedStartDate).setHours(12)
          ).toISOString();
          payload.details.fixedEndDate = new Date(new Date(payload.details.fixedEndDate).setHours(12)).toISOString();
        }
        if (payload && payload.details && payload.details.useFixedDates === 'always') {
          payload.details.startFromStartDate = null;
          payload.details.endFromStartDate = null;
          payload.details.useFixedDates = false;
        }

        if (payload && payload.immediate) {
          payload.details.startFromStartDate = null;
        }

        delete payload.immediate;

        Vue.prototype.$http
          .post(`/v1/companies/${companyId}/templates/${templateId}/learningpages/items/assign`, payload)
          .then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    unassignLearningPageToModule({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http
          .post(`/v1/companies/${companyId}/templates/${templateId}/learningpages/items/unassign`, payload)
          .then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    exportLearningPages({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.post(`/v1/companies/${companyId}/learningpages/items/export`, payload).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    deleteLearningPages({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.delete(`/v2/companies/${companyId}/learning-pages`, { data: payload }).then(
          (response) => {
            commit('deleteLearningPageByIds', payload.learningPageIds);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getLanguages({ commit }) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.get(`/v1/companies/${companyId}/templates/${templateId}/languages`).then(
          (result) => {
            commit('setLanguages', result.data);
            resolve(result.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getFilterLearningPage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        const { learningPageModuleId, learningPageId } = { ...payload };
        Vue.prototype.$http
          .get(
            `/v1/companies/${companyId}/templates/${templateId}/learningpages/items/filters/${learningPageModuleId}/${learningPageId}`
          )
          .then(
            (result) => {
              resolve(result.data || []);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    setFilterLearningPage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http
          .post(`/v1/companies/${companyId}/templates/${templateId}/learningpages/items/applyfilters`, payload)
          .then(
            (result) => {
              resolve(result.data || []);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    assignFilterFormInLearningPage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http
          .post(
            `/v1/companies/${companyId}/templates/${templateId}/learningpages/${payload.moduleId}/${payload.learningPageId}`,
            payload.filter
          )
          .then(
            (result) => {
              resolve(result.data || []);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    getFilterFormInLearningPage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        const { moduleId, learningPageId, formId } = { ...payload };
        Vue.prototype.$http
          .get(
            `/v1/companies/${companyId}/templates/${templateId}/learningpages/${moduleId}/${learningPageId}/${formId}`
          )
          .then(
            (result) => {
              resolve((result && result.data) || []);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    getPageSettingTranslation({ commit }, learningPageId) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http
          .get(`/v1/companies/${companyId}/templates/${templateId}/learningpages/items/translations/${learningPageId}`)
          .then(
            (result) => {
              commit('setPageSettingTranslation', result.data);
              resolve(result.data || []);
            },
            (err) => {
              reject();
            }
          );
      });
    },
    savePageSettingTranslation({ commit, state }) {
      let promises = [];
      const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };

      state.adjustedPageSettingTranslations.forEach((languageCode) => {
        const payload = state.pageSettingTranslation.find((translation) => translation.languageCode === languageCode);

        promises.push(
          new Promise((resolve, reject) => {
            Vue.prototype.$http
              .post(
                `/v1/companies/${companyId}/templates/${templateId}/learningpages/items/translate/${state.pageData.id}/${payload.languageCode}`,
                payload
              )
              .then(
                (result) => {
                  resolve(result.data);
                },
                (err) => {
                  reject();
                }
              );
          })
        );
      });
      return Promise.all(promises);
    },
    addFormToLearningPage({ commit }, payload) {
      const learningPageId = payload.learningPageId;
      const formId = payload.formId;

      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http
          .post(`/v1/companies/${companyId}/templates/${templateId}/learningpages/items/applyfilters`, payload)
          .then(
            (result) => {
              commit('setPageSettingTranslation', result.data);
              resolve(result.data || []);
            },
            (err) => {
              reject();
            }
          );
      });
    },
    exportTranslations({}, { learningPageId }) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };

        Vue.prototype.$http
          .get(
            `/v1/companies/${companyId}/templates/${templateId}/learningpages/items/${learningPageId}/translations/export`,
            {
              responseType: 'blob'
            }
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    importTranslations({}, { formData, id }) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };

        Vue.prototype.$http
          .post(
            `/v1/companies/${companyId}/templates/${templateId}/learningpages/items/${id}/translations/import`,
            formData
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
  }
};

export default learningPages;
