export default {
  formSection: 'form-section',
  form: 'form',
  formQuestion: 'form-question',
  formQuestionDescription: 'form-question-description',
  formLabel: 'form-label',
  formChoiceLabel: 'form-choice-label',
  label: 'label',
  radio: 'radio',
  formInput: 'input',
  textArea: 'textArea',
  questionOpenAnswer: 'open-answer',
  questionOtherAnswer: 'open-other-answer',
  questionChoice: 'form-choice-question',
  questionRating: 'form-rating',
  questionFile: 'form-file-input',
  questionDate: 'form-date-field',
  questionPhone: 'form-phone-field',
  questionCountry: 'form-country-field',
  questionAddress: 'form-address-field',
  questionIban: 'form-iban-field',
  questionGDPR: 'form-gdpr-field',
  questionEmail: 'form-email-field',
  questionRegExp: 'form-reg-exp-field',
  questionNumber: 'form-number-field',
  group: 'form-group'
};
