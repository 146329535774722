<template>
  <div class="form-gdrp-field">
    <div
      class="gdpr-intro"
      :contenteditable="editMode"
      @blur="changeIntroTextGdpr"
      @focus="setSelectedElement('gdpr-intro', $event)"
      v-html="gdprText"
      v-if="showGdprText"
    ></div>
    <input type="checkbox" class="form-check-input" :disabled="editMode" :value="true" :name="model" v-model="data" />
    <span class="gdpr-label gdpr-label-edit" :contenteditable="editMode" @blur="changeLabelGdpr" @click="toggle"
      >{{ label }} </span
    ><span class="required"></span>
  </div>
</template>
<script>
import setSelected from '@forms/mixins/setSelected';

export default {
  mixins: [setSelected],
  props: {
    label: {
      type: String
    },
    model: {
      type: String
    },
    editMode: {
      type: Boolean
    },
    gdprText: {
      type: String
    },
    showGdprText: {
      type: Boolean
    },
    initialValue: {
      type: Boolean
    },
    questionId: {
      type: String
    }
  },
  data() {
    return {
      data: false
    };
  },
  created() {
    if (this.initialValue) {
      this.data = Boolean(this.initialValue);
    }
  },
  methods: {
    toggle() {
      if (this.editMode) {
        return;
      }
      if (this.data === true) {
        this.data = false;
      } else {
        this.data = true;
      }
    },
    changeLabelGdpr(event) {
      this.$store.commit('formBuilder/editFormQuestion', {
        type: 'label',
        value: event.currentTarget.innerText
      });
    },
    changeIntroTextGdpr(event) {
      this.$store.commit('formBuilder/editFormQuestion', {
        type: 'gdprText',
        value: event.currentTarget.innerHTML
      });
    }
  },
  watch: {
    data() {
      if (!this.editMode) {
        this.$el.parentElement.querySelector(`[id="${this.model}"]`).value = this.data;
        this.$el.parentElement.querySelector(`[id="${this.model}"]`).dispatchEvent(new Event('input'));
      }
    }
  },
  mounted() {
    if (this.$el.parentElement.querySelector(`input[id="${this.model}"]`)) {
      const self = this;
      this.$el.parentElement
        .querySelector(`input[id="${this.model}"]`)
        .addEventListener('change', (newInput) => (self.data = Boolean(newInput.target.value)), false);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-gdrp-field input {
  margin-left: 0;
  padding: 0;
}

.gdpr-intro,
.gdpr-label {
  font-size: 15px;
}

.gdpr-intro {
  line-height: 24px;
  margin-bottom: 16px;
}

.required::after {
  content: ' *';
  font-size: 18px;
  vertical-align: middle;
}

.gdpr-label {
  line-height: 16px;
  cursor: pointer;
}

.gdpr-label-edit[contenteditable='true'] {
  cursor: text;
}

[type='checkbox']:checked,
[type='checkbox']:not(:checked) {
  margin-left: 5px;
}

input[type='checkbox']:checked:after {
  background-color: #021c2c;
  border-color: #021c2c;
}

[type='checkbox']:checked + label,
[type='checkbox']:not(:checked) + label {
  position: relative;
  padding: 2px 0 0 0px;
  height: 24px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  font-weight: bold;
}
</style>
