<template>
  <div class="form-phone-field">
    <div class="block">
      <vue-tel-input
        v-model="value"
        :disabled="editMode"
        :placeholder="placeholder"
        :validCharactersOnly="true"
        :translations="translations"
        :default-country-code="defaultCountry"
        :required="isRequired"
        @update="updateDate"
      ></vue-tel-input>
    </div>
  </div>
</template>
<script>
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default {
  props: {
    editMode: {
      type: Boolean
    },
    isRequired: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    model: {
      type: String
    },
    initialValue: {
      type: String
    }
  },
  data() {
    return {
      value: '',
      defaultCountry: 'US',
      translations: {
        countrySelectorLabel: this.$t('forms.countrySelectorLabel'),
        countrySelectorError: this.$t('forms.countrySelectorError'),
        phoneNumberLabel: this.$t('forms.phoneNumberLabel'),
        example: this.$t('forms.example').concat(' :')
      }
    };
  },
  methods: {
    updateDate(event) {
      if (event.formatInternational) {
        this.$el.parentElement.querySelector(`[id="${this.model}"]`).value = event.formatInternational;
        this.$el.parentElement.querySelector(`[id="${this.model}"]`).dispatchEvent(new Event('input'));
      }
    }
  },
  created() {
    if (this.initialValue && this.initialValue !== 'undefined') {
      const parsed = parsePhoneNumberFromString(this.initialValue);
      if (parsed) {
        this.defaultCountry = parsed.country;
        this.value = parsed.nationalNumber;
      } else {
        this.value = this.initialValue;
      }
    }
  },
  mounted() {
    if (this.$el.parentElement.querySelector(`input[id="${this.model}"]`)) {
      const self = this;
      this.$el.parentElement
        .querySelector(`input[id="${this.model}"]`)
        .addEventListener('change', (newInput) => (self.value = newInput.target.value), false);
    }

    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    if (lang !== null) {
      if (lang.indexOf('-') !== 1) {
        lang = lang.split('-').pop();
      }
      this.defaultCountry = lang.toUpperCase();
    }
  }
};
</script>

<style lang="scss">
.vti__dropdown-list {
  z-index: 999 !important;
}
.vti__input {
  font-family: 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.country-selector__country-flag {
  top: 17px !important;
}
.vue-tel-input {
  height: 40px;
  border: 1px solid #ddd !important;
}
</style>

<style lang="scss" scoped>
.form-date-field {
  font-family: 'Talmundo', 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
</style>
