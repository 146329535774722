import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const dashboard = {
  namespaced: true,
  state: {
    data: {
      calendarDays: 0
    }
  },
  getters: {
    dashboardData(state) {
      return state.data;
    }
  }
};

export default dashboard;
