import each from 'lodash/each';
import flatten from 'lodash/flatten';

const COLOR_PRIMARY = '#D7E0E8';
const COLOR_SECONDARY = '#021C2C';
const COLOR_CTA = '#F3C300';
const COLOR_TEXT = '#000000';
const BLACK = '#000000';
const WHITE = '#FFFFFF';
export const LIGHT_GREY = '#f9f9f9';

export const map = {
  primary: '$color-primary',
  secondary: '$color-secondary',
  cta: '$color-cta',
  text: '$color-text',
  black: BLACK,
  white: WHITE
};

// DEFAULT VALUES FOR CURRENT THEMES

export const primaryKeys = [
  '$color-secondary-button',
  '$menu-hover-background-color',
  '$welcome-title-color', // ✔ //
  '$counter-number-color',
  '$company-page-image-caption-background-color',
  '$introduction-icon-color',
  '$footer-background-color',
  '$did-you-know-indicator-color',
  '$module-title-color',
  '$color-default-button-text',
  '$contact-subtitle-text-color',
  '$contact-subtitle-icon-color'
];

export const secondaryKeys = [
  '$progress-color',
  '$pulse-check-subtitle-color',
  '$pulse-check-star-color',
  '$module-show-all-text-color' // ✔ //
];

export const ctaKeys = [
  '$color-primary-button', // ✔ //
  '$tasks-link-color', // ✔ //
  '$notification-badge-color',
  '$learning-page-grid-outline-color',
  '$contact-icon-color'
];

export const textKeys = [
  '$menu-text-color', // ✔ //
  '$subheader-text-color', // ✔ //
  '$pulse-check-title-color',
  '$quiz-title-color',
  '$quiz-icon-color',
  '$counter-text-color', // ✔ //
  '$progress-text-color', // ✔ //
  '$learning-page-indicator-color',
  '$introduction-text-color',
  '$did-you-know-text-color'
];

export const whiteKeys = [
  '$notification-badge-text-color', // ✔ //
  '$color-primary-button-text',
  '$color-secondary-button-text',
  '$color-default-button',
  '$menu-background-color',
  '$menu-hover-text-color',
  '$footer-text-color',
  '$company-page-image-caption-text-color'
];

export const blackKeys = ['$header-icon-color'];

// END DEFAULT VALUES FOR CURRENT THEMES

export const main = {
  '$color-primary': COLOR_PRIMARY,
  '$color-secondary': COLOR_SECONDARY,
  '$color-cta': COLOR_CTA,
  '$color-text': COLOR_TEXT
};

export const fonts = {
  $font: 'Raleway', // ✔ //
  '$title-font': 'Oswald' // ✔ //
};

export const background = {
  $background: WHITE, // ✔ //
  '$dashboard-background': WHITE, // ✔ //
  '$login-background': WHITE // ✔ //
};

const buttons = {
  '$color-primary-button': COLOR_CTA, // ✔ //
  '$color-primary-button-text': WHITE, // ✔ //
  '$color-secondary-button': COLOR_PRIMARY, // ✔ //
  '$color-secondary-button-text': WHITE, // ✔ //
  '$color-default-button': WHITE, // ✔ //
  '$color-default-button-text': COLOR_SECONDARY // ✔ //
};

const header = {
  '$header-background-color': WHITE, // ✔ //
  '$header-icon-color': BLACK, // ✔ //  (navIconColor ?)
  '$notification-badge-color': COLOR_CTA, // ✔ //
  '$notification-badge-text-color': WHITE // ✔ //
};

const menu = {
  '$menu-background-color': WHITE, // ✔ //
  '$menu-text-color': COLOR_TEXT, // ✔ //
  '$menu-hover-background-color': COLOR_PRIMARY, // ✔ //
  '$menu-hover-text-color': WHITE // ✔ //
};

const subheader = {
  '$subheader-background-color': LIGHT_GREY, // ✔ //
  '$subheader-text-color': COLOR_TEXT, // ✔ //
  '$welcome-title-color': COLOR_PRIMARY, // ✔ //
  '$tasks-link-color': COLOR_CTA, // ✔ //
  '$counter-number-color': COLOR_PRIMARY, // ✔ //
  '$counter-text-color': COLOR_TEXT, // ✔ //
  '$progress-color': COLOR_SECONDARY, // ✔ //
  '$progress-text-color': COLOR_TEXT // ✔ //
};

const module = {
  '$module-show-all-text-color': COLOR_CTA, // ✔ //
  '$module-title-color': COLOR_PRIMARY // ✔ //
};

const company_pages = {
  '$company-page-image-caption-background-color': COLOR_PRIMARY,
  '$company-page-image-caption-text-color': WHITE
};

const learning_pages = {
  '$learning-page-indicator-color': COLOR_CTA,
  '$learning-page-grid-outline-color': COLOR_CTA
};
const introduce_yourself = {
  '$introduction-text-color': COLOR_TEXT,
  '$introduction-icon-color': COLOR_PRIMARY
};
const pulse_check = {
  '$pulse-check-title-color': COLOR_TEXT,
  '$pulse-check-subtitle-color': COLOR_SECONDARY,
  '$pulse-check-star-color': COLOR_SECONDARY
};
const quiz = {
  '$quiz-title-color': COLOR_TEXT,
  '$quiz-icon-color': COLOR_SECONDARY
};

const did_you_know = {
  '$did-you-know-text-color': COLOR_TEXT,
  '$did-you-know-indicator-color': COLOR_CTA
};
const contact = {
  '$contact-name-text-color': COLOR_TEXT,
  '$contact-subtitle-text-color': COLOR_SECONDARY,
  '$contact-subtitle-icon-color': COLOR_SECONDARY,
  '$contact-icon-color': COLOR_CTA
};

const footer = {
  '$footer-background-color': COLOR_PRIMARY,
  '$footer-text-color': WHITE
};

const grouped = {
  main,
  fonts,
  background,
  buttons,
  header,
  menu,
  subheader,
  module,
  company_pages,
  learning_pages,
  introduce_yourself,
  pulse_check,
  quiz,
  did_you_know,
  contact,
  footer,
  advanced: ''
};

export default grouped;

const ungroupedObj = {};

each(grouped, (group) => {
  each(group, (value, key) => {
    ungroupedObj[key] = value;
  });
});

export const ungrouped = ungroupedObj;

export const flattened = flatten(Object.keys(grouped).map((group) => Object.keys(grouped[group])));
