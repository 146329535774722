<template>
  <div class="form-group tags">
    <span class="label">{{ 'lp.learningPageTags' | translate }}</span>
    <el-tag :key="tag" v-for="tag in tags" closable :disable-transitions="true" @close="handleClose(tag)">
      {{ tag }}
    </el-tag>
    <el-input
      class="input-new-tag"
      v-if="inputVisible"
      v-model="inputValue"
      ref="saveTagInput"
      size="mini"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    >
    </el-input>
    <el-button v-else class="button-new-tag" size="small" @click="showInput">{{ 'lp.newTag' | translate }}</el-button>
  </div>
</template>

<script>
import { Tag, Input, Button } from 'element-ui';
import { eventNames } from 'process';
export default {
  name: 'Tags',
  components: {
    elTag: Tag,
    elInput: Input,
    elButton: Button
  },
  props: {
    tags: {
      type: Array
    }
  },
  data() {
    return {
      inputValue: '',
      inputVisible: false
    };
  },
  watch: {
    inputVisible: {
      immediate: true,
      handler() {
        this.$emit('editTags', this.inputVisible);
      }
    },
    tags() {
      this.$emit('tagsChanged');
    }
  },
  methods: {
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm(event) {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.tags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    }
  }
};
</script>

<style lang="scss">
@import '@shared/styles/_colors.scss';

.tags {
  .label {
    font-weight: bold;
    width: 40px;
    display: inline-block;
  }

  .el-tag {
    margin-right: 10px;
  }

  .el-tag__close.el-icon-close {
    color: $color-darkgreyblue !important;
  }

  .el-tag__close.el-icon-close:hover {
    background-color: $color-midgreyblue !important;
  }

  .el-tag,
  .input-new-tag,
  button.el-button.button-new-tag {
    border-radius: 5px;
    background-color: white;
    border-color: $color-darkgreyblue;
    color: $color-darkgreyblue;
  }

  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
</style>
