import Vue from 'vue';
import Vuex from 'vuex';
import omit from 'lodash/omit';
import fieldTypes from '@forms/const/fieldTypes.js';
import buildUISchema from '@forms/mixins/uischema.js';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import { uuid } from 'vue-uuid';

Vue.use(Vuex);

const forms = {
  namespaced: true,
  form: null,
  state: {
    id: null,
    form: {
      uiSchema: [],
      schema: {}
    },
    selectedItem: { questionId: null, groupId: null, sectionId: null },
    selectedElement: null,
    filterForms: {},
    hasChanges: false,
    allowSelect: true
  },
  getters: {
    uiSchema(state) {
      return buildUISchema(state, false);
    },
    uiSchemaBuilder(state) {
      return buildUISchema(state, true);
    },
    amountOfSections(state) {
      if (!state.form.schema.properties) {
        return 0;
      }
      return Math.max(-1, ...Object.keys(state.form.schema.properties).map((e) => Number(e.split('/').pop()))) + 1;
    },
    createQuestionIdInSection(state) {
      if (state.selectedItem.sectionId) {
        if (!state.form.schema.properties) {
          return 0;
        }

        var map = Object.keys(state.form.schema.properties).map((e) =>
          state.form.schema.properties[e].properties ? Object.keys(state.form.schema.properties[e].properties) : []
        );
        return (
          Math.max(
            -1,
            ...flatten(map)
              .filter((e) => !isNaN(Number(e.split('/').pop())))
              .map((e) => Number(e.split('/').pop()))
          ) + 1
        );
      } else {
        return 0;
      }
    },
    selectedItem(state) {
      return state.selectedItem;
    },
    selectedSection: (state, getters) => {
      let result = {};
      const selectedItem = getters.selectedItem;
      if (selectedItem.sectionId && getters.schema.properties) {
        result = { schema: getters.schema.properties[selectedItem.sectionId], key: selectedItem.sectionId };
      }
      return result;
    },
    selectedGroup: (state, getters) => {
      let result = false;
      const selectedItem = getters.selectedItem;
      if (selectedItem.sectionId && selectedItem.groupId) {
        result = {
          schema: getters.schema.properties[selectedItem.sectionId].properties[selectedItem.groupId],
          key: selectedItem.groupId
        };
      }
      return result;
    },
    selectedQuestion: (state, getters) => {
      let result = undefined;
      if (state.selectedItem.sectionId && state.selectedItem.questionId) {
        if (state.selectedItem.groupId) {
          result = {
            schema:
              state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
                .properties[state.selectedItem.questionId],
            key: state.selectedItem.questionId
          };
        } else {
          result = {
            schema:
              state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId],
            key: state.selectedItem.questionId
          };
        }
      }
      return result;
    },
    dependencyQuestionDataById: (state) => (id) => {
      if (state.form.schema.allOf) {
        const ifvalues = state.form.schema.allOf.filter((e) =>
          e.then.properties[Object.keys(e.then.properties)[0]].required
            ? e.then.properties[Object.keys(e.then.properties)[0]].required[0] === id
            : false
        );
        if (ifvalues && ifvalues.length > 0) {
          return ifvalues.map((e) => {
            return {
              $id: e.$id,
              section: Object.keys(e.if.properties)[0],
              question: Object.keys(e.if.properties[Object.keys(e.if.properties)[0]].properties)[0],
              value:
                e.if.properties[Object.keys(e.if.properties)[0]].properties[
                  Object.keys(e.if.properties[Object.keys(e.if.properties)[0]].properties)[0]
                ].const
            };
          });
        }
      }
      return false;
    },
    requiredQuestion(state) {
      if (state.selectedItem.sectionId && state.selectedItem.questionId) {
        if (state.selectedItem.groupId) {
          return state.form.schema.properties[state.selectedItem.sectionId].properties[
            state.selectedItem.groupId
          ].items.required.includes(state.selectedItem.questionId);
        } else {
          return state.form.schema.properties[state.selectedItem.sectionId].required.includes(
            state.selectedItem.questionId
          );
        }
      }
    },
    requiredGroup(state) {
      if (
        state.selectedItem.sectionId &&
        state.selectedItem.groupId &&
        state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
      ) {
        return !!state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
          .minItems;
      }
    },
    schema(state) {
      return state.form.schema;
    },
    alignment(state) {
      if (!state.form.schema.properties || Object.keys(state.form.schema.properties).length <= 0) {
        return 'text-left';
      }

      return Object.keys(state.form.schema.properties).map((key) => {
        return state.form.schema.properties[key].data.textAlignment;
      })[0];
    },
    filterForms(state) {
      return state.filterForms;
    },
    hasChanges(state) {
      return state.hasChanges;
    }
  },
  mutations: {
    hasChanges(state, hasChanges) {
      state.hasChanges = hasChanges;
    },
    setAllowSelect(state, allowSelect) {
      state.allowSelect = allowSelect;
    },
    redraw(state) {
      // When calling this mutation it will remake the uischema and thus redraw.
      // This is not the cleanest way and we should use Vue.set, but in case we cannot find to origin of the problem this can be used.
      Vue.set(state.form.schema.properties, 'redraw', {});
      Vue.delete(state.form.schema.properties, 'redraw');
    },
    addFormSection(state, payload) {
      if (!state.form.schema.properties) {
        Vue.set(state.form.schema, 'properties', {});
      }
      Vue.set(state.form.schema.properties, payload.key, payload.section);
      state.selectedItem.sectionId = payload.key;
      if (!state.form.schema.required) {
        Vue.set(state.form.schema, 'required', [payload.key]);
      } else if (state.form.schema.required.indexOf(payload.key) < 0) {
        state.form.schema.required.push(payload.key);
      }

      state.hasChanges = true;
    },
    addFormFinalMessage(state, payload) {
      if (!state.form.schema.data) {
        Vue.set(state.form.schema, 'data', {});
      }
      Vue.set(state.form.schema.data, payload.type, payload.value);
    },
    editForm(state, payload) {
      state.form.schema.data[payload.type] = payload.value;
      state.hasChanges = true;
    },
    editFormSection(state, payload) {
      state.form.schema.properties[state.selectedItem.sectionId][payload.type] = payload.value;
      state.hasChanges = true;
    },
    editFormSectionProp(state, payload) {
      state.form.schema.properties[state.selectedItem.sectionId].data[payload.type] = payload.value;
      state.hasChanges = true;
    },
    editGroupQuestion(state, payload) {
      state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId][payload.type] =
        payload.value;
      state.hasChanges = true;
    },
    removeGroupQuestionProp(state, payload) {
      Vue.delete(
        state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId],
        payload
      );
      state.hasChanges = true;
    },
    editGroupQuestionProp(state, payload) {
      state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].data[
        payload.type
      ] = payload.value;
      state.hasChanges = true;
    },
    editFormQuestion(state, payload) {
      if (payload.type) {
        if (state.selectedItem.groupId) {
          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
              .properties[state.selectedItem.questionId].data,
            payload.type,
            payload.value
          );
        } else {
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId].data[
            payload.type
          ] = payload.value;
          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId].data,
            payload.type,
            payload.value
          );
        }
      }
      state.hasChanges = true;
    },
    deleteFormQuestionProp(state, payload) {
      if (state.selectedItem.groupId) {
        Vue.delete(
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties[
            state.selectedItem.questionId
          ],
          payload
        );
        if (payload === 'minItems') {
          Vue.delete(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties
              .items[state.selectedItem.questionId],
            payload
          );
        }
      } else {
        Vue.delete(
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId],
          payload
        );
      }
      state.hasChanges = true;
    },
    editFormQuestionProp(state, payload) {
      if (payload.type) {
        if (state.selectedItem.groupId && !state.selectedItem.questionId) {
          //Question inside a group
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId][
            payload.type
          ] = payload.value;
          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId],
            payload.type,
            payload.value
          );
        } else {
          if (state.selectedItem.groupId) {
            //Question inside a group
            state.form.schema.properties[state.selectedItem.sectionId].properties[
              state.selectedItem.groupId
            ].properties[state.selectedItem.questionId][payload.type] = payload.value;
            Vue.set(
              state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
                .properties[state.selectedItem.questionId],
              payload.type,
              payload.value
            );
            //Replace the placeholder for open question
            if (
              state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
                .properties[state.selectedItem.questionId].data.placeholder &&
              payload.type === 'title'
            ) {
              Vue.set(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
                  .properties[state.selectedItem.questionId].data,
                'placeholder',
                payload.value
              );
            }
            if (payload.type === 'items') {
              Vue.set(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
                  .properties[state.selectedItem.questionId],
                payload.type,
                payload.value
              );
              Vue.delete(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
                  .properties[state.selectedItem.questionId],
                'enum'
              );
            }
            if (payload.type === 'pattern' || payload.type === 'minimum' || payload.type === 'maximum') {
              Vue.set(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
                  .properties[state.selectedItem.questionId],
                payload.type,
                payload.value
              );
            }
            if (payload.type === 'enum') {
              Vue.set(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
                  .properties[state.selectedItem.questionId],
                payload.type,
                payload.value
              );
              Vue.delete(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
                  .properties[state.selectedItem.questionId],
                'items'
              );
            }
            if (payload.type === 'type' || payload.type === 'minItems') {
              Vue.set(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
                  .properties[state.selectedItem.questionId],
                payload.type,
                payload.value
              );
            }
          } else {
            //question without a group
            if (state.selectedItem.questionId) {
              state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId][
                payload.type
              ] = payload.value;
              Vue.set(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId],
                payload.type,
                payload.value
              );
              //Replace the placeholder for open question
              if (
                state.form.schema.properties[state.selectedItem.sectionId].properties[
                  state.selectedItem.questionId
                ].data.hasOwnProperty('placeholder') &&
                payload.type === 'title'
              ) {
                Vue.set(
                  state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId]
                    .data,
                  'placeholder',
                  payload.value
                );
              }
            }
          }
        }
      }
      state.hasChanges = true;
    },
    editFormRatingMax(state, payload) {
      if (payload.type) {
        if (state.selectedItem.groupId) {
          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
              .properties[state.selectedItem.questionId],
            payload.type,
            payload.value
          );
        } else {
          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId],
            payload.type,
            payload.value
          );
        }
      }
      state.hasChanges = true;
    },
    editChoiceOption(state, payload) {
      if (state.selectedItem.groupId) {
        if (
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
            .properties[state.selectedItem.questionId].enum
        ) {
          state.form.schema.properties[state.selectedItem.sectionId].properties[
            state.selectedItem.groupId
          ].items.properties[state.selectedItem.questionId].enum[payload.key] = payload.value;
        }
        if (
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties[
            state.selectedItem.questionId
          ].enum
        ) {
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties[
            state.selectedItem.questionId
          ].enum[payload.key] = payload.value;
        } else {
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties[
            state.selectedItem.questionId
          ].items.enum[payload.key] = payload.value;
        }
      } else {
        let previousValue = '';
        if (state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId].enum) {
          previousValue =
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId].enum[
              payload.key
            ];
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId].enum[
            payload.key
          ] = payload.value;
        } else {
          previousValue =
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId].items
              .enum[payload.key];
          state.form.schema.properties[state.selectedItem.sectionId].properties[
            state.selectedItem.questionId
          ].items.enum[payload.key] = payload.value;
        }
        if (state.form.schema.allOf) {
          state.form.schema.allOf.forEach((e) => {
            if (
              e.if.properties[state.selectedItem.sectionId] &&
              e.if.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId] &&
              e.if.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId].const ===
                previousValue
            ) {
              e.if.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId].const =
                payload.value;
            }
          });
        }
      }
      state.hasChanges = true;
    },
    rearrangeSections(state, payload) {
      payload.ordered.forEach((e) => {
        state.form.schema.properties[e.key].data.order = e.order;
      });
      state.hasChanges = true;
    },
    rearrangeQuestions(state, payload) {
      payload.ordered.forEach((e) => {
        if (payload.selectedItem.groupId) {
          state.form.schema.properties[payload.selectedItem.sectionId].properties[
            payload.selectedItem.groupId
          ].properties[e.key].data.order = e.order;
        } else {
          state.form.schema.properties[payload.selectedItem.sectionId].properties[e.key].data.order = e.order;
        }
      });
      state.hasChanges = true;
    },
    moveQuestionToSection(state, payload) {
      // Move question from to new section
      const questionToMove = cloneDeep(
        state.form.schema.properties[payload.currentSection].properties[payload.questionId]
      );
      questionToMove.$id = `#/properties/${payload.newSection}/properties/${payload.questionId}`;
      questionToMove.index = `${payload.newSection}.${payload.questionId}`;
      questionToMove.data.order = payload.newOrder;

      // in case new section is empty
      if (!state.form.schema.properties[payload.newSection].properties) {
        state.form.schema.properties[payload.newSection].properties = {};
      }

      Vue.delete(state.form.schema.properties[payload.currentSection].properties, payload.questionId);
      Vue.set(state.form.schema.properties[payload.newSection].properties, payload.questionId, questionToMove);

      // Transfer required to new section
      const requiredQuestion = state.form.schema.properties[payload.currentSection].required.find(
        (requiredQuestion) => requiredQuestion === payload.questionId
      );
      if (requiredQuestion) {
        state.form.schema.properties[payload.currentSection].required = state.form.schema.properties[
          payload.currentSection
        ].required.filter((requiredQuestionKey) => requiredQuestionKey !== requiredQuestion);

        let requiredSection = state.form.schema.properties[payload.newSection].required;
        if (requiredSection.indexOf(requiredQuestion) < 0) {
          requiredSection.push(requiredQuestion);
        }
      }

      // Remove dependency when moving.
      if (state.form.schema.allOf) {
        state.form.schema.allOf = state.form.schema.allOf.filter((allOf) => {
          return (
            allOf.then.properties[payload.currentSection] &&
            allOf.then.properties[payload.currentSection].required.indexOf(payload.questionId) === -1
          );
        });

        if (state.form.schema.allOf.length === 0) {
          Vue.delete(state.form.schema, 'allOf');
        }
      }
      state.hasChanges = true;
    },
    setForm(state, form) {
      state.form = form;
      if (form.versions.length > 0) {
        state.form = form.versions[form.versions.length - 1];
      } else {
        // Set default schema
        Vue.set(state.form, 'schema', {
          $id: 'form/' + form.id,
          $schema: 'http://json-schema.org/draft-07/schema#',
          type: 'object'
          // definitions: {}
        });
        Vue.set(state.form, 'uiSchema', []);
      }
      state.hasChanges = false;
    },
    setSelectedItem(state, payload) {
      if (!state.allowSelect) {
        return;
      }

      const newItem = cloneDeep(state.selectedItem);
      newItem.sectionId = payload.sectionId;
      newItem.groupId = payload.groupId;
      newItem.questionId = payload.questionId;
      state.selectedItem = newItem;
    },
    setSelectedElement(state, payload) {
      state.selectedElement = payload;
    },
    addFormQuestion(state, payload) {
      if (payload.fromGroup) {
        //Case group question
        if (
          !state.form.schema.properties[fieldTypes.formSection + '/' + payload.index].properties[payload.fromGroup]
            .properties
        ) {
          Vue.set(
            state.form.schema.properties[fieldTypes.formSection + '/' + payload.index].properties[payload.fromGroup],
            'properties',
            {}
          );
        }

        if (
          !state.form.schema.properties[fieldTypes.formSection + '/' + payload.index].properties[payload.fromGroup]
            .items.properties
        ) {
          Vue.set(
            state.form.schema.properties[fieldTypes.formSection + '/' + payload.index].properties[payload.fromGroup]
              .items,
            'properties',
            {}
          );
        }

        // Note the key of question of groups should follow /form-group/x/form-question/x.
        // This differs from the $id that is generated
        var replace = payload.fromGroup + '/properties/';
        payload.key = payload.key.replace(replace, '');
        Vue.set(
          state.form.schema.properties[fieldTypes.formSection + '/' + payload.index].properties[payload.fromGroup]
            .properties,
          payload.key,
          payload.question
        );
        Vue.set(
          state.form.schema.properties[fieldTypes.formSection + '/' + payload.index].properties[payload.fromGroup].items
            .properties,
          payload.key,
          omit(payload.question, ['$id', 'title', 'description', 'data', 'index'])
        );

        // if the group is required or the group has a dependency, adding a question makes it required as well
        if (
          (state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId] &&
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
              .minItems) ||
          payload.dependentGroup
        ) {
          if (state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]) {
            let requiredItems =
              state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
                .required;
            if (requiredItems.indexOf(payload.key < 0)) {
              requiredItems.push(payload.key);
            }
          }
        }
      } else {
        //Normal case
        if (!state.form.schema.properties[fieldTypes.formSection + '/' + payload.index].properties) {
          Vue.set(state.form.schema.properties[fieldTypes.formSection + '/' + payload.index], 'properties', {});
        }

        Vue.set(
          state.form.schema.properties[fieldTypes.formSection + '/' + payload.index].properties,
          payload.key,
          payload.question
        );

        let requiredSection = state.form.schema.properties[fieldTypes.formSection + '/' + payload.index].required;
        if (requiredSection.indexOf(payload.key < 0)) {
          requiredSection.push(payload.key);
        }
      }
      state.hasChanges = true;
    },
    duplicateSections(state, payload) {
      const sectionToDuplicate = cloneDeep(state.form.schema.properties[state.selectedItem.sectionId]);
      const newProperties = {};
      const newRequired = [];

      //Update the newproperties to add the existing section properties.
      if (sectionToDuplicate.properties !== undefined) {
        Object.keys(sectionToDuplicate.properties).forEach((e) => {
          const currentGroupIndex = e;
          if (e.includes('form-group')) {
            const newGroupProperties = {};
            const newGroupItemProperties = {};
            const newGroupItemRequired = [];
            let groupIndex = `${fieldTypes.group}/${payload.question}`;
            newProperties[groupIndex] = sectionToDuplicate.properties[e];
            newProperties[
              groupIndex
            ].$id = `#/properties/${fieldTypes.formSection}/${payload.index}/properties/${fieldTypes.group}/${groupIndex}`;
            newProperties[groupIndex].index = groupIndex;

            if (newProperties[groupIndex].properties !== undefined) {
              Object.keys(newProperties[groupIndex].properties).forEach((e) => {
                let index = e.includes('Other')
                  ? e
                      .split('/')
                      .slice(-2)
                      .join('/')
                  : e.split('/').pop();
                let groupPropertyIndex = `${fieldTypes.group}/${payload.question}.${fieldTypes.group}/${payload.question}/${fieldTypes.formQuestion}/${index}`;
                newGroupProperties[groupPropertyIndex] = newProperties[groupIndex].properties[e];
                newGroupProperties[groupPropertyIndex].$id = groupPropertyIndex;
                newGroupProperties[groupPropertyIndex].index = groupPropertyIndex;
              });

              Object.keys(newProperties[groupIndex].items.properties).forEach((e) => {
                let index = e.includes('Other')
                  ? e
                      .split('/')
                      .slice(-2)
                      .join('/')
                  : e.split('/').pop();
                let groupPropertyIndex = `${fieldTypes.group}/${payload.question}.${fieldTypes.group}/${payload.question}/${fieldTypes.formQuestion}/${index}`;
                newGroupItemProperties[groupPropertyIndex] = newProperties[groupIndex].items.properties[e];
              });

              if (newProperties[groupIndex].items.required.length >= 1) {
                let addRequiredForGroupItems = true;
                if (state.form.schema.allOf && state.form.schema.allOf.length >= 1) {
                  state.form.schema.allOf.forEach((allOf) => {
                    if (allOf.then.properties[payload.currentSection].required.includes(currentGroupIndex)) {
                      // If it was a dependent group, it should not apply required values to group items
                      addRequiredForGroupItems = false;
                    }
                  });
                }

                if (addRequiredForGroupItems) {
                  newProperties[groupIndex].items.required.map((e) => {
                    let index = e.includes('Other')
                      ? e
                          .split('/')
                          .slice(-2)
                          .join('/')
                      : e.split('/').pop();
                    let groupPropertyIndex = `${fieldTypes.group}/${payload.question}.${fieldTypes.group}/${payload.question}/${fieldTypes.formQuestion}/${index}`;
                    if (newGroupItemRequired.indexOf(groupPropertyIndex) < 0) {
                      newGroupItemRequired.push(groupPropertyIndex);
                    }
                  });
                }
              }
              if (sectionToDuplicate.required.includes(e)) {
                newRequired.push(groupIndex);
              }

              newProperties[groupIndex].properties = newGroupProperties;
              newProperties[groupIndex].items.properties = newGroupItemProperties;
              newProperties[groupIndex].items.required = [...new Set(newGroupItemRequired)];
            }
          } else {
            //Check if the choice question has other-option selected
            if (sectionToDuplicate.properties[e]['field-type'] === 'open-other-answer') {
              payload.question = payload.question - 1;
              let index = `${fieldTypes.formQuestion}/${payload.question}/Other`;
              newProperties[index] = sectionToDuplicate.properties[e];
              newProperties[
                index
              ].$id = `#/properties/${fieldTypes.formSection}/${payload.index}/${fieldTypes.formQuestion}/${payload.question}/Other`;
              newProperties[index].index = index;
            } else {
              let index = `${fieldTypes.formQuestion}/${payload.question}`;
              newProperties[index] = sectionToDuplicate.properties[e];
              newProperties[
                index
              ].$id = `#/properties/${fieldTypes.formSection}/${payload.index}/${fieldTypes.formQuestion}/${payload.question}`;
              newProperties[index].index = index;
              if (sectionToDuplicate.required.includes(e)) {
                newRequired.push(index);
              }
            }
          }
          payload.question++;
        });
        sectionToDuplicate.properties = newProperties;
      }
      sectionToDuplicate.required = [...new Set(newRequired)];
      sectionToDuplicate.data.order = payload.order;
      sectionToDuplicate.$id = `#/properties/${payload.key}`;
      sectionToDuplicate.index = `${payload.key}`;

      if (state.form.schema.required.includes(state.selectedItem.sectionId)) {
        let newLength = state.form.schema.required.length;
        Vue.set(state.form.schema.required, newLength, sectionToDuplicate.index);
      }
      Vue.set(state.form.schema.properties, payload.key, sectionToDuplicate);
      state.hasChanges = true;
    },
    duplicateQuestions(state, payload) {
      //Duplicating question inside section
      if (
        state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId] !==
        undefined
      ) {
        let questionDuplicatefromSection = cloneDeep(
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId]
        );
        questionDuplicatefromSection.$id = `#/properties/${state.selectedItem.sectionId}/properties/${fieldTypes.formQuestion}/${payload.question}`;
        questionDuplicatefromSection.index = `${fieldTypes.formQuestion}/${payload.question}`;
        questionDuplicatefromSection.data.order = payload.order;

        if (
          state.form.schema.properties[state.selectedItem.sectionId].required.includes(state.selectedItem.questionId)
        ) {
          let newLength = state.form.schema.properties[state.selectedItem.sectionId].required.length;
          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].required,
            newLength,
            questionDuplicatefromSection.index
          );
        }

        let newAllOf = [];
        if (state.form.schema.allOf !== undefined) {
          newAllOf = cloneDeep(
            state.form.schema.allOf.filter((allOf) => {
              if (allOf.then.properties[state.selectedItem.sectionId]) {
                return (
                  (allOf.then.properties[state.selectedItem.sectionId] &&
                    allOf.then.properties[state.selectedItem.sectionId].required.indexOf(
                      state.selectedItem.questionId
                    ) === 0) ||
                  allOf.then.properties[state.selectedItem.sectionId].required.indexOf(
                    `${state.selectedItem.questionId}/Other`
                  ) === 0
                );
              }
            })
          );

          newAllOf.map((prop) => {
            let newElse = {};
            let newThen = {};
            let newRequired = [];

            Object.keys(prop.else.properties[state.selectedItem.sectionId].properties).forEach((e) => {
              newElse[payload.key] = prop.else.properties[state.selectedItem.sectionId].properties[e];
            });

            Object.keys(prop.then.properties[state.selectedItem.sectionId].properties).forEach((e) => {
              newThen[payload.key] = prop.else.properties[state.selectedItem.sectionId].properties[e];
            });

            if (prop.then.properties[state.selectedItem.sectionId].required.length >= 1) {
              prop.then.properties[state.selectedItem.sectionId].required.map((e) => {
                if (e.includes('Other')) {
                  newRequired.push(`${fieldTypes.formQuestion}/${payload.question}/Other`);
                } else {
                  newRequired.push(`${fieldTypes.formQuestion}/${payload.question}`);
                }
              });
              prop.then.properties[state.selectedItem.sectionId].required = [...new Set(newRequired)];
            }
            prop.else.properties[state.selectedItem.sectionId].properties = newElse;
            prop.then.properties[state.selectedItem.sectionId].properties = newThen;

            state.form.schema.allOf.push({
              $id: payload.id,
              if: prop.if,
              else: prop.else,
              then: prop.then
            });
          });
        }

        Vue.set(
          state.form.schema.properties[state.selectedItem.sectionId].properties,
          questionDuplicatefromSection.index,
          questionDuplicatefromSection
        );

        //Check if the question has other option
        let questionDuplicatefromSectionOther = cloneDeep(
          state.form.schema.properties[state.selectedItem.sectionId].properties[
            state.selectedItem.questionId + '/Other'
          ]
        );

        if (questionDuplicatefromSectionOther !== undefined) {
          questionDuplicatefromSectionOther.$id = `#/properties/${state.selectedItem.sectionId}/properties/${fieldTypes.formQuestion}/${payload.question}/Other`;
          questionDuplicatefromSectionOther.index = `${fieldTypes.formQuestion}/${payload.question}/Other`;

          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].properties,
            questionDuplicatefromSectionOther.index,
            questionDuplicatefromSectionOther
          );
        }
        state.hasChanges = true;
      } else {
        //duplicating questions inside group
        if (
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
            .properties &&
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties[
            state.selectedItem.questionId
          ] !== undefined
        ) {
          let questionDuplicatefromGroup = cloneDeep(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
              .properties[state.selectedItem.questionId]
          );
          questionDuplicatefromGroup.$id = `#/properties/${fieldTypes.formSection}/${state.selectedItem.sectionId}/properties/${payload.key}`;
          questionDuplicatefromGroup.index = payload.key;
          questionDuplicatefromGroup.data.order = payload.order;

          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
              .properties,
            questionDuplicatefromGroup.index,
            questionDuplicatefromGroup
          );
          //Duplicating the items properties for the duplicated question inside group
          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
              .properties,
            payload.key,
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
              .properties[state.selectedItem.questionId]
          );

          //Check if the question has other option
          let questionDuplicatefromSectionOther = cloneDeep(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
              .properties[state.selectedItem.questionId + '/Other']
          );

          if (questionDuplicatefromSectionOther !== undefined) {
            questionDuplicatefromSectionOther.$id = `#/properties/${fieldTypes.formSection}/${state.selectedItem.sectionId}/properties/${payload.key}/Other`;
            questionDuplicatefromSectionOther.index = `${payload.key}/Other`;
            questionDuplicatefromSectionOther.data.order = payload.order;

            Vue.set(
              state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
                .properties,
              questionDuplicatefromSectionOther.index,
              questionDuplicatefromSectionOther
            );

            //Duplicating the items properties for Other choice question inside group
            Vue.set(
              state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
                .properties,
              payload.key + '/Other',
              state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
                .properties[state.selectedItem.questionId] + '/Other'
            );
          }
          state.hasChanges = true;
        } else if (
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId] !==
            undefined &&
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId][
            'field-type'
          ] === 'form-group'
        ) {
          //Duplicating group
          let questionDuplicate = cloneDeep(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
          );
          let groupPropertyIndex = `${fieldTypes.group}/${payload.question}`;
          let newGroupRequired = [];
          const newGroupProperties = {};
          const newProperties = {};

          if (questionDuplicate.properties !== undefined) {
            Object.keys(questionDuplicate.properties).forEach((e) => {
              let questionId = e.includes('Other')
                ? e
                    .split('/')
                    .slice(-3)
                    .join('/')
                : e
                    .split('/')
                    .slice(-2)
                    .join('/');
              let groupIndex = `${fieldTypes.group}/${payload.question}.${fieldTypes.group}/${payload.question}/${questionId}`;

              newProperties[groupIndex] = questionDuplicate.properties[e];
              newProperties[groupIndex].index = groupIndex;
              newProperties[
                groupIndex
              ].$id = `#/properties/${state.selectedItem.sectionId}/properties/${fieldTypes.group}/${payload.question}.${fieldTypes.group}/${payload.question}/${questionId}`;
            });

            if (questionDuplicate.items.required.length >= 1) {
              Object.keys(questionDuplicate.items.required).map((e) => {
                let questionId = questionDuplicate.items.required[e].includes('Other')
                  ? questionDuplicate.items.required[e]
                      .split('/')
                      .slice(-3)
                      .join('/')
                  : questionDuplicate.items.required[e]
                      .split('/')
                      .slice(-2)
                      .join('/');
                let groupIndex = `${fieldTypes.group}/${payload.question}.${fieldTypes.group}/${payload.question}/${questionId}`;
                if (newGroupRequired.indexOf(groupIndex) < 0) {
                  newGroupRequired.push(groupIndex);
                }
              });

              Object.keys(questionDuplicate.items.properties).forEach((e) => {
                let questionId = e.includes('Other')
                  ? e
                      .split('/')
                      .slice(-3)
                      .join('/')
                  : e
                      .split('/')
                      .slice(-2)
                      .join('/');
                let groupIndex = `${fieldTypes.group}/${payload.question}.${fieldTypes.group}/${payload.question}/${questionId}`;
                newGroupProperties[groupIndex] = questionDuplicate.items.properties[e];
              });
            }

            if (
              state.form.schema.properties[state.selectedItem.sectionId].required.includes(state.selectedItem.groupId)
            ) {
              Vue.set(
                state.form.schema.properties[state.selectedItem.sectionId].required,
                state.form.schema.properties[state.selectedItem.sectionId].required.length,
                groupPropertyIndex
              );
            }

            questionDuplicate.properties = newProperties;
            questionDuplicate.items.properties = newGroupProperties;
            questionDuplicate.items.required = [...new Set(newGroupRequired)];
          }
          questionDuplicate.$id = `#/properties/${state.selectedItem.sectionId}/properties/${fieldTypes.group}/${payload.question}`;
          questionDuplicate.index = `${state.selectedItem.sectionId}.${fieldTypes.group}/${payload.question}`;
          questionDuplicate.data.order = payload.order;

          //Check for the dependency and if any add it
          let newAllOf = [];
          if (state.form.schema.allOf !== undefined) {
            newAllOf = cloneDeep(
              state.form.schema.allOf.filter((allOf) => {
                return (
                  allOf.then.properties[state.selectedItem.sectionId] &&
                  allOf.then.properties[state.selectedItem.sectionId].required.indexOf(state.selectedItem.groupId) === 0
                );
              })
            );
            newAllOf.map((prop) => {
              let newElse = {};
              let newThen = {};
              let newRequired = [];

              Object.keys(prop.else.properties[state.selectedItem.sectionId].properties).forEach((e) => {
                newElse[payload.key] = prop.else.properties[state.selectedItem.sectionId].properties[e];
              });

              Object.keys(prop.then.properties[state.selectedItem.sectionId].properties).forEach((e) => {
                newThen[payload.key] = prop.then.properties[state.selectedItem.sectionId].properties[e];
              });

              if (prop.then.properties[state.selectedItem.sectionId].required.length >= 1) {
                prop.then.properties[state.selectedItem.sectionId].required.map((e) => {
                  e.includes('Other')
                    ? newRequired.push(`${fieldTypes.group}/${payload.question}/Other`)
                    : newRequired.push(`${fieldTypes.group}/${payload.question}`);
                });
                prop.then.properties[state.selectedItem.sectionId].required = [...new Set(newRequired)];
              }
              prop.else.properties[state.selectedItem.sectionId].properties = newElse;
              prop.then.properties[state.selectedItem.sectionId].properties = newThen;

              state.form.schema.allOf.push({
                $id: payload.id,
                if: prop.if,
                else: prop.else,
                then: prop.then
              });
            });
          }
          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].properties,
            groupPropertyIndex,
            questionDuplicate
          );
        }
      }
      state.hasChanges = true;
    },
    deleteChoices(state, payload) {
      let array;
      if (state.selectedItem.groupId && state.selectedItem.questionId) {
        if (
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties[
            state.selectedItem.questionId
          ].enum
        ) {
          array =
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
              .properties[state.selectedItem.questionId].enum;
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties[
            state.selectedItem.questionId
          ].enum.splice(array.indexOf(payload), 1);
        } else {
          let items = { type: 'string' };
          items.enum =
            state.form.schema.properties[state.selectedItem.sectionId].properties[
              state.selectedItem.groupId
            ].properties[state.selectedItem.questionId].items.enum;
          items.enum.splice(items.enum.indexOf(payload), 1);
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties[
            state.selectedItem.questionId
          ].items = items;
        }
      } else {
        if (state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId].enum) {
          array =
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId].enum;
          state.form.schema.properties[state.selectedItem.sectionId].properties[
            state.selectedItem.questionId
          ].enum.splice(array.indexOf(payload), 1);
        } else {
          let items = { type: 'string' };
          items.enum =
            state.form.schema.properties[state.selectedItem.sectionId].properties[
              state.selectedItem.questionId
            ].items.enum;
          items.enum.splice(items.enum.indexOf(payload), 1);
          state.form.schema.properties[state.selectedItem.sectionId].properties[
            state.selectedItem.questionId
          ].items = items;
        }
      }
      state.hasChanges = true;
    },
    addChoices(state, selectedItem) {
      if (selectedItem.groupId && selectedItem.questionId) {
        if (
          state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.groupId].properties[
            selectedItem.questionId
          ].data.enableOtherOption
        ) {
          if (
            state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.groupId].properties[
              selectedItem.questionId
            ].enum
          ) {
            state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.groupId].properties[
              selectedItem.questionId
            ].enum.splice(
              -1,
              0,
              `Option${
                state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.groupId].properties[
                  selectedItem.questionId
                ].enum.length
              }`
            );
          } else {
            let items = { type: 'string' };
            items.enum =
              state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.groupId].properties[
                selectedItem.questionId
              ].items.enum;
            items.enum.splice(-1, 0, `Option${items.enum.length}`);
            state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.groupId].properties[
              selectedItem.questionId
            ].items = items;
          }
        } else {
          if (
            state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.groupId].properties[
              selectedItem.questionId
            ].enum
          ) {
            state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.groupId].properties[
              selectedItem.questionId
            ].enum.push(
              `Option${state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.groupId].properties[
                selectedItem.questionId
              ].enum.length + 1}`
            );
          } else {
            let items = { type: 'string' };
            items.enum =
              state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.groupId].properties[
                selectedItem.questionId
              ].items.enum;
            items.enum.push(`Option${items.enum.length + 1}`);
            state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.groupId].properties[
              selectedItem.questionId
            ].items = items;
          }
        }
      } else {
        if (
          state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.questionId].data
            .enableOtherOption
        ) {
          if (state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.questionId].enum) {
            state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.questionId].enum.splice(
              -1,
              0,
              `Option${
                state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.questionId].enum.length
              }`
            );
          } else {
            let items = { type: 'string' };
            items.enum =
              state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.questionId].items.enum;
            items.enum.splice(-1, 0, `Option${items.enum.length}`);
            state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.questionId].items = items;
          }
        } else {
          if (state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.questionId].enum) {
            state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.questionId].enum.push(
              `Option${state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.questionId].enum
                .length + 1}`
            );
          } else {
            let items = { type: 'string' };
            items.enum =
              state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.questionId].items.enum;
            items.enum.push(`Option${items.enum.length + 1}`);
            state.form.schema.properties[selectedItem.sectionId].properties[selectedItem.questionId].items = items;
          }
        }
      }
      state.hasChanges = true;
    },
    updateChoiceOther(state, value) {
      if (value) {
        if (state.selectedItem.groupId) {
          const otherQuestion = {
            'field-type': fieldTypes.questionOtherAnswer,
            type: 'string',
            $id: `#/properties/${state.selectedItem.sectionId}/properties/${state.selectedItem.questionId}/Other`,
            title: 'Other',
            description: 'Other',
            data: {
              showTitle: false,
              showDescription: false,
              order: 0
            }
          };

          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
              .properties,
            `${state.selectedItem.questionId}/Other`,
            otherQuestion
          );
          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
              .properties,
            `${state.selectedItem.questionId}/Other`,
            otherQuestion
          );
        } else {
          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].properties,
            `${state.selectedItem.questionId}/Other`,
            {
              'field-type': fieldTypes.questionOtherAnswer,
              type: 'string',
              $id: `#/properties/${state.selectedItem.sectionId}/properties/${state.selectedItem.questionId}/Other`,
              title: 'Other',
              description: 'Other',
              data: {
                showTitle: false,
                showDescription: false,
                order: 0
              }
            }
          );

          if (!state.form.schema.allOf) {
            Vue.set(state.form.schema, 'allOf', []);
          }
          const ifElem = { properties: {} };
          ifElem.properties[state.selectedItem.sectionId] = { properties: {} };
          ifElem.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId] = {
            const: 'Other'
          };
          const then = { properties: {} };
          const elseElem = { properties: {} };
          elseElem.properties[state.selectedItem.sectionId] = { properties: {} };
          elseElem.properties[state.selectedItem.sectionId].properties[
            state.selectedItem.groupId ? state.selectedItem.groupId : state.selectedItem.questionId
          ] = {};
          then.properties[state.selectedItem.sectionId] = { properties: {} };
          if (
            !then.properties[state.selectedItem.sectionId].required ||
            then.properties[state.selectedItem.sectionId].required.indexOf(`${state.selectedItem.questionId}/Other`) < 0
          ) {
            then.properties[state.selectedItem.sectionId].required = [`${state.selectedItem.questionId}/Other`];
          }

          state.form.schema.allOf.push({
            $id: uuid.v4(),
            if: ifElem,
            else: elseElem,
            then
          });
        }
      } else {
        if (state.selectedItem.groupId) {
          Vue.delete(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
              .properties,
            `${state.selectedItem.questionId}/Other`
          );
          Vue.delete(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
              .properties,
            `${state.selectedItem.questionId}/Other`
          );
          state.form.schema.allOf = state.form.schema.allOf.filter(
            (e) =>
              !(
                e.if.properties[state.selectedItem.sectionId] &&
                e.if.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId] &&
                e.if.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties[
                  state.selectedItem.questionId
                ] &&
                e.if.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties[
                  state.selectedItem.questionId
                ].const === 'Other'
              )
          );
        } else {
          Vue.delete(
            state.form.schema.properties[state.selectedItem.sectionId].properties,
            `${state.selectedItem.questionId}/Other`
          );
          state.form.schema.allOf = state.form.schema.allOf.filter(
            (e) =>
              !(
                e.if.properties[state.selectedItem.sectionId] &&
                e.if.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId] &&
                e.if.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId].const ===
                  'Other'
              )
          );
        }
        if (state.form.schema.allOf.length === 0) {
          Vue.delete(state.form.schema, 'allOf');
        }
      }
      state.hasChanges = true;
    },
    rearrangeChoices(state, payload) {
      if (payload.selectedItem.groupId && payload.selectedItem.questionId) {
        if (
          state.form.schema.properties[payload.selectedItem.sectionId].properties[payload.selectedItem.groupId]
            .properties[payload.selectedItem.questionId].enum
        ) {
          state.form.schema.properties[payload.selectedItem.sectionId].properties[
            payload.selectedItem.groupId
          ].properties[payload.selectedItem.questionId].enum = payload.ordered.map((e) => e.title);
        } else {
          let items = { type: 'string' };
          items.enum = payload.ordered.map((e) => e.title);
          state.form.schema.properties[payload.selectedItem.sectionId].properties[
            payload.selectedItem.groupId
          ].properties[payload.selectedItem.questionId].items = items;
        }
      } else {
        if (
          state.form.schema.properties[payload.selectedItem.sectionId].properties[payload.selectedItem.questionId].enum
        ) {
          state.form.schema.properties[payload.selectedItem.sectionId].properties[
            payload.selectedItem.questionId
          ].enum = payload.ordered.map((e) => e.title);
        } else {
          let items = { type: 'string' };
          items.enum = payload.ordered.map((e) => e.title);
          state.form.schema.properties[payload.selectedItem.sectionId].properties[
            payload.selectedItem.questionId
          ].items = items;
        }
      }
      state.hasChanges = true;
    },
    deleteQuestions(state) {
      if (state.selectedItem.groupId && state.selectedItem.questionId) {
        Vue.delete(
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties,
          state.selectedItem.questionId
        );

        // Set form schema to apply reactivity
        const rebuildState = cloneDeep(state.form.schema);
        Vue.set(state.form, 'schema', rebuildState);

        if (
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items &&
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
            .properties
        ) {
          Vue.delete(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
              .properties,
            state.selectedItem.questionId
          );
        }

        if (
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties[
            state.selectedItem.questionId + '/Other'
          ]
        ) {
          Vue.delete(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
              .properties,
            state.selectedItem.questionId + '/Other'
          );
          Vue.delete(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties
              .items.properties,
            state.selectedItem.questionId + '/Other'
          );
          state.form.schema.allOf = state.form.schema.allOf.filter(
            (e) =>
              !(
                e.if.properties[state.selectedItem.sectionId] &&
                e.if.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId] &&
                e.if.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties[
                  state.selectedItem.questionId
                ] &&
                e.if.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].properties[
                  state.selectedItem.questionId
                ].const === 'Other'
              )
          );
        }
      } else if (state.selectedItem.groupId) {
        Vue.delete(state.form.schema.properties[state.selectedItem.sectionId].properties, state.selectedItem.groupId);
        const requiredGroupId = state.form.schema.properties[state.selectedItem.sectionId].required.filter(
          (e) => e !== state.selectedItem.groupId
        );
        state.form.schema.properties[state.selectedItem.sectionId].required = [...new Set(requiredGroupId)];

        if (state.form.schema.allOf && state.form.schema.allOf.length > 0) {
          state.form.schema.allOf = state.form.schema.allOf.filter(
            (e) =>
              (e.then.properties[state.selectedItem.sectionId] &&
                e.then.properties[state.selectedItem.sectionId].required &&
                e.then.properties[state.selectedItem.sectionId].required.indexOf(state.selectedItem.groupId) === -1) ||
              !(
                e.if.properties[state.selectedItem.sectionId] &&
                e.if.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
              )
          );
        }
        state.selectedItem.groupId = null;
      } else {
        Vue.delete(
          state.form.schema.properties[state.selectedItem.sectionId].properties,
          state.selectedItem.questionId
        );

        // Delete other option when removing choice questin
        if (
          state.form.schema.properties[state.selectedItem.sectionId].properties[
            state.selectedItem.questionId + '/Other'
          ]
        ) {
          Vue.delete(
            state.form.schema.properties[state.selectedItem.sectionId].properties,
            state.selectedItem.questionId + '/Other'
          );
        }

        const newRequired = state.form.schema.properties[state.selectedItem.sectionId].required.filter(
          (e) => e !== state.selectedItem.questionId
        );
        state.form.schema.properties[state.selectedItem.sectionId].required = [...new Set(newRequired)];
        if (state.form.schema.allOf) {
          state.form.schema.allOf = state.form.schema.allOf.filter(
            (e) =>
              e.then.properties[Object.keys(e.then.properties)[0]].required[0] !== state.selectedItem.questionId &&
              Object.keys(e.if.properties[Object.keys(e.if.properties)[0]].properties)[0] !==
                state.selectedItem.questionId
          );
        }
      }

      if (state.form.schema.allOf && state.form.schema.allOf.length === 0) {
        Vue.delete(state.form.schema, 'allOf');
      }

      state.selectedItem.questionId = null;
      state.hasChanges = true;
    },
    deleteSections(state) {
      let indexDelete = Object.keys(state.form.schema.properties).indexOf(state.selectedItem.sectionId);
      Vue.delete(state.form.schema.properties, state.selectedItem.sectionId);
      const newRequiredSectionIds = state.form.schema.required.filter((e) => e !== state.selectedItem.sectionId);
      state.form.schema.required = [...new Set(newRequiredSectionIds)];
      if (state.form.schema.allOf) {
        state.form.schema.allOf = state.form.schema.allOf.filter(
          (e) => Object.keys(e.if.properties)[0] !== state.selectedItem.sectionId
        );
      }
      if (state.form.schema.allOf && state.form.schema.allOf.length === 0) {
        Vue.delete(state.form.schema, 'allOf');
      }

      if (Object.keys(state.form.schema.properties)[indexDelete - 1]) {
        state.selectedItem.sectionId = Object.keys(state.form.schema.properties)[indexDelete - 1];
      } else {
        state.selectedItem.sectionId = Object.keys(state.form.schema.properties)[0];
      }

      state.hasChanges = true;
    },
    addIfElement(state, payload) {
      if (!state.form.schema.allOf) {
        Vue.set(state.form.schema, 'allOf', []);
      }
      const ifElem = { properties: {} };
      ifElem.properties[payload.value.section] = { properties: {} };
      ifElem.properties[payload.value.section].properties[payload.value.question] = { const: payload.value.value };

      const then = { properties: {} };
      then.properties[state.selectedItem.sectionId] = { properties: {} };
      then.properties[state.selectedItem.sectionId].required = [
        state.selectedItem.groupId ? state.selectedItem.groupId : state.selectedItem.questionId
      ];

      // If group make group required and check if atleast 1 question is required
      if (state.selectedItem.groupId) {
        then.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId] = {};
        then.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].minItems = 1;

        Vue.delete(
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId],
          'minItems'
        );

        if (
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
            .required.length === 0 &&
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
            .properties
        ) {
          const newRequired = Object.keys(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
              .properties
          );
          state.form.schema.properties[state.selectedItem.sectionId].properties[
            state.selectedItem.groupId
          ].items.required = [...new Set(newRequired)];
        }
      }

      const elseElem = { properties: {} };
      elseElem.properties[state.selectedItem.sectionId] = { properties: {} };
      elseElem.properties[state.selectedItem.sectionId].properties[
        state.selectedItem.groupId ? state.selectedItem.groupId : state.selectedItem.questionId
      ] = {};

      if (state.selectedItem.groupId) {
        elseElem.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId] = {};
        elseElem.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].minItems = 0;
      }

      state.form.schema.allOf.push({
        $id: payload.value.$id,
        if: ifElem,
        else: elseElem,
        then
      });

      const keyGroupQuestion = state.selectedItem.groupId ? state.selectedItem.groupId : state.selectedItem.questionId;
      state.form.schema.properties[state.selectedItem.sectionId].required = state.form.schema.properties[
        state.selectedItem.sectionId
      ].required.filter((e) => e !== keyGroupQuestion);
      state.hasChanges = true;
    },
    removeIfElement(state, payload) {
      if (state.form.schema.allOf) {
        const allOfSchema = state.form.schema.allOf.filter((e) => e.$id === payload.$id)[0];
        if (allOfSchema) {
          // If removing a group with dependency, set the minItems so normal validation works
          if (
            state.selectedItem.groupId &&
            allOfSchema.then.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId] &&
            allOfSchema.then.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].minItems
          ) {
            Vue.set(
              state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId],
              'minItems',
              1
            );
          }
        }

        state.form.schema.allOf = state.form.schema.allOf.filter((e) => e.$id !== payload.$id);
      }
      if (state.form.schema.allOf && state.form.schema.allOf.length === 0) {
        Vue.delete(state.form.schema, 'allOf');
      }
      state.hasChanges = true;
    },
    toggleRequiredQuestion(state, dependentGroup) {
      if (state.selectedItem.sectionId && state.selectedItem.questionId) {
        if (state.selectedItem.groupId) {
          //Question inside a group
          if (
            state.form.schema.properties[state.selectedItem.sectionId].properties[
              state.selectedItem.groupId
            ].items.required.includes(state.selectedItem.questionId)
          ) {
            let newRequired = state.form.schema.properties[state.selectedItem.sectionId].properties[
              state.selectedItem.groupId
            ].items.required.filter((e) => e !== state.selectedItem.questionId);

            state.form.schema.properties[state.selectedItem.sectionId].properties[
              state.selectedItem.groupId
            ].items.required = [...new Set(newRequired)];

            // Set the group to unrequired if all questions in the group are unrequired
            if (
              state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
                .required.length === 0
            ) {
              Vue.delete(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId],
                'minItems'
              );

              // If it has a dependency of a group you should also remove the dependency when removing all required questions
              if (dependentGroup) {
                const idDeps = dependentGroup.map((dep) => dep.$id);
                state.form.schema.allOf = state.form.schema.allOf.filter((e) => idDeps.indexOf(e.$id) === -1);

                if (state.form.schema.allOf && state.form.schema.allOf.length === 0) {
                  Vue.delete(state.form.schema, 'allOf');
                }
                state.hasChanges = true;
              }
            }
          } else {
            if (
              state.form.schema.properties[state.selectedItem.sectionId].properties[
                state.selectedItem.groupId
              ].items.required.indexOf(state.selectedItem.questionId) < 0
            ) {
              state.form.schema.properties[state.selectedItem.sectionId].properties[
                state.selectedItem.groupId
              ].items.required.push(state.selectedItem.questionId);
            }

            // Set group to required if 1 question is required and the question is not part of a group that is dependent
            if (!dependentGroup) {
              Vue.set(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId],
                'minItems',
                1
              );
            }
          }
          if (
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId]
              .properties[state.selectedItem.questionId].data.type === 'checkbox'
          ) {
            if (
              state.form.schema.properties[state.selectedItem.sectionId].properties[
                state.selectedItem.groupId
              ].items.required.includes(state.selectedItem.questionId)
            ) {
              Vue.set(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
                  .properties[state.selectedItem.questionId],
                'minItems',
                1
              );
            } else {
              Vue.delete(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
                  .properties[state.selectedItem.questionId],
                'minItems'
              );
            }
          }
        } else {
          //Question without group
          if (
            state.form.schema.properties[state.selectedItem.sectionId].required.includes(state.selectedItem.questionId)
          ) {
            state.form.schema.properties[state.selectedItem.sectionId].required = state.form.schema.properties[
              state.selectedItem.sectionId
            ].required.filter((e) => e !== state.selectedItem.questionId);
          } else if (
            state.form.schema.properties[state.selectedItem.sectionId].required.indexOf(state.selectedItem.questionId) <
            0
          ) {
            state.form.schema.properties[state.selectedItem.sectionId].required.push(state.selectedItem.questionId);
          }
          if (
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId].data
              .type === 'checkbox'
          ) {
            if (
              state.form.schema.properties[state.selectedItem.sectionId].required.includes(
                state.selectedItem.questionId
              )
            ) {
              Vue.set(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId],
                'minItems',
                1
              );
            } else {
              Vue.delete(
                state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.questionId],
                'minItems'
              );
            }
          }
        }
      }
      state.hasChanges = true;
    },
    toggleRequiredGroup(state) {
      if (state.selectedItem.sectionId && state.selectedItem.groupId) {
        if (
          state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].minItems
        ) {
          Vue.delete(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId],
            'minItems'
          );

          // If group is not required, make all questions in group not required
          state.form.schema.properties[state.selectedItem.sectionId].properties[
            state.selectedItem.groupId
          ].items.required = [];
        } else {
          Vue.set(
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId],
            'minItems',
            1
          );

          // if no questions are required, make them all required
          if (
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
              .required.length === 0 &&
            state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
              .properties
          ) {
            const newRequired = Object.keys(
              state.form.schema.properties[state.selectedItem.sectionId].properties[state.selectedItem.groupId].items
                .properties
            ).filter((questionKey) => questionKey.indexOf('Other') < 0);
            state.form.schema.properties[state.selectedItem.sectionId].properties[
              state.selectedItem.groupId
            ].items.required = [...new Set(newRequired)];
          }
        }
      }
      state.hasChanges = true;
    },
    changeTextAlignment(state, alignment) {
      Object.keys(state.form.schema.properties).map((key) => {
        const section = state.form.schema.properties[key];
        section.data.textAlignment = alignment;
        return section;
      });
      state.hasChanges = true;
    },
    changeTextColor(state, color) {
      // TODO make it scheme compatible
      state.form.uiSchema = state.form.uiSchema.map((uiSchema) => {
        if (uiSchema.component === fieldTypes.formSection) {
          uiSchema.fieldOptions.props.color = color;
        }
        return uiSchema;
      });
      state.hasChanges = true;
    },
    setFilterForms(state, filter) {
      state.filterForms = filter;
      state.hasChanges = true;
    },
    removeFilterWithFormId(state, formId) {
      state.filterForms = Object.keys(state.filterForms)
        .filter((key) => key.indexOf(`form/${formId}`) < 0)
        .reduce((filterForm, key) => {
          filterForm[key] = state.filterForms[key];
          return filterForm;
        }, {});
      state.hasChanges = true;
    }
  }
};

export default forms;
