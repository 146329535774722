import Vue from 'vue';
import settings from '@shared/store/settings';

const defaultPasswordRequirements = {
  passwordMinLength: 8,
  passwordRequireLowerCaseChar: true,
  passwordRequireNumber: true,
  passwordRequireSpecialChar: true,
  passwordRequireUpperCaseChar: true,
  passwordSpecialCharactersAllowed: [
    '!',
    '"',
    '#',
    '$',
    '%',
    '&',
    '(',
    ')',
    '*',
    '+',
    ',',
    '-',
    '.',
    '/',
    ':',
    ';',
    '<',
    '=',
    '>',
    '?',
    '@',
    '[',
    ']',
    '^',
    '_',
    '`',
    '{',
    '|',
    '}',
    '~'
  ]
};

const escapeRegExp = (text) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

const uppercaseCheck = function(requirements, value) {
  if (!requirements) return true;
  return !requirements.passwordRequireUpperCaseChar || (value && /(?=.*[A-Z])/.test(value));
};

const lowercaseCheck = function(requirements, value) {
  if (!requirements) return true;
  return !requirements.passwordRequireLowerCaseChar || (value && /(?=.*[a-z])/.test(value));
};

const digitsCheck = function(requirements, value) {
  if (!requirements) return true;
  return !requirements.passwordRequireNumber || (value && /(?=.*\d)/.test(value));
};

const specialCharactersCheck = function(requirements, value) {
  if (!requirements) return true;
  return (
    !requirements.passwordRequireSpecialChar ||
    (value &&
      new RegExp(
        defaultPasswordRequirements.passwordSpecialCharactersAllowed.map((char) => escapeRegExp(char)).join('|')
      ).test(value))
  );
};

const minLengthCheck = function(requirements, value) {
  if (!requirements) return true;
  return requirements.passwordMinLength && value && value.length >= requirements.passwordMinLength;
};

function validatePassword(value) {
  const passwordRequirements =
    settings.state.companyData.passwordRequirements !== null
      ? getPasswordRequirements(settings.state.companyData.passwordRequirements)
      : defaultPasswordRequirements;

  const isValid =
    uppercaseCheck(passwordRequirements, value) &&
    lowercaseCheck(passwordRequirements, value) &&
    digitsCheck(passwordRequirements, value) &&
    specialCharactersCheck(passwordRequirements, value) &&
    minLengthCheck(passwordRequirements, value);

  return !passwordRequirements || isValid;
}

function getPasswordRequirements(companyRequirements) {
  const passwordRequirements = Object.assign({}, companyRequirements);

  Object.keys(passwordRequirements).forEach((req) => {
    if (passwordRequirements[req] === null) {
      passwordRequirements[req] = defaultPasswordRequirements[req];
    }
  });

  return passwordRequirements;
}

function getValidationMessage(value) {
  const passwordRequirements =
    settings.state.companyData.passwordRequirements !== null
      ? getPasswordRequirements(settings.state.companyData.passwordRequirements)
      : defaultPasswordRequirements;

  let message = '';

  if (!minLengthCheck(passwordRequirements, value)) {
    message +=
      Vue.i18n.translate('password.minimumlength', { amount: passwordRequirements.passwordMinLength }) + '<br/>';
  }
  if (!uppercaseCheck(passwordRequirements, value)) {
    message += Vue.i18n.translate('password.uppercase') + '<br/>';
  }
  if (!lowercaseCheck(passwordRequirements, value)) {
    message += Vue.i18n.translate('password.lowercase') + '<br/>';
  }
  if (!digitsCheck(passwordRequirements, value)) {
    message += Vue.i18n.translate('password.numbers') + '<br/>';
  }
  if (!specialCharactersCheck(passwordRequirements, value)) {
    message += Vue.i18n.translate('password.specialcharacters') + '<br/>';
  }
  if (message.length) return message.substring(0, message.length - 5);
  return Vue.i18n.translate('validation.password');
}

export default {
  getMessage(field, params, message) {
    return message || Vue.i18n.translate('validation.password');
  },
  validate(value) {
    return new Promise((resolve) => {
      resolve({
        valid: value === 'trigger' ? false : validatePassword(value),
        data: value !== 'trigger' ? getValidationMessage(value) : { message: Vue.i18n.translate('validation.password') }
      });
    });
  }
};
