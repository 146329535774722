<template>
  <router-view></router-view>
</template>

<script>
export default {
  mounted() {
    const { companyBranding } = window.localStorage;
    if (companyBranding) {
      document.body.className += ` ${companyBranding.toLowerCase()}`;
    }
  }
};
</script>

<style></style>
