// Import ES6 Promise
import 'es6-promise/auto';
import identity from 'lodash/identity';
import isObject from 'lodash/isObject';
import axios from 'axios';
import qs from 'qs';
import store from '../store';

import Vue from 'vue';

const { VUE_APP_ADMIN_API_URL } = process.env;
const token = window.localStorage.admin_token;

let instance = axios.create({
  baseURL: VUE_APP_ADMIN_API_URL,
  timeout: 40000,
  headers: {
    Authorization: token ? `Bearer ${token}` : null
  }
});

instance.defaults.headers.post['Content-Type'] = 'application/json';
// Add a request interceptor
instance.interceptors.request.use(
  function(config) {
    if (
      config.method === 'post' &&
      config.data &&
      config.headers['Content-Type'] === 'application/x-www-form-urlencoded'
    ) {
      config.data = qs.stringify(config.data);
    }
    if (config.headers && (config.headers.Authorization === null || isObject(config.headers.Authorization))) {
      delete config.headers.Authorization;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    const { config, response } = error;
    if (response && response.status === 401) {
      if (response.headers && response.headers['www-authenticate']) {
        let authenticateHeader = response.headers['www-authenticate'];
        if (authenticateHeader === 'Bearer error="invalid_token", error_description="The token is expired"') {
          const originalRequest = config;
          if (window.localStorage.refresh_token) {
            return Vue.prototype.$http
              .post(
                '/connect/token',
                {
                  grant_type: 'refresh_token',
                  refresh_token: window.localStorage.refresh_token
                },
                {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                  }
                }
              )
              .then(
                (res) => {
                  const { access_token, refresh_token } = res.data;
                  store.dispatch('auth/setAdminToken', {
                    access_token,
                    refresh_token
                  });

                  return new Promise((resolve, reject) => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + access_token;
                    resolve(axios(originalRequest));
                  });
                },
                (err) => {
                  store.dispatch('auth/logout');
                  window.location.href = '/auth/login?expired=true&redirect_to=' + window.location.pathname;
                }
              );
          } else {
            store.dispatch('auth/admin');
            window.location.href = '/auth/login?expired=true&redirect_to=' + window.location.pathname;
          }
        } else {
          store.dispatch('auth/logout');
          window.location.href = '/auth/login?refresh=true&redirect_to=' + window.location.pathname;
        }
      }
      store.dispatch('auth/logout');
      return (window.location.href = '/auth/login?expired=true&redirect_to=' + window.location.pathname);

      let redirect = '/auth/login';

      if (response.data && response.data.action && response.data.action.name && response.data.action.data) {
        if (response.data.action.name === 'changepassword' && response.data.action.data) {
          redirect = '/change-password?RequestString=' + response.data.action.data;
        }
      }

      return Promise.reject(
        identity({
          action: response.data.action,
          error: response.data && response.data.errorMessage,
          redirect: redirect
        })
      );
    } else if (response && response.status > 200 && response.data && response.data.errorMessage) {
      store.dispatch('auth/logout');
      return Promise.reject(
        identity({
          error: response.data && response.data.errorMessage
        })
      );
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
