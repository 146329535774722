export default {
  planhatTimer: new Date().getTime(),
  currentLocation: window.location ? window.location.href : '',

  install(Vue) {
    document.body.addEventListener(
      'click',
      () => {
        this.checkLocationChanged();
      },
      true
    );
    window.addEventListener('popstate', () => {
      this.trackUserTime();
    });
    Vue.prototype.$planhat = (key, info) => {
      const planhatTokens = localStorage.planhatTenantTokens ? JSON.parse(localStorage.planhatTenantTokens) : [];
      const host = window.parent.location && window.parent.location.host ? window.parent.location.host : '';
      const userMail =
        window.parent &&
        window.parent.document &&
        window.parent.document.querySelector('.mail-container') &&
        window.parent.document.querySelector('.mail-container').textContent
          ? window.parent.document.querySelector('.mail-container').textContent.trim()
          : '';
      const data = {
        email: userMail,
        action: key,
        weight: '1'
      };
      if (info) {
        data.info = info;
      }
      if (
        host.includes('apps.talmundo') &&
        userMail.length > 0 &&
        !userMail.includes('keeper') &&
        !userMail.includes('@talmundo')
      ) {
        planhatTokens.forEach((token) => {
          fetch(`https://analytics.planhat.com/analytics/${token}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          });
        });
        this.trackUserTime();
      }
    };
  },

  trackUserTime() {
    this.currentLocation = window.location ? window.location.href : '';
    const planhatTokens = localStorage.planhatTenantTokens ? JSON.parse(localStorage.planhatTenantTokens) : [];
    const host = window.parent.location && window.parent.location.host ? window.parent.location.host : '';
    const userMail =
      window.parent &&
      window.parent.document &&
      window.parent.document.querySelector('.mail-container') &&
      window.parent.document.querySelector('.mail-container').textContent
        ? window.parent.document.querySelector('.mail-container').textContent.trim()
        : '';
    const newTime = new Date().getTime();
    if (
      host.includes('apps.talmundo') &&
      userMail.length > 0 &&
      !userMail.includes('keeper') &&
      !userMail.includes('@talmundo') &&
      this.planhatTimer > 0
    ) {
      const userTime = newTime - this.planhatTimer;
      const data = {
        email: userMail,
        action: 'tal_admin_user_time_spent',
        weight: '1',
        info: {
          time: userTime
        }
      };
      this.planhatTimer = newTime;
      planhatTokens.forEach((token) => {
        fetch(`https://analytics.planhat.com/analytics/${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
      });
    }
  },
  checkLocationChanged() {
    const newLocation = window.location ? window.location.href : '';
    if (this.currentLocation !== newLocation) {
      this.trackUserTime();
    }
  }
};
