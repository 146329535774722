import locale_ar from 'element-ui/lib/locale/lang/ar';
import locale_cs from 'element-ui/lib/locale/lang/cs-CZ';
import locale_da from 'element-ui/lib/locale/lang/da';
import locale_de from 'element-ui/lib/locale/lang/de';
import locale_el from 'element-ui/lib/locale/lang/el';
import locale_en from 'element-ui/lib/locale/lang/en';
import locale_es from 'element-ui/lib/locale/lang/es';
import locale_fi from 'element-ui/lib/locale/lang/fi';
import locale_fr from 'element-ui/lib/locale/lang/fr';
import locale_hu from 'element-ui/lib/locale/lang/hu';
import locale_hy from 'element-ui/lib/locale/lang/hy-AM';
import locale_it from 'element-ui/lib/locale/lang/it';
import locale_ja from 'element-ui/lib/locale/lang/ja';
import locale_kg from 'element-ui/lib/locale/lang/kg';
import locale_nb from 'element-ui/lib/locale/lang/nb-NO';
import locale_nl from 'element-ui/lib/locale/lang/nl';
import locale_pl from 'element-ui/lib/locale/lang/pl';
import locale_pt from 'element-ui/lib/locale/lang/pt';
import locale_ro from 'element-ui/lib/locale/lang/ro';
import locale_ru from 'element-ui/lib/locale/lang/ru-RU';
import locale_sk from 'element-ui/lib/locale/lang/sk';
import locale_sr from 'element-ui/lib/locale/lang/sr';
import locale_sv from 'element-ui/lib/locale/lang/sv-SE';
import locale_tr from 'element-ui/lib/locale/lang/tr-TR';
import locale_zhCN from 'element-ui/lib/locale/lang/zh-CN';
import locale_zhTW from 'element-ui/lib/locale/lang/zh-TW';
const setLanguage = (lang) => {
  let locale = locale_en;
  switch (lang) {
    case 'ar-DZ':
      locale = locale_ar;
      break;
    case 'cs-CZ':
      locale = locale_cs;
      break;
    case 'da-DK':
      locale = locale_da;
      break;
    case 'de-DE':
      locale = locale_de;
      break;
    case 'el-GR':
      locale = locale_el;
      break;
    case 'en-US':
      locale = locale_en;
      break;
    case 'es-ES':
      locale = locale_es;
      break;
    case 'es-ES419':
      locale = locale_es;
      break;
    case 'fi-FI':
      locale = locale_fi;
      break;
    case 'fr-FR':
      locale = locale_fr;
      break;
    case 'hu-HU':
      locale = locale_hu;
      break;
    case 'hy':
      locale = locale_hy;
      break;
    case 'it-IT':
      locale = locale_it;
      break;
    case 'ja-JP':
      locale = locale_ja;
      break;
    case 'ka':
      // locale = ;
      break;
    case 'kk-KZ':
      locale = locale_kg;
      break;
    case 'ky':
      // locale = ;
      break;
    case 'nb-NO':
      locale = locale_nb;
      break;
    case 'nl-NL':
      locale = locale_nl;
      break;
    case 'pl-PL':
      locale = locale_pl;
      break;
    case 'pt-BR':
      locale = locale_pt;
      break;
    case 'pt-PT':
      locale = locale_pt;
      break;
    case 'ro-RO':
      locale = locale_ro;
      break;
    case 'ru-RU':
      locale = locale_ru;
      break;
    case 'sk-SK':
      locale = locale_sk;
      break;
    case 'sr-LATN':
      locale = locale_sr;
      break;
    case 'sv-SE':
      locale = locale_sv;
      break;
    case 'tr-TR':
      locale = locale_tr;
      break;
    case 'uk-UA':
      locale = locale_en;
      break;
    case 'zh-CN':
      locale = locale_zhCN;
      break;
    case 'zh-TW':
      locale = locale_zhTW;
      break;
    default:
      break;
  }
  return locale;
};
export default setLanguage;
