<template>
  <div class="form-date-field">
    <div class="block">
      <el-date-picker
        :disabled="editMode"
        format="dd/MM/yyyy"
        value-format="dd/MM/yyyy"
        v-model="value"
        type="date"
        :placeholder="placeholder"
        @change="updateDate"
      ></el-date-picker>
    </div>
  </div>
</template>
<script>
import { DatePicker, Tree } from 'element-ui';

export default {
  props: {
    editMode: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    model: {
      type: String
    },
    initialValue: {
      type: String
    }
  },
  components: {
    ElDatePicker: DatePicker
  },
  data() {
    return {
      value: ''
    };
  },
  methods: {
    updateDate() {
      this.$el.parentElement.querySelector(`[id="${this.model}"]`).value = this.value;
      this.$el.parentElement.querySelector(`[id="${this.model}"]`).dispatchEvent(new Event('input'));
    }
  },
  mounted() {
    if (this.$el.parentElement.querySelector(`input[id="${this.model}"]`)) {
      const self = this;
      this.$el.parentElement.querySelector(`input[id="${this.model}"]`).addEventListener(
        'change',
        (newInput) => {
          self.value = newInput.target.value;
        },
        false
      );
    }
  },
  created() {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.form-date-field {
  font-family: 'Talmundo';
}
.el-date-editor.el-input {
  width: 100% !important;
}
</style>

<style lang="scss">
.el-date-editor.el-input {
  input {
    padding-left: 35px !important;
  }
}
</style>
