import Vue from 'vue';
function validateBSN(value) {
  const pattern = /^\d{9}$/; // check for digits and length (should be 9 digits)
  if (!value.match(pattern)) return false;

  let sum = 0;
  value
    .split('')
    .reverse()
    .forEach((digit, i) => {
      i = i == 0 ? -1 : i + 1;
      sum += digit * i;
    });

  return sum % 11 === 0;
}

export default {
  getMessage(field, params, data) {
    return (data && data.message) || Vue.i18n.translate('validation.dutchId');
  },
  validate(value) {
    return new Promise((resolve) => {
      resolve({
        valid: validateBSN(
          value
            .replace(/\//g, '')
            .replace(/-/g, '')
            .replace(/\./g, '')
            .replace(/ /g, '')
        ),
        data: value !== 'trigger' ? undefined : { message: 'Not this value' }
      });
    });
  }
};
