import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const template = {
  namespaced: true,
  state: {
    data: {
      onboardingPeriod: 0,
      endUserName: 0
    }
  },
  getters: {
    endUserName(state) {
      return state.data.endUserName;
    }
  },
  mutations: {
    setOnboardingPeriod(state, onboardingPeriod) {
      state.onboardingPeriod = onboardingPeriod;
    },
    setTemplateData(state, data) {
      state.data = data;
    }
  },
  actions: {
    getOnboardingPeriod({ commit }) {
      return new Promise((resolve, reject) => {
        const { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        Vue.prototype.$http.get(`/v1/companies/${companyId}/templates/${templateId}`).then(
          (result) => {
            if (!result) {
              reject();
            } else {
              const onboardingPeriod = result.data.onboardingPeriod;
              commit('setTemplateData', result.data);
              resolve(onboardingPeriod);
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    }
  }
};

export default template;
