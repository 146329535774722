<template>
  <div
    class="form-title-element"
    :contenteditable="editMode"
    @blur="editFormItemLabel('form-label', $event)"
    @focus="setSelectedElement('form-label', $event)"
  ></div>
</template>

<script>
export default {
  props: {
    editMode: {
      type: Boolean
    }
  },
  methods: {
    editFormItemLabel(key, event) {
      const value = event.target.innerHTML;
      this.$store.commit('formBuilder/editFormQuestion', {
        key,
        value
      });
    },
    setSelectedElement(type) {
      this.$store.commit('formBuilder/setSelectedElement', type);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-title-element {
  width: 100%;
  font-weight: bold;
}
</style>
