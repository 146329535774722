<template>
  <div>
    <sweet-modal :title="modalTitle" hide-footer ref="formModal" :blocking="true" @hide="checkHide">
      <div v-if="loading">
        <loader></loader>
      </div>
      <div v-else>
        <form @submit.prevent>
          <div class="row mb-2 border-bottom">
            <div class="col-12">
              <div class="form-group required">
                <input
                  name="form-title"
                  required
                  type="text"
                  :placeholder="$t('forms.formsPlaceholderName')"
                  class="form-control"
                  v-model="form.title"
                  maxlength="255"
                />
              </div>
              <div class="form-group">
                <textarea
                  name="form-description"
                  :placeholder="$t('forms.descriptionForm')"
                  class="form-control"
                  v-model="form.description"
                  maxlength="255"
                ></textarea>
              </div>
              <div class="form-group" v-if="form.canChangeEditableByOthers">
                <input type="checkbox" name="editableByOthers" id="editableByOthers" v-model="form.editableByOthers" />
                <label class="form-check-label pointer" for="editableByOthers">{{
                  'lp.editableByOthers' | translate
                }}</label>
              </div>
              <div class="form-group">
                <input
                  type="checkbox"
                  name="editAfterSubmission"
                  id="editAfterSubmission"
                  v-model="form.editAfterSubmission"
                />
                <label class="form-check-label pointer" for="editAfterSubmission">{{
                  'forms.editAfterSubmission' | translate
                }}</label>
              </div>
              <div class="form-group">
                <input type="checkbox" name="sendReminder" id="sendReminder" v-model="sendReminder" />
                <label class="form-check-label pointer" for="sendReminder">{{
                  'forms.sendReminder' | translate
                }}</label>
              </div>
              <template v-if="sendReminder">
                <div class="form-group">
                  <label for="useFixedDates">{{ 'forms.thisFormIsDue' | translate }}</label>
                  <br />
                  <radio-image
                    val="false"
                    :setValue="useFixedDate"
                    v-model="useFixedDate"
                    name="useFixedDates"
                    :text="$t('lp.moduleLabelRelativeDate')"
                    img="relative-date.png"
                  ></radio-image>
                  <radio-image
                    val="true"
                    :setValue="useFixedDate"
                    v-model="useFixedDate"
                    name="useFixedDates"
                    :text="$t('lp.moduleLabelFixedDate')"
                    img="fixed-date.png"
                  ></radio-image>
                </div>
                <div class="form-group" v-if="useFixedDate">
                  <div class="form-date-field">
                    <div class="block">
                      <el-date-picker
                        name="completedByFixed"
                        v-model="form.completedByFixed"
                        type="date"
                        :placeholder="$t('forms.completedByFixed')"
                        format="dd/MM/yyyy"
                      ></el-date-picker>
                    </div>
                  </div>
                </div>
                <div class="form-group" v-else>
                  <template>
                    <input
                      type="text"
                      name="startDays"
                      v-model="startFromStartDays"
                      class="form-control small-input"
                      @blur="setToZeroIfEmpty"
                    />
                    <span v-if="Number(form.startFromStartDays) === 1">{{ 'counter.day' | translate }}</span>
                    <span v-else>{{ 'counter.days' | translate }}</span>
                    <select name="startWorkday" v-model="startWorkday" class="form-control">
                      <option value="before">{{ 'lp.moduleTextBefore' | translate }}</option>
                      <option value="after">{{ 'lp.moduleTextAfter' | translate }}</option>
                    </select>
                    <span v-if="endUserName === 3">{{ 'lp.moduleTextLastWorkDay' | translate }}</span>
                    <span v-else>{{ 'lp.moduleTextFirstWorkDay' | translate }}</span>
                  </template>
                </div>
              </template>
            </div>
          </div>
          <tags @editTags="tagsEditting" :tags="form.tags"></tags>
          <div class="float-right button-container">
            <button class="btn-cancel" @click="hideFormModal">{{ 'general.cancel' | translate }}</button>
            <button v-if="editing" class="btn-confirm" @click="editForm" type="submit">
              {{ 'general.saveSettings' | translate }}
            </button>
            <button v-else class="btn-confirm" @click="createForm" type="submit">
              {{ 'forms.buttonCreateForm' | translate }}
            </button>
          </div>
        </form>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import Tags from '@lp/components/Tags.vue';
import notifications from '@shared/mixins/notifications';
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { SweetModal } from 'sweet-modal-vue';
import RadioImage from '@lp/components/RadioImage.vue';
import { DatePicker } from 'element-ui';

export default {
  mixins: [notifications],
  components: {
    Tags,
    SweetModal,
    RadioImage,
    ElDatePicker: DatePicker
  },
  props: {
    modalTitle: {
      type: String,
      required: true
    },
    formId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      form: {
        title: '',
        description: '',
        tags: [],
        editableByOthers: false,
        editAfterSubmission: false,
        canChangeEditableByOthers: true,
        completedByFixed: null,
        completedByRelativeDays: -30
      },
      startFromStartDays: 30,
      startWorkday: 'before',
      useFixedDate: false,
      sendReminder: false,
      editing: false,
      tagsEdit: false
    };
  },
  async created() {
    await this.$store.dispatch('template/getOnboardingPeriod');
    if (this.formId) {
      const selectedForm = this.forms.find((e) => e.id === Number(this.formId));
      this.form = cloneDeep(selectedForm);

      if (this.form.completedByFixed) {
        this.useFixedDate = true;
        this.sendReminder = true;
      } else if (this.form.completedByRelativeDays !== undefined) {
        if (this.form.completedByRelativeDays == null) {
          this.sendReminder = false;
        } else {
          this.sendReminder = true;
        }

        if (this.form.completedByRelativeDays > 0) {
          this.startWorkday = 'after';
          this.startFromStartDays = this.form.completedByRelativeDays;
        } else {
          this.startFromStartDays = this.form.completedByRelativeDays * -1;
        }
      }

      this.editing = true;
    }
  },
  computed: {
    ...mapGetters('forms', ['forms']),
    ...mapGetters('loading', ['loading']),
    ...mapGetters('auth', ['isTalmundoUser']),
    ...mapGetters('template', ['endUserName'])
  },
  methods: {
    tagsEditting(tagsEdit) {
      this.tagsEdit = tagsEdit;
    },
    checkHide(event) {
      if (this.tagsEdit) {
        event.preventDefault();
      }
    },
    hideFormModal() {
      this.$refs.formModal.close();
    },
    checkSetCompletedByFixed() {
      if (!this.useFixedDate) {
        this.form.completedByFixed = null;
      } else {
        this.form.completedByRelativeDays = null;
      }
    },
    checkSendReminder() {
      if (!this.sendReminder) {
        this.form.completedByFixed = null;
        this.form.completedByRelativeDays = null;
      }
    },
    createForm() {
      if (!this.validateForm()) {
        return;
      }
      this.checkSendReminder();
      this.checkSetCompletedByFixed();
      this.$store
        .dispatch('forms/addForm', this.form)
        .then((form) => {
          this.$planhat('tal_admin_form_created');
          this.createFormSuccess(form.id);
        })
        .catch((error) => this.showErrorNotification(error, this.$t('notifications.errorCreatingForm')));
    },
    editForm() {
      if (!this.validateForm()) {
        return;
      }
      this.checkSendReminder();
      this.checkSetCompletedByFixed();
      this.form.id = Number(this.formId);
      this.$store
        .dispatch('forms/editForm', this.form)
        .then((form) => {
          this.$planhat('tal_admin_form_properties_edited');
          this.editFormSuccess(form.id);
        })
        .catch((error) => this.showErrorNotification(error, this.$t('notifications.errorEdittingForm')));
    },
    validateForm() {
      if (this.sendReminder && this.useFixedDate && !this.form.completedByFixed) {
        this.$notify({
          type: 'warning',
          message: this.$t('notifications.isRequired', { field: this.$t('forms.completedByFixed') }),
          position: 'bottom-right'
        });
        return false;
      } else if (this.sendReminder && !this.useFixedDate && !this.form.completedByRelativeDays) {
        if (Math.abs(this.form.completedByRelativeDays) === 0) {
          return true;
        }
        this.$notify({
          type: 'warning',
          message: this.$t('notifications.isRequired', { field: this.$t('lp.moduleLabelRelativeDate') }),
          position: 'bottom-right'
        });
        return false;
      }

      return ['title'].every((field) => {
        if (!this.form[field]) {
          this.$notify({
            type: 'warning',
            message: this.$t('notifications.isRequired', { field: field }),
            position: 'bottom-right'
          });
          return false;
        }
        return true;
      });
    },
    createFormSuccess(formId) {
      this.showSuccessNotification(this.$t('notifications.successCreatingForm'));
      this.$router.push('/form-editor/editor/' + formId);
    },
    editFormSuccess(formId) {
      this.showSuccessNotification(this.$t('notifications.successEdittingForm'));
      this.$refs.formModal.close();
      this.$emit('formEditted', this.form);
    },
    setToZeroIfEmpty() {
      if (this.startFromStartDays === '') {
        this.startFromStartDays = 0;
      }
    }
  },
  watch: {
    useFixedDate(useFixedDate) {
      if (!useFixedDate && this.form.completedByRelativeDays === undefined) {
        this.form.completedByRelativeDays = -30;
        this.startFromStartDays = 30;
        this.startWorkday = 'before';
      }
    },
    startWorkday(startWorkday) {
      // Make positive/negative based on before after
      if (startWorkday === 'before' && this.form.completedByRelativeDays > 0) {
        this.form.completedByRelativeDays = -this.form.completedByRelativeDays;
      } else if (startWorkday === 'after' && this.form.completedByRelativeDays <= 0) {
        this.form.completedByRelativeDays = this.form.completedByRelativeDays * -1;
      }
    },
    startFromStartDays(startFromStartDays) {
      if (isNaN(this.startFromStartDays)) {
        this.startFromStartDays = 0;
      }

      if (this.startWorkday === 'before') {
        this.form.completedByRelativeDays = -startFromStartDays;
      } else {
        this.form.completedByRelativeDays = startFromStartDays;
      }
      this.form.completedByRelativeDays = Number(this.form.completedByRelativeDays);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';

select.form-control {
  width: 120px;
  color: $color-blue;
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
}
</style>
