<template>
  <p
    class="form-question-description"
    :contenteditable="editMode"
    @keyup.enter="exitEdit($event)"
    @focus="setSelectedElement('description', $event)"
    @blur="hitTheBlur"
    :id="questionDescriptionId"
  ></p>
</template>

<script>
import setSelected from '@forms/mixins/setSelected';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';

export default {
  mixins: [setSelected],
  data() {
    return {
      questionDescriptionId: uuid.v4()
    };
  },
  mounted() {
    if (this.editMode) {
      mediumEditor(`[id="${this.questionDescriptionId}"]`, ['boldStyle', 'italicStyle', 'anchorLink']);
    }
  },
  props: {
    editMode: {
      type: Boolean
    },
    questionId: {
      type: String
    },
    groupId: {
      type: String
    }
  },
  methods: {
    hitTheBlur(event) {
      if (this.editMode) {
        let innerHTML = event.target.innerHTML;
        if (innerHTML.trim() === '<p></p>' || innerHTML.trim() === '<p><br></p>' || innerHTML.trim() === '<br>') {
          innerHTML = '';
        }
        const value = {
          target: {
            innerHTML: innerHTML
          },
          relatedTarget: {
            innerText: 'link'
          }
        };
        this.editFormItemLabel('description', value);
      }
    },
    editFormItemLabel(type, event) {
      if (!event || !event.relatedTarget || event.relatedTarget.innerText !== 'link') {
        this.setSelectedElement(null, event); // reset
      }
      const value = event.target.innerHTML
        .replace(/<br>/g, '')
        .replace(/<\/br>/g, '')
        .replace(/&nbsp;/gi, '');
      event.target.innerHTML = value;
      this.$store.commit('formBuilder/editFormQuestionProp', {
        type,
        value
      });
    },
    exitEdit(event) {
      event.target.blur();
    }
  }
};
</script>
