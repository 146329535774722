<template>
  <div>
    <div class="form-choice-container" v-if="editMode || (!editMode && type !== 'select')">
      <div v-for="(choice, index) in choices" :key="choice" class="input-choice-container">
        <form-action-drd
          title="Rearrange choices"
          elements="Choices"
          class="choice-actions-drd"
          v-if="editMode && choice !== 'Other'"
        ></form-action-drd>
        <input
          v-if="type !== 'select'"
          :type="type"
          class="form-check-input"
          :value="choice"
          :name="uniqueKey"
          :id="uniqueKey + choice"
          @click="select(choice)"
        />
        <i v-else class="material-icons">keyboard_arrow_down</i>
        <form-choice-label
          :forInput="uniqueKey + choice"
          :editMode="editMode"
          :choice="choice"
          :position="index"
          :class="{ 'select-type': type === 'select' }"
        ></form-choice-label>
      </div>
      <form-add-choice v-if="editMode"></form-add-choice>
    </div>
    <div v-else class="form-choice-container">
      <el-select :value="selected" @change="select($event)" class="input-select" :placeholder="$t('general.select')">
        <el-option v-for="choice in choices" :key="choice" :label="choice" :value="choice"></el-option>
      </el-select>
    </div>
    <div class="question-container" v-if="enableOtherOption && isGroup">
      <div class="question">
        <input
          class="form-control"
          placeholder="Other"
          :id="otherId"
          :class="{ 'd-none': selected !== choices[choices.length - 1] }"
        />
        <div class="alert alert-danger" :class="otherIdWarning">
          <i class="material-icons">cancel</i> {{ $t('form.requiredField') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fieldTypes from '@forms/const/fieldTypes.js';
import { uuid } from 'vue-uuid';
export default {
  props: {
    editMode: {
      type: Boolean
    },
    choices: {
      type: Array
    },
    type: {
      type: String
    },
    enableOtherOption: {
      type: Boolean
    },
    model: {
      type: String
    },
    initialValue: {
      type: String | Array
    },
    isGroup: {
      type: Boolean
    }
  },
  data() {
    return {
      selected: '',
      uniqueKey: ''
    };
  },
  created() {
    this.uniqueKey = uuid.v4();
  },
  mounted() {
    // Set the ui correct if initialValues (since not binded by v-model, v-model break IE support)
    if (this.initialValue) {
      let selector;
      if (Array.isArray(this.initialValue)) {
        this.initialValue.forEach((value) => {
          selector = this.$el.querySelector(`input[value="${value}"]`);
          if (selector) {
            selector.click();
          }
        });
      } else {
        if (this.type === 'select') {
          selector = this.$el.querySelector(`input`);
          selector.value = this.initialValue;
        } else {
          selector = this.$el.querySelector(`input[value="${this.initialValue}"]`);
          if (selector) {
            selector.click();
          }
        }
      }
    }

    if (this.$el.parentElement.querySelector(`input[id="${this.model}"]`)) {
      const self = this;
      this.$el.parentElement.querySelector(`input[id="${this.model}"]`).addEventListener(
        'change',
        (newInput) => {
          self.selected = self.type === 'checkbox' ? JSON.parse(newInput.target.value) : newInput.target.value;
          if (self.type === 'checkbox') {
            self.selected.forEach((e) => self.$el.querySelector(`input[value="${e}"]`).click());
          } else {
            if (self.type === 'radio') {
              self.$el.querySelector(`input[value="${self.selected}"]`).click();
            }
          }
        },
        false
      );
    }
  },
  methods: {
    select(choice) {
      if (!this.editMode) {
        if (this.type !== 'checkbox') {
          this.selected = choice;
        } else if (Array.isArray(this.selected)) {
          if (this.selected.includes(choice)) {
            this.selected = this.selected.filter((e) => e !== choice);
          } else {
            this.selected.push(choice);
          }
        } else {
          this.selected = [choice];
        }
      }
    }
  },
  computed: {
    otherId() {
      return this.model.concat('/Other');
    },
    otherIdWarning() {
      return `warning-required-${this.model}/Other`;
    }
  },
  watch: {
    selected() {
      if (!this.editMode) {
        this.$el.parentElement.querySelector(`[id="${this.model}"]`).value =
          this.type === 'checkbox' ? JSON.stringify(this.selected) : this.selected;
        this.$el.parentElement.querySelector(`[id="${this.model}"]`).dispatchEvent(new Event('input'));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-choice-container {
  font-family: 'Talmundo';

  .select-type {
    position: relative;
    top: -8px;
    margin: 0px;
  }

  .input-select {
    width: 100%;
  }

  .input-choice-container {
    border: solid 1px #ddd;
    margin: 8px 0;
    padding: 8px 8px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;

    [type='checkbox']:checked,
    [type='checkbox']:not(:checked) {
      margin-left: 5px;
      height: 16px !important;
    }

    input[type='checkbox']:checked:after {
      background-color: #021c2c;
      border-color: #021c2c;
    }

    [type='checkbox']:checked + label,
    [type='checkbox']:not(:checked) + label {
      position: relative;
      padding: 2px 0 0 0px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
      font-weight: bold;
      margin: 0px;
      width: 90%;
    }

    [type='radio']:checked,
    [type='radio']:not(:checked) {
      cursor: pointer;
      position: absolute;
      top: -2px;
      left: 18px;
      height: 40px;
      width: 100%;
      opacity: 0;
    }
    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
      position: relative;
      padding: 2px 0 0 30px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
      font-weight: bold;
      margin: 0px;
    }
    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      border: 2px solid #ddd;
      border-radius: 100%;
      background: #fff;
      cursor: pointer;
    }
    [type='radio']:checked + label:before {
      border: 2px solid #021c2c;
      cursor: pointer;
    }
    [type='radio']:checked + label:after,
    [type='radio']:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: #021c2c;
      position: absolute;
      top: 6px;
      left: 6px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      cursor: pointer;
    }
    [type='radio']:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type='radio']:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
</style>
